import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const DollarSignIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M13.01 21.7H14.935V20.325C15.8517 20.16 16.64 19.8025 17.3 19.2525C17.96 18.7025 18.29 17.8867 18.29 16.805C18.29 16.035 18.07 15.3292 17.63 14.6875C17.19 14.0458 16.31 13.4867 14.99 13.01C13.89 12.6433 13.1292 12.3225 12.7075 12.0475C12.2858 11.7725 12.075 11.3967 12.075 10.92C12.075 10.4433 12.2448 10.0675 12.5843 9.7925C12.9231 9.5175 13.4133 9.38 14.055 9.38C14.6417 9.38 15.1 9.52227 15.43 9.8068C15.76 10.0906 15.9983 10.4433 16.145 10.865L17.905 10.15C17.7033 9.50833 17.3323 8.94917 16.7918 8.4725C16.2506 7.99583 15.65 7.73 14.99 7.675V6.3H13.065V7.675C12.1483 7.87667 11.4333 8.28 10.92 8.885C10.4067 9.49 10.15 10.1683 10.15 10.92C10.15 11.7817 10.4023 12.4783 10.9068 13.01C11.4106 13.5417 12.2033 14 13.285 14.385C14.44 14.8067 15.2423 15.1825 15.6918 15.5125C16.1406 15.8425 16.365 16.2733 16.365 16.805C16.365 17.41 16.1494 17.8544 15.7182 18.1382C15.2877 18.4227 14.77 18.565 14.165 18.565C13.56 18.565 13.0239 18.3769 12.5568 18.0007C12.0889 17.6252 11.745 17.0617 11.525 16.31L9.71 17.025C9.96667 17.905 10.3656 18.6156 10.9068 19.1568C11.4473 19.6973 12.1483 20.0683 13.01 20.27V21.7ZM14 25C12.4783 25 11.0483 24.7111 9.71 24.1332C8.37167 23.5561 7.2075 22.7725 6.2175 21.7825C5.2275 20.7925 4.44393 19.6283 3.8668 18.29C3.28893 16.9517 3 15.5217 3 14C3 12.4783 3.28893 11.0483 3.8668 9.71C4.44393 8.37167 5.2275 7.2075 6.2175 6.2175C7.2075 5.2275 8.37167 4.44357 9.71 3.8657C11.0483 3.28857 12.4783 3 14 3C15.5217 3 16.9517 3.28857 18.29 3.8657C19.6283 4.44357 20.7925 5.2275 21.7825 6.2175C22.7725 7.2075 23.5561 8.37167 24.1332 9.71C24.7111 11.0483 25 12.4783 25 14C25 15.5217 24.7111 16.9517 24.1332 18.29C23.5561 19.6283 22.7725 20.7925 21.7825 21.7825C20.7925 22.7725 19.6283 23.5561 18.29 24.1332C16.9517 24.7111 15.5217 25 14 25Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default DollarSignIcon;
