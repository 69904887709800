import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AmenityWifiIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.9376 12.2476C17.9254 8.6259 11.9146 8.6259 7.90237 12.2476C7.61457 12.5074 7.17067 12.4847 6.91089 12.1969C6.6511 11.9091 6.67381 11.4652 6.96161 11.2054C11.5082 7.1013 18.3318 7.1013 22.8784 11.2054C23.1662 11.4652 23.1889 11.9091 22.9291 12.1969C22.6693 12.4847 22.2254 12.5074 21.9376 12.2476ZM19.4753 14.8335C16.8511 12.5952 13.0455 12.5952 10.4213 14.8335C10.1263 15.0851 9.68323 15.0499 9.43163 14.755C9.18003 14.46 9.21519 14.0169 9.51017 13.7653C12.6593 11.0793 17.2373 11.0793 20.3864 13.7653C20.6814 14.0169 20.7166 14.46 20.465 14.755C20.2134 15.0499 19.7703 15.0851 19.4753 14.8335ZM16.97 17.4472C15.7435 16.5549 14.1036 16.5549 12.8771 17.4472C12.5636 17.6753 12.1245 17.6061 11.8964 17.2926C11.6683 16.9791 11.7376 16.54 12.0511 16.3119C13.77 15.0614 16.077 15.0614 17.796 16.3119C18.1095 16.54 18.1787 16.9791 17.9506 17.2926C17.7225 17.6061 17.2835 17.6753 16.97 17.4472ZM14.218 19.6989C14.218 19.3112 14.5323 18.9969 14.92 18.9969H14.9271C15.3148 18.9969 15.6291 19.3112 15.6291 19.6989C15.6291 20.0866 15.3148 20.4009 14.9271 20.4009H14.92C14.5323 20.4009 14.218 20.0866 14.218 19.6989Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AmenityWifiIcon;
