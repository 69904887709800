import React from 'react';
import { inject } from 'aurelia-dependency-injection';
import {
    AuthenticationStore,
    AuthorizationStore,
    LoadingStore,
    ToastStore,
    UserStore,
    NavigationStore,
    AmenityStore,
} from 'Stores';

export interface GlobalStores {
    authorizationStore: AuthorizationStore;
    toastStore: ToastStore;
    userStore: UserStore;
    authenticationStore: AuthenticationStore;
    globalLoadingStore: LoadingStore;
    navigationStore: NavigationStore;
    amenityStore: AmenityStore;
}

@inject(
    AuthorizationStore,
    ToastStore,
    UserStore,
    AuthenticationStore,
    LoadingStore,
    NavigationStore,
    AmenityStore
)
export class StoresContext {
    context: React.Context<GlobalStores>;

    constructor(
        authorizationStore: AuthorizationStore,
        toastStore: ToastStore,
        userStore: UserStore,
        authenticationStore: AuthenticationStore,
        globalLoadingStore: LoadingStore,
        navigationStore: NavigationStore,
        amenityStore: AmenityStore
    ) {
        this.context = React.createContext({
            authorizationStore,
            toastStore,
            userStore,
            authenticationStore,
            globalLoadingStore,
            navigationStore,
            amenityStore,
        });
    }
}
