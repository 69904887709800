import React from 'react';
import * as yup from 'yup';
import { scrollToErrors } from 'Utils/ScrollToErrors';
import { FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStores } from './use-stores';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { appInsights } from 'AppInsights';

export const useFormValidation = (
    schema: yup.ObjectSchema,
    form: FormInstance
): [
    Map<string, string[]>,
    (values: object) => Promise<boolean>,
    () => void,
    (errors: Map<string, string[]>) => void
] => {
    const [errors, setErrors] = React.useState(new Map<string, string[]>());
    const { t } = useTranslation();
    const { userStore } = useStores();

    const appInsightsReactPlugin = useAppInsightsContext();
    const eventTracker = useTrackEvent(appInsightsReactPlugin, 'Form validation failed', {});

    const resetErrors = (): void => {
        setErrors(new Map<string, string[]>());
    };

    const validateForm = async (values: object): Promise<boolean> => {
        try {
            await schema.validate(values, { abortEarly: false });
            resetErrors();
            return true;
        } catch (validationErrors: any) {
            const errors = new Map<string, string[]>();
            for (const error of validationErrors.inner) {
                errors.set(
                    error.path,
                    (errors.get(error.path) ?? []).concat(t(`${error.errors}`) + '. ')
                );
            }

            setErrors(errors);
            scrollToErrors(errors, form);

            // Log validation error to Azure AppInsights
            if (userStore.userInfo?.contactInfo?.email)
                appInsights.setAuthenticatedUserContext(userStore.userInfo?.contactInfo?.email);

            eventTracker({
                userSubmittedFormValues: validationErrors.value,
                validationErrors: validationErrors.inner,
            });

            return false;
        }
    };

    return [errors, validateForm, resetErrors, setErrors];
};
