import React, { FunctionComponent, useEffect, useState } from 'react';
import { Input } from 'antd';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { FilterStore } from 'Stores';
import { theme } from 'Style/theme';
import './table-filters.less';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { CloseIcon, MagnifyingGlassIcon } from 'Components/icons';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import StaticMultiSelect from 'Components/select-custom/multi-select/static-multi-select';
import Button from 'Components/button';
import { VenueStatusDto } from 'Api/Features/Venues/Dtos/VenueStatusDto';
import { BookingStatusFilterMap, BookingStatusKey } from 'Models/Filters/BookingStatusFilter';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import Divider from 'Components/divider';
import AsyncSingleSelect from 'Components/select-custom/single-select/async-single-select';
import { useAsyncSingleSelectProps } from 'Hooks/use-async-single-select-props';
import { UserService } from 'Services/UserService';
import { useService } from 'Hooks';
import { UserAccountStatusDto } from 'Api/Features/Users/Dtos/UserAccountStatusDto';
import Datepicker from 'Components/datepicker';
import moment from 'moment';
import { YEAR_MONTH_DAY } from 'Models/Constants';
import { GetOperatorsResponseItemDto } from 'Api/Features/Users/Dtos/GetOperatorsResponseItemDto';
import { GetOperatorsRequestDto } from 'Api/Features/Users/Dtos/GetOperatorsRequestDto';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';

interface TableFiltersProps {
    filterStore: FilterStore;
    includeSearch?: boolean;
    actionbutton?: {
        buttonText: string;
        buttonIcon?: string;
        onClick: () => void;
    };
    advancedFiltersDefaultValue?: () => AdvancedFilter[];
    pageTitle?: string;
    includeVenueStatuses?: boolean;
    includeBookingStatus?: boolean;
    includeOperators?: boolean;
    defaultSelectedOperator?: SingleSelectCustomOption;
    includeAccountStatuses?: boolean;
    includeMonth?: boolean;
    includeRole?: boolean;
}

interface MultiSelectOptionType extends MultiSelectCustomOption {
    type: 'venueStatus' | 'accountStatus';
}

const TableFilters: FunctionComponent<TableFiltersProps> = observer(
    ({
        filterStore,
        includeSearch,
        actionbutton,
        pageTitle,
        includeVenueStatuses,
        includeBookingStatus,
        includeOperators,
        defaultSelectedOperator,
        includeAccountStatuses,
        includeMonth,
        includeRole,
    }) => {
        const { t } = useTranslation();
        const userService = useService(UserService);

        const [showYourSelectionSection, setShowYourSelectionSection] = useState(false);

        const [selectedVenueStatusOptions, setSelectedVenueStatusOptions] = useState<
            MultiSelectOptionType[]
        >([]);
        const [selectedAccountStatusOptions, setSelectedAccountStatusOptions] = useState<
            MultiSelectOptionType[]
        >([]);
        const [selectedBookingStatus, setSelectedBookingStatus] = useState<string | undefined>(
            BookingStatusKey.CurrentUpcoming
        );
        const [selectedRole, setSelectedRole] = useState<string | undefined>('all');

        const [selectedMonth, setSelectedMonth] = useState<moment.Moment | null>(moment());

        const { asyncSingleSelectProps: operatorSelectProp } = useAsyncSingleSelectProps({
            fetchProps: {
                fetchFunction: async (request: GetOperatorsRequestDto) =>
                    await userService.getOperators(request),
            },
            entityToSingleSelectCustomOption: (operator: GetOperatorsResponseItemDto) =>
                ({
                    label: operator.companyName,
                    value: operator.id,
                    content: { node: `${operator.firstName} ${operator.lastName}` },
                } as SingleSelectCustomOption),
        });

        useEffect(() => {
            //default venue status filter options
            if (includeVenueStatuses) {
                const defaultStatuses: MultiSelectOptionType[] = [
                    {
                        label: t(`VenueStatusDto.VenueStatusDto_${VenueStatusDto.Pending}`),
                        value: VenueStatusDto.Pending,
                        type: 'venueStatus',
                    },
                    {
                        label: t(`VenueStatusDto.VenueStatusDto_${VenueStatusDto.Active}`),
                        value: VenueStatusDto.Active,
                        type: 'venueStatus',
                    },
                ];
                setSelectedVenueStatusOptions(defaultStatuses);
                onVenueStatusesChange(defaultStatuses);
            }
        }, [includeVenueStatuses]);

        useEffect(() => {
            //default account status filter options
            if (includeAccountStatuses) {
                const defaultStatuses: MultiSelectOptionType[] = [
                    {
                        label: UserAccountStatusDto.Pending,
                        value: UserAccountStatusDto.Pending,
                        type: 'accountStatus',
                    },
                    {
                        label: UserAccountStatusDto.Active,
                        value: UserAccountStatusDto.Active,
                        type: 'accountStatus',
                    },
                ];
                setSelectedAccountStatusOptions(defaultStatuses);
                onAccountStatusesChange(defaultStatuses);
            }
        }, [includeVenueStatuses]);

        useEffect(() => {
            //default value for month picker
            onMonthChange(moment());
        }, [includeMonth]);

        useEffect(() => {
            //default selected operators
            if (includeOperators && defaultSelectedOperator) {
                onOperatorChange(defaultSelectedOperator);
            }
        }, [includeOperators, defaultSelectedOperator]);

        useEffect(() => {
            //when multi select filters are selected, we display the "Your Selection"
            const show =
                selectedVenueStatusOptions.length > 0 || selectedAccountStatusOptions.length > 0;
            setShowYourSelectionSection(show);
        }, [selectedVenueStatusOptions, selectedAccountStatusOptions]);

        const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            filterStore.updateSearchTerm(event.target.value);
        };

        const onVenueStatusesChange = (selectedOptions: MultiSelectCustomOption[]) => {
            const statuses = selectedOptions?.map(
                (x: MultiSelectCustomOption) => x.value as VenueStatusDto
            );
            filterStore.updateVenueStatuses(statuses);
            setSelectedVenueStatusOptions(
                selectedOptions.map((x) => ({ ...x, type: 'venueStatus' }))
            );
        };

        const onAccountStatusesChange = (selectedOptions: MultiSelectCustomOption[]) => {
            const statuses = selectedOptions?.map(
                (x: MultiSelectCustomOption) => x.value as UserAccountStatusDto
            );
            filterStore.updateAccountStatuses(statuses);
            setSelectedAccountStatusOptions(
                selectedOptions.map((x) => ({ ...x, type: 'accountStatus' }))
            );
        };

        const onOperatorChange = (selectedOption?: SingleSelectCustomOption) => {
            filterStore.updateOperator(selectedOption?.value);
            operatorSelectProp.onChange(selectedOption);
        };

        const onMonthChange = (month: moment.Moment | null) => {
            filterStore.updateMonth(month?.format(YEAR_MONTH_DAY) ?? undefined);
            setSelectedMonth(month);
        };

        const onBookingStatusChange = (selectedOption?: SingleSelectCustomOption) => {
            if (selectedOption) {
                filterStore.updateBookingStatuses(BookingStatusKey[selectedOption.value]);
            } else {
                filterStore.updateBookingStatuses(undefined);
            }
            setSelectedBookingStatus(selectedOption?.value);
        };

        const onRoleChange = (selectedOption?: SingleSelectCustomOption) => {
            const value = selectedOption?.value
                ? (selectedOption.value as ManagementRoleDto)
                : undefined;
            filterStore.updateRole(value);
            setSelectedRole(value);
        };

        const bookingStatusOptions = (): SingleSelectCustomOption[] => {
            const statuses: SingleSelectCustomOption[] = [];
            BookingStatusFilterMap.forEach((value, key) =>
                statuses.push({
                    label: t(`BookingStatusKey.BookingStatusKey_${key}`),
                    value: key,
                } as SingleSelectCustomOption)
            );
            return statuses;
        };

        const handleYourSelectionTagRemove = (option: MultiSelectOptionType) => {
            switch (option.type) {
                case 'venueStatus':
                    onVenueStatusesChange(
                        selectedVenueStatusOptions.filter((x) => x.value !== option.value)
                    );
                    break;
                case 'accountStatus':
                    onAccountStatusesChange(
                        selectedAccountStatusOptions.filter((x) => x.value !== option.value)
                    );
                    break;
            }
        };

        const SelectionTag = (option: MultiSelectOptionType) => (
            <div className="selection-tag" key={option.type + option.value}>
                <span className="caption-medium label">{option.label}</span>
                <CloseIcon width={16} onClick={() => handleYourSelectionTagRemove(option)} />
            </div>
        );

        return (
            <div className="TableFilters">
                <div className="filters-actions">
                    <span className="page-title title-h4-bold element-light-primary">
                        {pageTitle}
                    </span>

                    {includeOperators && (
                        <AsyncSingleSelect
                            {...operatorSelectProp}
                            isClearable
                            borderless
                            onChange={onOperatorChange}
                            placeholder="Select an operator"
                        />
                    )}

                    {includeVenueStatuses && (
                        <StaticMultiSelect
                            borderless
                            onChange={onVenueStatusesChange}
                            selected={selectedVenueStatusOptions.map((x) => x.value)}
                            options={[
                                ...Object.keys(VenueStatusDto).map(
                                    (key) =>
                                        ({
                                            label: t(`VenueStatusDto.VenueStatusDto_${key}`),
                                            value: key,
                                            type: 'venueStatus',
                                        } as MultiSelectOptionType)
                                ),
                            ]}
                        />
                    )}

                    {includeAccountStatuses && (
                        <StaticMultiSelect
                            borderless
                            onChange={onAccountStatusesChange}
                            selected={selectedAccountStatusOptions.map((x) => x.value)}
                            options={[
                                ...Object.keys(UserAccountStatusDto).map(
                                    (key) =>
                                        ({
                                            label: t(
                                                `UserAccountStatusDto.UserAccountStatusDto_${key}`
                                            ),
                                            value: t(
                                                `UserAccountStatusDto.UserAccountStatusDto_${key}`
                                            ),
                                            type: 'accountStatus',
                                        } as MultiSelectOptionType)
                                ),
                            ]}
                        />
                    )}

                    {includeBookingStatus && (
                        <StaticSingleSelect
                            borderless
                            onChange={onBookingStatusChange}
                            selected={selectedBookingStatus}
                            options={bookingStatusOptions()}
                        />
                    )}

                    {includeRole && (
                        <StaticSingleSelect
                            borderless
                            onChange={onRoleChange}
                            selected={selectedRole}
                            placeholder={'Select role...'}
                            options={[
                                { label: 'All Roles', value: 'all' } as SingleSelectCustomOption,
                            ].concat(
                                Object.keys(ManagementRoleDto).map(
                                    (role) =>
                                        ({
                                            label: t(`ManagementRoleDto.ManagementRoleDto_${role}`),
                                            value: role,
                                        } as SingleSelectCustomOption)
                                )
                            )}
                        />
                    )}

                    {includeMonth && (
                        <Datepicker
                            type="month"
                            onChange={(value) => onMonthChange(value)}
                            value={selectedMonth}
                        />
                    )}

                    {includeSearch && (
                        <Input
                            className="search-bar"
                            placeholder={t('search')}
                            onChange={onSearchChange}
                            suffix={
                                <MagnifyingGlassIcon
                                    width={20}
                                    fill={theme['main-primary-color']}
                                />
                            }
                            value={filterStore.searchTerm}
                        />
                    )}

                    {actionbutton && (
                        <Button
                            text={actionbutton.buttonText}
                            type="primary"
                            width="hugged"
                            leftIcon={actionbutton.buttonIcon}
                            sizeType="medium"
                            onClick={actionbutton.onClick}
                        />
                    )}
                </div>

                {showYourSelectionSection && (
                    <div className="your-selection-container">
                        <Divider marginTop={24} marginBottom={24} />
                        <div className="d-flex-align">
                            <div className="title">{t('your_selection')}</div>
                            <div className="tags-container">
                                {selectedVenueStatusOptions.map((x) => SelectionTag(x))}
                                {selectedAccountStatusOptions.map((x) => SelectionTag(x))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
);

export default TableFilters;
