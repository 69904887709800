import { Col, Input, Row } from 'antd';
import Form, { useForm } from 'antd/lib/form/Form';
import { UpdateUserRequestDto } from 'Api/Features/Users/Dtos/UpdateUserRequestDto';
import Button from 'Components/button';
import Content from 'Components/content';
import FormSectionTitle from 'Components/form-section-title';
import PhoneInput from 'Components/phone-input';
import Skeleton from 'Components/skeleton';
import { DetailsTitleShape } from 'Components/skeleton/generic-component-shapes/details-title';
import { InputSkeletonShape } from 'Components/skeleton/generic-component-shapes/inputs';
import SquigglyLine from 'Components/squiggly-line';
import { ValidatedFormItem } from 'Components/validated-form-item';
import WhiteCard from 'Components/white-card';
import { useService, useStores } from 'Hooks';
import { useNavigationBlocker } from 'Hooks/use-form-navigation-blocker';
import { useFormValidation } from 'Hooks/use-form-validation';
import { observer } from 'mobx-react';
import {
    CHANGED_EMAIL_SUCCESS_QUERY,
    DETAILS_VIEW_GRID_COLUMNS,
    FORM_GUTTER,
} from 'Models/Constants';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { EditProfileSchema } from 'Schemas/ProfileSchema';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';
import { UserService } from 'Services/UserService';
import { fromE164, toE164 } from 'Utils/PhoneNumberUtils';
import './index.less';

const Profile: FunctionComponent = observer(() => {
    const location = useLocation();
    const [form] = useForm();
    const { t } = useTranslation();
    const [errors, validateForm, resetErrors] = useFormValidation(EditProfileSchema, form);
    const { globalLoadingStore, toastStore, userStore } = useStores();
    const userService = useService(UserService);
    const azureAdAuthenticationService = useService(AzureAdAuthenticationService);
    const navBlocked = useNavigationBlocker();

    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [extension, setExtension] = useState<string>();
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(true);
    const [phoneNumberWasFocused, setPhoneNumberWasFocused] = useState(false);

    useEffect(() => {
        //change email call redirects here, if query param has emailChanged, toast success message
        if (location.search === CHANGED_EMAIL_SUCCESS_QUERY) {
            toastStore.toast({
                type: 'success',
                message: 'Email changed',
            });
        }
    }, [location.pathname]);

    useEffect(() => {
        if (userStore.userInfo) {
            form.setFieldsValue({
                companyName: userStore.userInfo.companyName,
                firstName: userStore.userInfo.firstName,
                lastName: userStore.userInfo.lastName,
                'contactInfo.email': userStore.userInfo.contactInfo?.email,
            });
            const phoneNumber = userStore.userInfo?.contactInfo?.phoneNumber
                ? fromE164(userStore.userInfo?.contactInfo?.phoneNumber).number
                : undefined;
            const extension = userStore.userInfo?.contactInfo?.phoneNumber
                ? fromE164(userStore.userInfo?.contactInfo?.phoneNumber).ext
                : undefined;

            setPhoneNumber(phoneNumber);
            setExtension(extension);
        }
    }, [userStore.userInfo]);

    const submit = async (): Promise<void> => {
        if (!userStore.userInfo?.id) return;

        const formData = form.getFieldsValue();

        const validationObject = {
            ...formData,
            contactInfo: {
                phoneNumber: phoneNumber
                    ? fromE164(toE164(phoneNumber, extension)).number
                    : undefined,
                email: formData['contactInfo.email'],
            },
        };

        if (!(await validateForm(validationObject)) || !phoneNumberIsValid) return;

        try {
            globalLoadingStore.addLoading();

            const request: UpdateUserRequestDto = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                contactInfo: {
                    phoneNumber: phoneNumber ? toE164(phoneNumber, extension) : undefined,
                    email: formData['contactInfo.email'],
                },
                companyName: formData.companyName,
            };

            await userService.updateUser(userStore.userInfo.id, request);

            toastStore.toast({
                type: 'success',
                message: t('Toast.success_message', {
                    param1: 'Profile',
                }),
            });
            navBlocked(false);
            userStore.setUserInfo(userStore.userInfo.id);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }

        resetErrors();
    };

    const changePassword = async (): Promise<void> => {
        if (userStore.userInfo?.contactInfo?.email) {
            await azureAdAuthenticationService.runChangePasswordFlow(
                userStore.userInfo.contactInfo.email
            );
        }
    };

    const changeEmail = async (): Promise<void> => {
        if (userStore.userInfo?.contactInfo?.email) {
            await azureAdAuthenticationService.runChangeEmailFlow(
                userStore.userInfo?.contactInfo?.email
            );
        }
    };

    return (
        <Content className="Profile" designGridColAmount={DETAILS_VIEW_GRID_COLUMNS}>
            <div className="top-container title-h4-bold mb-20">
                <Skeleton placeholder={DetailsTitleShape()} isLoading={userStore.callInProgress}>
                    <span>{t('profile')}</span>
                    <Button
                        text={'Save'}
                        type="primary"
                        width="hugged"
                        sizeType="medium"
                        onClick={() => submit()}
                    />
                </Skeleton>
            </div>

            <WhiteCard padding={40}>
                <Form
                    layout="vertical"
                    onFinish={submit}
                    form={form}
                    onFieldsChange={(changedFields) => {
                        const phoneFieldIndex = changedFields.findIndex(
                            (field) => field.name[0] === 'contactInfo.phoneNumber'
                        );
                        //phone input triggers on change when setting data from venue. only block if user focused input
                        if (phoneFieldIndex !== -1 && phoneNumberWasFocused) {
                            navBlocked(true);
                        } else if (changedFields.length > 0 && phoneFieldIndex === -1)
                            //normally only need this
                            navBlocked(true);
                    }}
                >
                    <FormSectionTitle title="Details" />

                    <Row gutter={FORM_GUTTER}>
                        <Col span={8}>
                            <Skeleton
                                isLoading={userStore.callInProgress}
                                placeholder={InputSkeletonShape('Company')}
                            >
                                <ValidatedFormItem
                                    name={'companyName'}
                                    label="Company"
                                    errors={errors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                        <Col span={8}>
                            <Skeleton
                                isLoading={userStore.callInProgress}
                                placeholder={InputSkeletonShape('First Name')}
                            >
                                <ValidatedFormItem
                                    name={'firstName'}
                                    label="First Name"
                                    errors={errors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                        <Col span={8}>
                            <Skeleton
                                isLoading={userStore.callInProgress}
                                placeholder={InputSkeletonShape('Last Name')}
                            >
                                <ValidatedFormItem
                                    name="lastName"
                                    label="Last Name"
                                    errors={errors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={12}>
                            <Skeleton
                                isLoading={userStore.callInProgress}
                                placeholder={InputSkeletonShape('Email')}
                            >
                                <ValidatedFormItem
                                    name="contactInfo.email"
                                    label="Email"
                                    errors={errors}
                                    disabled
                                >
                                    <Input disabled />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                        <Col span={12}>
                            <Skeleton
                                isLoading={userStore.callInProgress}
                                placeholder={InputSkeletonShape('Phone (Optional)')}
                            >
                                <ValidatedFormItem
                                    name={'contactInfo.phoneNumber'}
                                    label="Phone (Optional)"
                                    errors={errors}
                                >
                                    <PhoneInput
                                        onChange={(
                                            isValid: boolean,
                                            fullNumber?: string,
                                            extension?: string
                                        ) => {
                                            setPhoneNumber(fullNumber);
                                            setExtension(extension);
                                            setPhoneNumberIsValid(isValid || fullNumber === '');
                                        }}
                                        startingValue={
                                            userStore.userInfo?.contactInfo?.phoneNumber
                                                ? fromE164(
                                                      userStore.userInfo?.contactInfo.phoneNumber
                                                  ).number
                                                : undefined
                                        }
                                        extension={extension}
                                        formId="contactInfo.phoneNumber"
                                        onFocus={() => setPhoneNumberWasFocused(true)}
                                        formErrors={errors}
                                        formInputName="contactInfo.phoneNumber"
                                    />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                    </Row>

                    <SquigglyLine marginTop={20} marginbottom={40} />
                </Form>

                <FormSectionTitle title="Email" marginBottom={20} />
                <div className="password-container">
                    {t('Profile.profile_change_email')}
                    <Button
                        text="Change Email"
                        type="secondary"
                        width="hugged"
                        leftIcon="EmailIcon"
                        sizeType="medium"
                        onClick={() => changeEmail()}
                    />
                </div>

                <FormSectionTitle title="Password" marginBottom={20} marginTop={40} />
                <div className="password-container">
                    {t('Profile.profile_change_password')}
                    <Button
                        text="Change Password"
                        type="secondary"
                        width="hugged"
                        leftIcon="PadlockIcon"
                        sizeType="medium"
                        onClick={() => changePassword()}
                    />
                </div>
            </WhiteCard>
        </Content>
    );
});

export default Profile;
