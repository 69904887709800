import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { observer } from 'mobx-react';
import './authenticated-layout.less';
import SideNavigation from 'Components/side-navigation/side-navigation';
import {
    BOOKINGS_URL,
    BOOKNIGS_URL_REGEX,
    KEY_LOCAL_STORAGE_SIDE_NAVIGATION_COLLAPSED,
    MANAGERS_URL,
    MANAGERS_URL_REGEX,
    SIDENAV_AUTO_COLLAPSE_WIDTH_THRESHOLD,
    VENUES_URL,
    VENUES_URL_REGEX,
} from 'Models/Constants';
import { useWindowDimensions } from 'Hooks/use-window-dimensions';
import LayoutHeader, { HeaderBackButton } from 'Components/layout-header/layout-header';
import { useLocation } from 'react-router-dom';
import { useService, useStores } from 'Hooks';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';
import { AuthenticationService } from 'Services/AuthenticationService';

interface SideNavigationContext {
    collapsed: boolean;
    /**When screen size is smaller then 1560px, the sidenav is close by default and when it opens, should be over site content.
     * Otherwise when sidenav is open it pushes site content */
    isAutoCollapseThreshold: boolean;
}

export const SideNavigationContext = createContext<SideNavigationContext | undefined>(undefined);

const AuthenticatedLayout: React.FunctionComponent = observer(({ children }) => {
    const location = useLocation();
    const windowDimensions = useWindowDimensions();
    const { userStore, amenityStore, authenticationStore } = useStores();
    const azureAdAuthenticationService = useService(AzureAdAuthenticationService);
    const { t } = useTranslation();
    const { windowWidth } = useWindowDimensions();
    const [sideNavContext, setSideNavContext] = useState<SideNavigationContext | undefined>(
        undefined
    );
    const azureAuthService = useService(AzureAdAuthenticationService);
    const authenticationService = useService(AuthenticationService);

    const fetchUserInfo = useCallback(async () => {
        if (authenticationStore.userId) {
            await userStore.setUserInfo(authenticationStore.userId!);
        } else {
            if (!authenticationService.runSignInPromise) {
                authenticationStore.setReturnUrl(window.location.pathname + window.location.search);
                authenticationService.runSignInPromise = azureAuthService.runSignInFlow();
            }
            await authenticationService.runSignInPromise;
        }
        //kick out user that are not admin or operator
        if (userStore.userInfo && (userStore.userInfo.managementRoles?.length ?? 0) < 1) {
            authenticationStore.clearSession();
            await azureAdAuthenticationService.signOut();
        }
    }, []);

    useEffect(() => {
        if (userStore.userInfo === null && !userStore.callInProgress) fetchUserInfo();
    }, [fetchUserInfo, userStore.userInfo, userStore.callInProgress]);

    useEffect(() => {
        amenityStore.cachedSetAmenities();
    }, []);

    //collapse
    useEffect(() => {
        const key = KEY_LOCAL_STORAGE_SIDE_NAVIGATION_COLLAPSED;

        const autoCollapseThreshold =
            windowDimensions.windowWidth <= SIDENAV_AUTO_COLLAPSE_WIDTH_THRESHOLD;

        const previousSession = localStorage.getItem(key);
        if (autoCollapseThreshold) {
            setSideNavContext({
                isAutoCollapseThreshold: autoCollapseThreshold,
                collapsed: true,
            });
        } else if (previousSession) {
            setSideNavContext({
                isAutoCollapseThreshold: autoCollapseThreshold,
                collapsed: previousSession === 'true',
            });
        }
    }, [location.pathname, windowWidth]);

    const onHandleDisplayModeClick = () => {
        const key = KEY_LOCAL_STORAGE_SIDE_NAVIGATION_COLLAPSED;

        setSideNavContext((prev) => {
            localStorage.setItem(key, prev?.collapsed ? 'false' : 'true');
            return {
                collapsed: !prev?.collapsed,
                isAutoCollapseThreshold: prev?.isAutoCollapseThreshold ?? false,
            };
        });
    };

    const backButton = useCallback((): HeaderBackButton | undefined => {
        const urlSections = location.pathname.split('/');
        if (VENUES_URL_REGEX.test(location.pathname)) {
            if (urlSections.length !== 2) return { title: t('venues'), url: VENUES_URL };
        }

        if (BOOKNIGS_URL_REGEX.test(location.pathname)) {
            if (urlSections.length !== 2) return { title: t('bookings'), url: BOOKINGS_URL };
        }

        if (MANAGERS_URL_REGEX.test(location.pathname)) {
            if (urlSections.length !== 2) return { title: t('managers'), url: MANAGERS_URL };
        }

        return undefined;
    }, [location.pathname]);

    const headerTitle = useCallback((): string => {
        let title;
        if (VENUES_URL_REGEX.test(location.pathname)) {
            title = t('venues');
        }

        if (BOOKNIGS_URL_REGEX.test(location.pathname)) {
            title = t('bookings');
        }

        return title ? `${title} - ` : '';
    }, [location.pathname]);

    return (
        <Layout className="AuthenticatedLayout">
            <Helmet>
                <title>{`${headerTitle()}Workhop`}</title>
            </Helmet>
            <SideNavigationContext.Provider value={sideNavContext}>
                <SideNavigation />
                <Layout
                    className={`site-layout ${
                        windowWidth > SIDENAV_AUTO_COLLAPSE_WIDTH_THRESHOLD
                            ? sideNavContext?.collapsed
                                ? 'small-screen-sidenav-collapsed'
                                : 'small-screen-sidenav-open'
                            : ''
                    }`}
                >
                    <LayoutHeader
                        onHandleDisplayModeClick={onHandleDisplayModeClick}
                        backButton={backButton()}
                    />
                    <div className="site-layout-content">{children}</div>
                </Layout>
            </SideNavigationContext.Provider>
        </Layout>
    );
});

export default AuthenticatedLayout;
