import { DatePicker } from 'antd';
import { CalendarIcon } from 'Components/icons';
import { MONTH_YEAR } from 'Models/Constants';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { theme } from 'Style/theme';
import './index.less';

interface DatepickerProps {
    type: 'month' | 'week' | 'year';
    onChange: (value: moment.Moment | null) => void;
    value: moment.Moment | null;
    allowClear?: boolean;
}

const Datepicker: FunctionComponent<DatepickerProps> = ({ type, onChange, value, allowClear }) => {
    const monthFormat = MONTH_YEAR;
    return (
        <div className="Datepicker">
            <DatePicker
                allowClear={allowClear}
                suffixIcon={<CalendarIcon width={20} fill={theme['main-primary-color']} />}
                picker={type}
                format={type === 'month' ? monthFormat : undefined}
                onChange={(value) => onChange(value)}
                value={value as any}
            />
        </div>
    );
};

export default Datepicker;
