import React, { FunctionComponent } from 'react';
import './index.less';

interface SquigglyLineProps {
    margin?: number;
    marginTop?: number;
    marginbottom?: number;
}

const SquigglyLine: FunctionComponent<SquigglyLineProps> = ({
    margin,
    marginTop,
    marginbottom,
}) => {
    return (
        <div
            className="SquigglyLine"
            style={{ marginTop: marginTop ?? margin, marginBottom: marginbottom ?? margin }}
        />
    );
};

export default SquigglyLine;
