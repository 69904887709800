// GENERATED FILE - DO NOT MODIFY
import { CreateUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserRequestDto';
import { CreateUserResponseDto } from 'Api/Features/Users/Dtos/CreateUserResponseDto';
import { GetOperatorsRequestDto } from 'Api/Features/Users/Dtos/GetOperatorsRequestDto';
import { GetOperatorsResponseDto } from 'Api/Features/Users/Dtos/GetOperatorsResponseDto';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { GetUsersResponseDto } from 'Api/Features/Users/Dtos/GetUsersResponseDto';
import { ResendAccountConfirmationRequestDto } from 'Api/Features/Users/Dtos/ResendAccountConfirmationRequestDto';
import { UpdateUserRequestDto } from 'Api/Features/Users/Dtos/UpdateUserRequestDto';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class UserProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getUser(userId: string): Promise<UserDetailsDto | null> {
        const uri = this.buildUri(
            "/users/{userId}",
            { userId: userId },
            null
        );

        const data: UserDetailsDto | null = await this.httpClient.get<UserDetailsDto | null>(uri);
        return data;
    }

    public async getUsers(request: GetUsersRequestDto | null): Promise<GetUsersResponseDto | null> {
        const uri = this.buildUri(
            "/users",
            null,
            { ...request || undefined }
        );

        const data: GetUsersResponseDto | null = await this.httpClient.get<GetUsersResponseDto | null>(uri);
        return data;
    }

    public async getOperators(request: GetOperatorsRequestDto | null): Promise<GetOperatorsResponseDto | null> {
        const uri = this.buildUri(
            "/operators",
            null,
            { ...request || undefined }
        );

        const data: GetOperatorsResponseDto | null = await this.httpClient.get<GetOperatorsResponseDto | null>(uri);
        return data;
    }

    public async createUser(request: CreateUserRequestDto | null): Promise<CreateUserResponseDto | null> {
        const uri = this.buildUri(
            "/users",
            null,
            null
        );

        const data: CreateUserResponseDto | null = await this.httpClient.post<CreateUserRequestDto | null, CreateUserResponseDto | null>(uri, request);
        return data;
    }

    public async updateUser(userId: string, request: UpdateUserRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}",
            { userId: userId },
            null
        );

        await this.httpClient.patch<UpdateUserRequestDto | null, void>(uri, request);
    }

    public async deactivateUser(userId: string): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/deactivate",
            { userId: userId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async reactivateUser(userId: string): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/reactivate",
            { userId: userId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async deleteUser(userId: string): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}",
            { userId: userId },
            null
        );

        await this.httpClient.delete<void, void>(uri, );
    }

    public async resendAccountConfirmation(userId: string): Promise<void> {
        const uri = this.buildUri(
            "/users/{userId}/resend-account-confirmation",
            { userId: userId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async resendAccountConfirmationAnonymous(request: ResendAccountConfirmationRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/resend-account-confirmation",
            null,
            null
        );

        await this.httpClient.post<ResendAccountConfirmationRequestDto | null, void>(uri, request);
    }
}