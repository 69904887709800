import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { BookingDetailsDto } from 'Api/Features/Bookings/Dtos/BookingDetailsDto';
import Content from 'Components/content';
import Skeleton from 'Components/skeleton';
import { DetailsTitleShape } from 'Components/skeleton/generic-component-shapes/details-title';
import {
    DETAILS_VIEW_GRID_COLUMNS,
    SHORT_MONTH_DATE_YEAR,
    TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE_CAPS,
} from 'Models/Constants';
import TitledWhiteCard from 'Components/titled-white-card';
import { useService, useStores } from 'Hooks';
import { BookingService } from 'Services/BookingService';
import { useParams } from 'react-router-dom';
import './index.less';
import { RichTextDisplay } from 'Components/rich-text-display/rich-text-display';
import AddressPin from 'Components/address-pin';
import { theme } from 'Style/theme';
import AmenityTag from 'Components/amenity-tag';
import { AmenityIdIconMap } from 'Models/Amenities/amenityIdIconMap';
import { ClockIcon, EmailIcon, LaptopIcon, PhoneIcon } from 'Components/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import Tag from 'Components/tag/tag';
import Price from 'Components/price';
import { bookingShape, imageShape, venueShape } from './skeleton-shapes';
import Button from 'Components/button';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import ConfirmationModal from 'Components/confirmation-modal';
import { CancelBookingRequestDto } from 'Api/Features/Bookings/Dtos/CancelBookingRequestDto';
import { RefundActionDto } from 'Api/Features/Bookings/Dtos/RefundActionDto';
import Popover from 'Components/popover';
import Divider from 'Components/divider';
import { observer } from 'mobx-react';

interface CancelBookingModalState {
    visible: boolean;
    withRefund: boolean;
}

const BookingDetails: FunctionComponent = observer(() => {
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const { toastStore, globalLoadingStore, userStore } = useStores();
    const bookingService = useService(BookingService);
    const [bookingIsLoading, setBookingIsLoading] = useState(true);
    const [bookingDetails, setBookingDetails] = useState<BookingDetailsDto>();
    const [cancelBookingWarningModalState, setCancelBookingWarningModalState] =
        useState<CancelBookingModalState>({ visible: false, withRefund: false });

    const fetchBooking = useCallback(async () => {
        try {
            setBookingIsLoading(true);
            const booking = await bookingService.getBooking(id);
            if (booking) setBookingDetails(booking);
        } catch (e: any) {
            if (!e.treated) toastStore.genericError();
        } finally {
            setBookingIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (id) fetchBooking();
    }, [id]);

    const handleCancelBooking = async (withRefund: boolean) => {
        try {
            globalLoadingStore.addLoading();
            if (bookingDetails?.id) {
                const request: CancelBookingRequestDto = {
                    refundAction: withRefund ? RefundActionDto.Refund : RefundActionDto.NoRefund,
                };
                await bookingService.cancelBooking(bookingDetails?.id, request);
                toastStore.toast({
                    type: 'success',
                    message: 'Booking canceled',
                });
                await fetchBooking();
            }
        } catch (e: any) {
            if (!e.treated) toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
            setCancelBookingWarningModalState({
                visible: false,
                withRefund: false,
            });
        }
    };

    return (
        <Content className="BookingDetails" designGridColAmount={DETAILS_VIEW_GRID_COLUMNS}>
            <div className="title-h4-bold mb-20 title-section">
                <Skeleton placeholder={DetailsTitleShape(false)} isLoading={bookingIsLoading}>
                    <span>{bookingDetails?.venue?.name}</span>
                </Skeleton>
                {userStore.userRole === ManagementRoleDto.Administrator &&
                    !bookingDetails?.isCancelled &&
                    bookingDetails?.isRefundable && (
                        <Popover
                            triggerContent={
                                <Button
                                    text=""
                                    type="secondary"
                                    width="hugged"
                                    rightIcon="ThreeDotMenu"
                                    sizeType="medium"
                                    onlyIcon={true}
                                    className="menu"
                                />
                            }
                            openMenuContent={
                                <>
                                    <div
                                        className="item text-body text-high-contrast"
                                        onClick={() =>
                                            setCancelBookingWarningModalState({
                                                visible: true,
                                                withRefund: true,
                                            })
                                        }
                                    >
                                        {t('Booking.cancel_with_refund')}
                                    </div>

                                    <Divider />

                                    <div
                                        className="item text-body text-high-contrast"
                                        onClick={() =>
                                            setCancelBookingWarningModalState({
                                                visible: true,
                                                withRefund: false,
                                            })
                                        }
                                    >
                                        {t('Booking.cancel_without_refund')}
                                    </div>
                                </>
                            }
                        />
                    )}

                {userStore.userRole === ManagementRoleDto.Administrator &&
                    !bookingDetails?.isCancelled &&
                    !bookingDetails?.isRefundable && (
                        <Button
                            text="Cancel"
                            type="secondary"
                            width="hugged"
                            sizeType="medium"
                            onlyIcon={undefined}
                            onClick={() =>
                                setCancelBookingWarningModalState({
                                    visible: true,
                                    withRefund: false,
                                })
                            }
                        />
                    )}
            </div>

            <div className="cards-container">
                <TitledWhiteCard
                    padding={20}
                    title={<div className="p-medium-bold">{'Venue Details'}</div>}
                >
                    <Skeleton placeholder={imageShape} isLoading={bookingIsLoading}>
                        <img
                            className="venue-img"
                            src={bookingDetails?.venue?.images?.[0]?.url ?? ''}
                        />
                    </Skeleton>

                    <Skeleton placeholder={venueShape} isLoading={bookingIsLoading}>
                        <div className="mt-30 mb-30">
                            <AddressPin
                                address={bookingDetails?.venue?.address}
                                pinColor={theme['accent-primary-color']}
                                textColor={theme['accent-primary-color']}
                                asLink
                            />
                        </div>
                        <div className="mb-20">
                            <RichTextDisplay html={bookingDetails?.venue?.description} />
                        </div>

                        <div className="hours-container mb-20">
                            <ClockIcon
                                width={20}
                                height={20}
                                fill={theme['element-light-primary']}
                            />
                            {'Business Hours:'} &nbsp;
                            <span className="caption-medium-bold">
                                {moment(
                                    '2022-01-01T' +
                                        bookingDetails?.venue?.openingHours?.periods?.[0]
                                            ?.openingTime
                                ).format(TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE_CAPS)}{' '}
                                -{' '}
                                {moment(
                                    '2022-01-01T' +
                                        bookingDetails?.venue?.openingHours?.periods?.[0]
                                            ?.closingTime
                                ).format(TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE_CAPS)}
                            </span>
                        </div>

                        <div className="amenity-container">
                            {bookingDetails?.venue?.amenities?.map((amenity) => (
                                <AmenityTag
                                    key={amenity?.id ?? ''}
                                    id={amenity?.id ?? ''}
                                    label={amenity?.name ?? ''}
                                    icon={AmenityIdIconMap[amenity?.id ?? '']}
                                />
                            ))}
                        </div>

                        <div className="p-medium-bold mt-20 mb-10">{t('Contact')}</div>

                        <div className="contact-info">
                            <div className="d-flex-align p-medium mb-10">
                                <PhoneIcon width={24} fill={theme['main-primary-color']} />
                                <a
                                    href={`tel:${bookingDetails?.venue?.contactInfo?.phoneNumber}`}
                                    className="element-light-primary"
                                >
                                    {bookingDetails?.venue?.contactInfo?.phoneNumber}
                                </a>
                            </div>

                            <div className="d-flex-align p-medium accent-primary mb-10">
                                <EmailIcon width={24} />
                                <a href={`mailto:${bookingDetails?.venue?.contactInfo?.email}`}>
                                    {bookingDetails?.venue?.contactInfo?.email}
                                </a>
                            </div>

                            <div className="d-flex-align p-medium accent-primary">
                                <LaptopIcon width={24} />
                                <a
                                    href={bookingDetails?.venue?.contactInfo?.website ?? ''}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {bookingDetails?.venue?.contactInfo?.website}
                                </a>
                            </div>
                        </div>
                    </Skeleton>
                </TitledWhiteCard>

                <TitledWhiteCard
                    padding={20}
                    title={
                        <div className="d-flex  p-medium-bold">
                            {'Booking Details'}
                            {bookingDetails?.isCancelled && (
                                <div className="canceled caption-medium-bold element-light-primary">
                                    <div className="circle" />
                                    {t('canceled')}
                                </div>
                            )}
                        </div>
                    }
                >
                    <Skeleton placeholder={bookingShape} isLoading={bookingIsLoading}>
                        <div className="booking-details-inner">
                            <div className="consumer-container element-light-primary">
                                <div className="d-flex-column">
                                    <div className="p-small">
                                        {bookingDetails?.consumer?.firstName}{' '}
                                        {bookingDetails?.consumer?.lastName}
                                    </div>
                                    <div className="caption-medium">
                                        {bookingDetails?.consumer?.contactInfo?.email}
                                    </div>
                                </div>
                                <div className="caption-medium">
                                    {'Booking date'}
                                    <Tag
                                        color="darkGreen"
                                        text={moment
                                            .utc(bookingDetails?.date)
                                            .format(SHORT_MONTH_DATE_YEAR)}
                                    />
                                </div>
                            </div>

                            <div className="p-medium-bold mt-30 mb-5">{'Detailed Price'}</div>
                            <div className="receipt-container">
                                <div className="receipt-line">
                                    <div>{bookingDetails?.venue?.name}</div>
                                    <div>
                                        <Price
                                            price={bookingDetails?.invoice?.items?.[0]?.amount}
                                            color="black"
                                            priceBold={false}
                                        />
                                    </div>
                                </div>

                                <div className="receipt-line">
                                    <div>{'Taxes & Fees'}</div>
                                    <div>
                                        <Price
                                            price={bookingDetails?.invoice?.tax}
                                            color="black"
                                            priceBold={false}
                                        />
                                    </div>
                                </div>

                                <div className="receipt-line total">
                                    <div>{'Total Price'}</div>
                                    <div>
                                        <Price
                                            price={bookingDetails?.price ?? 0}
                                            priceSize="large"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Skeleton>
                </TitledWhiteCard>
            </div>
            <ConfirmationModal
                visible={cancelBookingWarningModalState.visible}
                title="Cancel Booking?"
                message={
                    cancelBookingWarningModalState.withRefund
                        ? t('Booking.cancel_with_refund_warning')
                        : t('Booking.cancel_without_refund_warning')
                }
                positive={{
                    text: 'Continue',
                    action: () => handleCancelBooking(cancelBookingWarningModalState.withRefund),
                }}
                negative={{
                    text: 'Cancel',
                    action: () => {
                        setCancelBookingWarningModalState({
                            visible: false,
                            withRefund: false,
                        });
                    },
                }}
            />
        </Content>
    );
});

export default BookingDetails;
