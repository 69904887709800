import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Col, Divider, Form, Input, InputNumber, Row, UploadFile } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { VenueDto } from 'Api/Features/Venues/Dtos/VenueDto';
import Button from 'Components/button';
import Content from 'Components/content';
import FormSectionTitle from 'Components/form-section-title';
import SquigglyLine from 'Components/squiggly-line';
import { ValidatedFormItem } from 'Components/validated-form-item';
import WhiteCard from 'Components/white-card';
import { useService, useStores } from 'Hooks';
import {
    CREATING_ID_URL,
    DETAILS_VIEW_GRID_COLUMNS,
    FORM_GUTTER,
    VENUES_URL,
} from 'Models/Constants';
import { useHistory, useParams } from 'react-router-dom';
import { VenueService } from 'Services/VenueService';
import { ImagePicker } from 'Components/image-picker';
import PhoneInput from 'Components/phone-input';
import TimePickerDropdown from 'Components/time-picker';
import QuillWrapper from 'Components/quill-wrapper/quill-wrapper';
import { MultiSelectCustomOption } from 'Components/select-custom/multi-select/multi-select-common';
import StaticTaglessMultiSelect from 'Components/select-custom/multi-select/static-multi-select-no-tags';
import AmenityTag from 'Components/amenity-tag';
import { StripMilliSecondsFromApiTime } from 'Utils/TimeUtils';
import { ImageDetails } from 'Components/image-picker/image-picker';
import Skeleton from 'Components/skeleton';
import { ImagePickerSkeletonShape } from 'Components/image-picker/skeleton';
import { InputSkeletonShape } from 'Components/skeleton/generic-component-shapes/inputs';
import { DetailsTitleShape } from 'Components/skeleton/generic-component-shapes/details-title';
import { observer } from 'mobx-react';
import { useNavigationBlocker } from 'Hooks/use-form-navigation-blocker';
import { useFormValidation } from 'Hooks/use-form-validation';
import { CreateVenueSchema } from 'Schemas/VenueSchema';
import { fromE164, toE164 } from 'Utils/PhoneNumberUtils';
import { CreateVenueRequestDto } from 'Api/Features/Venues/Dtos/CreateVenueRequestDto';
import GooglePlacesAutoComplete from 'Components/google-places-autocomplete';
import { displayAddress, formatLink } from 'Utils/TextUtils';
import { useTranslation } from 'react-i18next';
import { UpdateFileRequestDto } from 'Api/Features/General/Dtos/UpdateFileRequestDto';
import { AmenityIdIconMap } from 'Models/Amenities/amenityIdIconMap';
import { parseLinksToAbsolute } from 'Utils/QuillUtils';
import './index.less';
import Popover from 'Components/popover';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { VenueStatusDto } from 'Api/Features/Venues/Dtos/VenueStatusDto';
import Tag from 'Components/tag/tag';
import { getVenueStatusTagColor } from 'Utils/TagUtils';
import ConfirmationModal from 'Components/confirmation-modal';
import { BookingService } from 'Services/BookingService';

enum VenueStatusChangeEnum {
    accept = 'accept',
    reject = 'reject',
    deactivate = 'deactivate',
}

const VenueDetails: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [form] = useForm();
    const [errors, validateForm] = useFormValidation(CreateVenueSchema, form);
    const navBlocked = useNavigationBlocker();
    const venueService = useService(VenueService);
    const { globalLoadingStore, toastStore, amenityStore, userStore, authorizationStore } =
        useStores();
    const [venueDetails, setVenueDetails] = useState<VenueDto>();
    const [venueIsLoading, setVenueIsLoading] = useState(id !== CREATING_ID_URL);
    const [deactivateWarningModalVisible, setDeactivateWarningModalVisible] = useState(false);

    //special fields
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [extension, setExtension] = useState<string>();
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(true);
    const [phoneNumberWasFocused, setPhoneNumberWasFocused] = useState(false);
    const [openingTime, setOpeningTime] = useState<string | undefined>();
    const [closingTime, setClosingtime] = useState<string | undefined>();
    const [noMarkupDescription, setNoMarkupDescription] = useState<string | undefined>();
    const [selectedAmenities, setSelectedAmenities] = useState<MultiSelectCustomOption[]>([]);
    const [mainImageDetails, setMainImageDetails] = useState<ImageDetails[]>();
    const [otherImagesDetails, setOtherImagesDetails] = useState<ImageDetails[]>();
    const [googlePlaceId, setGooglePlaceId] = useState<string | undefined>();

    useEffect(() => {
        if (id === CREATING_ID_URL) setVenueIsLoading(false);
    }, [id]);

    useEffect(() => {
        //operator must be owner to view
        if (
            venueDetails &&
            userStore.userRole &&
            userStore.userRole === ManagementRoleDto.Operator &&
            venueDetails.operator?.id !== userStore.userInfo?.id
        ) {
            authorizationStore.setCurrentRequestForbidden();
        }
    }, [userStore.userRole, venueDetails]);

    useEffect(() => {
        if (venueDetails) {
            form.setFieldsValue({
                name: venueDetails.name,
                description: venueDetails.description,
                price: venueDetails.price,
                inventory: venueDetails.inventory,
                'contactInfo.email': venueDetails.contactInfo?.email,
                'contactInfo.website': venueDetails.contactInfo?.website,
                'contactInfo.phoneNumber': venueDetails.contactInfo?.phoneNumber,
                openingTime: venueDetails.openingHours?.periods?.[0]?.openingTime ?? undefined,
                closingTime: venueDetails.openingHours?.periods?.[0]?.closingTime ?? undefined,
                amenityIds: venueDetails.amenities,
            });
            setSelectedAmenities(
                venueDetails.amenities?.map(
                    (amenity) =>
                        ({
                            label: amenity?.name,
                            value: amenity?.id,
                            extraData: { icon: AmenityIdIconMap[amenity?.id ?? ''] },
                        } as MultiSelectCustomOption)
                ) ?? []
            );
            setOpeningTime(
                StripMilliSecondsFromApiTime(
                    venueDetails.openingHours?.periods?.[0]?.openingTime ?? ''
                )
            );
            setClosingtime(
                StripMilliSecondsFromApiTime(
                    venueDetails.openingHours?.periods?.[0]?.closingTime ?? ''
                )
            );
            setGooglePlaceId(venueDetails.addressGooglePlaceId ?? undefined);
            const phoneNumber = venueDetails.contactInfo?.phoneNumber
                ? fromE164(venueDetails.contactInfo?.phoneNumber).number
                : undefined;
            const extension = venueDetails.contactInfo?.phoneNumber
                ? fromE164(venueDetails.contactInfo?.phoneNumber).ext
                : undefined;

            setPhoneNumber(phoneNumber);
            setExtension(extension);
        } else {
            //default value for inventory is 1 in the field
            form.setFieldsValue({
                inventory: 1,
            });
        }
    }, [venueDetails]);

    const fetchVenueDetails = useCallback(async () => {
        try {
            setVenueIsLoading(true);
            const response = await venueService.getVenue(id);
            if (response) setVenueDetails(response);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setVenueIsLoading(false);
        }
    }, [globalLoadingStore, toastStore, venueService, id]);

    useEffect(() => {
        if (id !== CREATING_ID_URL) {
            fetchVenueDetails();
        }
    }, [id]);

    const deactivateVenue = async () => {
        if (venueDetails?.id) {
            try {
                await venueService.deactivateVenue(venueDetails.id);

                toastStore.toast({
                    type: 'success',
                    message: 'Venue deactivated',
                });

                await fetchVenueDetails();
            } catch (e: any) {
                //if bookings exist for the venue, do not deactivate and warn user
                if (e.response?.data?.errorCode === 'E004003') {
                    toastStore.toast({
                        type: 'error',
                        message: 'You must cancel bookings for this venue before deactivating',
                    });
                } else if (!e.treated) toastStore.genericError();
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
    };

    const handleStatusChange = async (action: VenueStatusChangeEnum) => {
        if (venueDetails?.id) {
            try {
                globalLoadingStore.addLoading();
                switch (action) {
                    case VenueStatusChangeEnum.accept:
                        await venueService.approveVenue(venueDetails.id);
                        toastStore.toast({
                            type: 'success',
                            message: 'Venue accepted',
                        });
                        await fetchVenueDetails();
                        break;
                    case VenueStatusChangeEnum.reject:
                        await venueService.rejectVenue(venueDetails.id);
                        toastStore.toast({
                            type: 'success',
                            message: 'Venue rejected',
                        });
                        await fetchVenueDetails();
                        break;
                    case VenueStatusChangeEnum.deactivate:
                        setDeactivateWarningModalVisible(true);
                        break;
                }
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                globalLoadingStore.removeLoading();
            }
        }
    };

    const submit = async (): Promise<void> => {
        const formData = form.getFieldsValue();

        const validationObject = {
            ...formData,
            amenityIds: selectedAmenities.map((amenity) => amenity.value),
            price: formData.price ?? undefined,
            inventory: formData.inventory ?? undefined,
            mainImage: mainImageDetails?.[0]
                ? mainImageDetails[0].isDeleted
                    ? undefined
                    : mainImageDetails
                : venueDetails?.images?.[0] ?? undefined,
            description: venueDetails
                ? noMarkupDescription ?? 'did not change'
                : noMarkupDescription,
            contactInfo: {
                phoneNumber: phoneNumber
                    ? fromE164(toE164(phoneNumber, extension)).number
                    : undefined,
                website: formData['contactInfo.website'],
                email: formData['contactInfo.email'],
            },
            addressGooglePlaceId: googlePlaceId,
        };

        if (!(await validateForm(validationObject)) || !phoneNumberIsValid) return;

        try {
            globalLoadingStore.addLoading();

            const request: CreateVenueRequestDto = {
                addressGooglePlaceId: googlePlaceId,
                amenityIds: selectedAmenities.map((amenity) => amenity.value),
                openingTime: openingTime,
                closingTime: closingTime,
                contactInfo: {
                    phoneNumber: phoneNumber ? toE164(phoneNumber, extension) : undefined,
                    website: formatLink(formData['contactInfo.website']),
                    email: formData['contactInfo.email'],
                },
                description: parseLinksToAbsolute(formData.description),
                name: formData.name,
                price: formData.price,
                inventory: formData.inventory,
                mainImage: mainImageDetails
                    ? ({
                          delete: mainImageDetails[0].isDeleted,
                          uploadBase64: mainImageDetails[0].base64,
                      } as UpdateFileRequestDto)
                    : undefined,
                images: otherImagesDetails
                    ? otherImagesDetails
                          ?.filter((image) => image.isDeleted || (!image.isDeleted && image.base64))
                          .map(
                              (image) =>
                                  ({
                                      delete: image.isDeleted,
                                      uploadBase64: image.base64,
                                      id: image.id,
                                  } as UpdateFileRequestDto)
                          )
                    : undefined,
                operatorId: userStore.userInfo?.id,
            };
            if (venueDetails && venueDetails.id)
                await venueService.updateVenue(venueDetails.id, request);
            else await venueService.createVenue(request);

            toastStore.toast({
                type: 'success',
                message: t('Toast.success_message', {
                    param1: 'Venue',
                }),
            });
            navBlocked(false);
            history.push(VENUES_URL);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <Content className="VenueDetails" designGridColAmount={DETAILS_VIEW_GRID_COLUMNS}>
            <div className="top-container title-h4-bold mb-20">
                <Skeleton placeholder={DetailsTitleShape()} isLoading={venueIsLoading}>
                    <div>
                        <div className="d-flex">
                            {venueDetails ? `${venueDetails.name} - Details` : 'Create Venue'}
                            {id !== CREATING_ID_URL &&
                                venueDetails?.status !== VenueStatusDto.Active && (
                                    <div className="d-flex-align ml-10">
                                        <Tag
                                            color={getVenueStatusTagColor(venueDetails?.status)}
                                            text={t(
                                                `VenueStatusDto.VenueStatusDto_${venueDetails?.status}`
                                            )}
                                        />
                                    </div>
                                )}
                        </div>
                        {venueDetails && userStore.userRole === ManagementRoleDto.Administrator && (
                            <div className="p-medium">
                                Operator: {venueDetails.operator?.companyName}
                            </div>
                        )}
                    </div>

                    <div className="buttons-container">
                        {/* Venue is active, add deactive button */}
                        {id !== CREATING_ID_URL &&
                            venueDetails &&
                            venueDetails.status === VenueStatusDto.Active &&
                            userStore.userRole === ManagementRoleDto.Administrator && (
                                <Button
                                    text={'Deactivate'}
                                    type="secondary"
                                    width="hugged"
                                    sizeType="medium"
                                    onClick={() =>
                                        handleStatusChange(VenueStatusChangeEnum.deactivate)
                                    }
                                    className="deactivate"
                                />
                            )}

                        {(!venueDetails || venueDetails.status !== VenueStatusDto.Deactivated) && (
                            <Button
                                text={venueDetails ? 'Save' : 'Create'}
                                type="primary"
                                width="hugged"
                                sizeType="medium"
                                onClick={() => submit()}
                                className="save"
                            />
                        )}

                        {/* venue is not active, add menu button */}
                        {id !== CREATING_ID_URL &&
                            venueDetails &&
                            venueDetails.status !== VenueStatusDto.Active &&
                            userStore.userRole === ManagementRoleDto.Administrator && (
                                <Popover
                                    triggerContent={
                                        <Button
                                            text=""
                                            type="secondary"
                                            width="hugged"
                                            rightIcon="ThreeDotMenu"
                                            sizeType="medium"
                                            onlyIcon={true}
                                            className="menu"
                                        />
                                    }
                                    openMenuContent={
                                        <>
                                            {(venueDetails?.status === VenueStatusDto.Pending ||
                                                venueDetails?.status ===
                                                    VenueStatusDto.Rejected) && (
                                                <>
                                                    <div
                                                        className="item text-body text-high-contrast"
                                                        onClick={() =>
                                                            handleStatusChange(
                                                                VenueStatusChangeEnum.accept
                                                            )
                                                        }
                                                    >
                                                        {t('accept')}
                                                    </div>
                                                    <Divider />
                                                </>
                                            )}
                                            {venueDetails?.status === VenueStatusDto.Pending && (
                                                <>
                                                    <div
                                                        className="item text-body text-high-contrast"
                                                        onClick={() =>
                                                            handleStatusChange(
                                                                VenueStatusChangeEnum.reject
                                                            )
                                                        }
                                                    >
                                                        {t('reject')}
                                                    </div>
                                                    <Divider />
                                                </>
                                            )}
                                            <div
                                                className="item text-body text-high-contrast"
                                                onClick={() =>
                                                    handleStatusChange(
                                                        VenueStatusChangeEnum.deactivate
                                                    )
                                                }
                                            >
                                                {t('deactivate')}
                                            </div>
                                        </>
                                    }
                                />
                            )}
                    </div>
                </Skeleton>
            </div>

            <WhiteCard padding={40} paddingBottom={20}>
                <Form
                    layout="vertical"
                    onFinish={submit}
                    form={form}
                    onFieldsChange={(changedFields) => {
                        const phoneFieldIndex = changedFields.findIndex(
                            (field) => field.name[0] === 'contactInfo.phoneNumber'
                        );
                        //phone input triggers on change when setting data from venue. only block if user focused input
                        if (phoneFieldIndex !== -1 && phoneNumberWasFocused) {
                            navBlocked(true);
                        } else if (changedFields.length > 0 && phoneFieldIndex === -1)
                            //normally only need this
                            navBlocked(true);
                    }}
                >
                    <FormSectionTitle title="Main image" marginBottom={0} />

                    <Skeleton isLoading={venueIsLoading} placeholder={ImagePickerSkeletonShape}>
                        <ValidatedFormItem
                            className="image-picker"
                            name={'mainImage'}
                            label=""
                            errors={errors}
                        >
                            <ImagePicker
                                images={
                                    venueDetails?.images
                                        ? [
                                              {
                                                  url: venueDetails.images[0]?.url,
                                                  uid: venueDetails.images[0]?.id,
                                              } as UploadFile,
                                          ]
                                        : undefined
                                }
                                setImagesDetails={(images: ImageDetails[] | undefined) => {
                                    setMainImageDetails(images);
                                    navBlocked(true);
                                }}
                                formId="mainImage"
                            />
                        </ValidatedFormItem>
                    </Skeleton>

                    <FormSectionTitle
                        title="Other images (optional)"
                        marginBottom={0}
                        marginTop={20}
                    />

                    <Skeleton isLoading={venueIsLoading} placeholder={ImagePickerSkeletonShape}>
                        <ValidatedFormItem
                            className="image-picker"
                            name={'otherImages'}
                            label=""
                            errors={errors}
                        >
                            <ImagePicker
                                images={
                                    venueDetails?.images
                                        ? [...venueDetails.images].slice(1)?.map(
                                              (img) =>
                                                  ({
                                                      url: img?.url,
                                                      uid: img?.id,
                                                  } as UploadFile)
                                          )
                                        : undefined
                                }
                                setImagesDetails={(images: ImageDetails[] | undefined) => {
                                    setOtherImagesDetails(images);
                                    navBlocked(true);
                                }}
                                allowMultiple
                                formId="otherImages"
                            />
                        </ValidatedFormItem>
                    </Skeleton>

                    <SquigglyLine margin={40} />

                    <FormSectionTitle title="Details" />

                    <Row gutter={FORM_GUTTER}>
                        <Col span={12}>
                            <Skeleton
                                isLoading={venueIsLoading}
                                placeholder={InputSkeletonShape('name')}
                            >
                                <ValidatedFormItem name={'name'} label="Name" errors={errors}>
                                    <Input />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                        <Col span={12}>
                            <Skeleton
                                isLoading={venueIsLoading}
                                placeholder={InputSkeletonShape('Address')}
                            >
                                <ValidatedFormItem
                                    name="addressGooglePlaceId"
                                    label="Address"
                                    errors={errors}
                                >
                                    <GooglePlacesAutoComplete
                                        onChange={(value?: string) => setGooglePlaceId(value)}
                                        defaultValue={
                                            venueDetails
                                                ? {
                                                      value:
                                                          venueDetails.addressGooglePlaceId ?? '',
                                                      label:
                                                          displayAddress(
                                                              venueDetails.address,
                                                              true
                                                          ) ?? '',
                                                  }
                                                : undefined
                                        }
                                    />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={6}>
                            <Skeleton
                                isLoading={venueIsLoading}
                                placeholder={InputSkeletonShape('price/day')}
                            >
                                <ValidatedFormItem name={'price'} label="price/day" errors={errors}>
                                    <InputNumber size="middle" controls={false} />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                        <Col span={6}>
                            <Skeleton
                                isLoading={venueIsLoading}
                                placeholder={InputSkeletonShape('inventory')}
                            >
                                <ValidatedFormItem
                                    name={'inventory'}
                                    label="inventory"
                                    errors={errors}
                                >
                                    <InputNumber
                                        size="middle"
                                        controls={false}
                                        defaultValue={1}
                                        min={1}
                                    />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                        <Col span={6}>
                            <Skeleton
                                isLoading={venueIsLoading}
                                placeholder={InputSkeletonShape('opening time')}
                            >
                                <ValidatedFormItem
                                    name={'openingTime'}
                                    label="opening time"
                                    errors={errors}
                                >
                                    <TimePickerDropdown
                                        selected={openingTime}
                                        onChange={(value?: string) => setOpeningTime(value)}
                                    />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                        <Col span={6}>
                            <Skeleton
                                isLoading={venueIsLoading}
                                placeholder={InputSkeletonShape('closing time')}
                            >
                                <ValidatedFormItem
                                    name={'closingTime'}
                                    label="closing time"
                                    errors={errors}
                                >
                                    <TimePickerDropdown
                                        selected={closingTime}
                                        minimumAvailableTime={openingTime}
                                        midnightAvailable
                                        onChange={(value?: string) => setClosingtime(value)}
                                    />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={24}>
                            <Skeleton
                                isLoading={venueIsLoading}
                                placeholder={InputSkeletonShape('description', 175)}
                            >
                                <ValidatedFormItem
                                    name={'description'}
                                    label="description"
                                    errors={errors}
                                >
                                    <QuillWrapper
                                        value={form.getFieldValue('description') ?? null}
                                        fieldName="description"
                                        errors={new Map()}
                                        onChange={(value: string, noMarkupValue: string) => {
                                            form.setFieldsValue({ description: value });
                                            setNoMarkupDescription(noMarkupValue);
                                        }}
                                        formId="description"
                                    />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                    </Row>

                    <SquigglyLine marginbottom={40} marginTop={20} />

                    <FormSectionTitle title="Amenites (Optional)" />
                    <Row gutter={FORM_GUTTER}>
                        <Col span={8}>
                            <Skeleton
                                isLoading={venueIsLoading}
                                placeholder={InputSkeletonShape('Choose Amenities')}
                            >
                                <ValidatedFormItem
                                    name={'amenityIds'}
                                    label="Choose Amenities"
                                    errors={errors}
                                >
                                    <StaticTaglessMultiSelect
                                        onChange={(value) => setSelectedAmenities(value)}
                                        selected={selectedAmenities?.map((x) => x.value)}
                                        options={amenityStore.amenities?.map(
                                            (amenity) =>
                                                ({
                                                    label: amenity.name,
                                                    value: amenity.id,
                                                    extraData: { icon: amenity.icon },
                                                } as MultiSelectCustomOption)
                                        )}
                                        closeMenuOnSelect={false}
                                        formId="amenityIds"
                                    />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                    </Row>

                    {selectedAmenities.length > 0 && (
                        <div className="amenity-selections">
                            <div className=" title caption-medium">Your Selection</div>
                            <div className="amenities">
                                {selectedAmenities?.map((amenity) => (
                                    <AmenityTag
                                        key={amenity.value}
                                        id={amenity.value}
                                        label={amenity.label}
                                        icon={amenity.extraData?.icon}
                                        onRemove={(id: string) => {
                                            setSelectedAmenities((prev) =>
                                                prev?.filter((amenity) => amenity.value !== id)
                                            );
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                    <SquigglyLine marginTop={40} marginbottom={40} />

                    <FormSectionTitle title="Contact" />
                    <Row gutter={FORM_GUTTER}>
                        <Col span={8}>
                            <Skeleton
                                isLoading={venueIsLoading}
                                placeholder={InputSkeletonShape('phone')}
                            >
                                <ValidatedFormItem
                                    name={'contactInfo.phoneNumber'}
                                    label="phone"
                                    errors={errors}
                                >
                                    <PhoneInput
                                        onChange={(
                                            isValid: boolean,
                                            fullNumber?: string,
                                            extension?: string
                                        ) => {
                                            setPhoneNumber(fullNumber);
                                            setExtension(extension);
                                            setPhoneNumberIsValid(isValid || fullNumber === '');
                                        }}
                                        startingValue={
                                            venueDetails?.contactInfo?.phoneNumber
                                                ? fromE164(venueDetails.contactInfo.phoneNumber)
                                                      .number
                                                : undefined
                                        }
                                        extension={extension}
                                        formId="contactInfo.phoneNumber"
                                        onFocus={() => setPhoneNumberWasFocused(true)}
                                        formErrors={errors}
                                        formInputName="contactInfo.phoneNumber"
                                    />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>

                        <Col span={8}>
                            <Skeleton
                                isLoading={venueIsLoading}
                                placeholder={InputSkeletonShape('email')}
                            >
                                <ValidatedFormItem
                                    name={'contactInfo.email'}
                                    label="email"
                                    errors={errors}
                                >
                                    <Input type="email" />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                        <Col span={8}>
                            <Skeleton
                                isLoading={venueIsLoading}
                                placeholder={InputSkeletonShape('website')}
                            >
                                <ValidatedFormItem
                                    name={'contactInfo.website'}
                                    label="website"
                                    errors={errors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                    </Row>
                </Form>
            </WhiteCard>

            <ConfirmationModal
                visible={deactivateWarningModalVisible}
                title="Deactivate Venue?"
                message="You are about to deactivate this venue. You will not be able to reactivate this venue."
                positive={{ text: 'Continue', action: deactivateVenue }}
                negative={{
                    text: 'Cancel',
                    action: () => {
                        setDeactivateWarningModalVisible(false);
                    },
                }}
            />
        </Content>
    );
});

export default VenueDetails;
