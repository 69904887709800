// GENERATED FILE - DO NOT MODIFY
import { CreateVenueRequestDto } from 'Api/Features/Venues/Dtos/CreateVenueRequestDto';
import { CreateVenueResponseDto } from 'Api/Features/Venues/Dtos/CreateVenueResponseDto';
import { GetAmenitiesResponseDto } from 'Api/Features/Venues/Dtos/GetAmenitiesResponseDto';
import { GetVenueAvailabilityRequestDto } from 'Api/Features/Venues/Dtos/GetVenueAvailabilityRequestDto';
import { GetVenueAvailabilityResponseDto } from 'Api/Features/Venues/Dtos/GetVenueAvailabilityResponseDto';
import { GetVenuesRequestDto } from 'Api/Features/Venues/Dtos/GetVenuesRequestDto';
import { GetVenuesResponseDto } from 'Api/Features/Venues/Dtos/GetVenuesResponseDto';
import { UpdateVenueRequestDto } from 'Api/Features/Venues/Dtos/UpdateVenueRequestDto';
import { VenueDto } from 'Api/Features/Venues/Dtos/VenueDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class VenueProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getVenue(venueId: string): Promise<VenueDto | null> {
        const uri = this.buildUri(
            "/venues/{venueId}",
            { venueId: venueId },
            null
        );

        const data: VenueDto | null = await this.httpClient.get<VenueDto | null>(uri);
        return data;
    }

    public async getVenues(request: GetVenuesRequestDto | null): Promise<GetVenuesResponseDto | null> {
        const uri = this.buildUri(
            "/venues",
            null,
            { ...request || undefined }
        );

        const data: GetVenuesResponseDto | null = await this.httpClient.get<GetVenuesResponseDto | null>(uri);
        return data;
    }

    public async createVenue(request: CreateVenueRequestDto | null): Promise<CreateVenueResponseDto | null> {
        const uri = this.buildUri(
            "/venues",
            null,
            null
        );

        const data: CreateVenueResponseDto | null = await this.httpClient.post<CreateVenueRequestDto | null, CreateVenueResponseDto | null>(uri, request);
        return data;
    }

    public async updateVenue(venueId: string, request: UpdateVenueRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/venues/{venueId}",
            { venueId: venueId },
            null
        );

        await this.httpClient.patch<UpdateVenueRequestDto | null, void>(uri, request);
    }

    public async deactivateVenue(venueId: string): Promise<void> {
        const uri = this.buildUri(
            "/venues/{venueId}/deactivate",
            { venueId: venueId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async getAmenities(): Promise<GetAmenitiesResponseDto | null> {
        const uri = this.buildUri(
            "/amenities",
            null,
            null
        );

        const data: GetAmenitiesResponseDto | null = await this.httpClient.get<GetAmenitiesResponseDto | null>(uri);
        return data;
    }

    public async approveVenue(venueId: string): Promise<void> {
        const uri = this.buildUri(
            "/venues/{venueId}/approve",
            { venueId: venueId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async rejectVenue(venueId: string): Promise<void> {
        const uri = this.buildUri(
            "/venues/{venueId}/reject",
            { venueId: venueId },
            null
        );

        await this.httpClient.post<void, void>(uri, );
    }

    public async getVenueAvailability(venueId: string, request: GetVenueAvailabilityRequestDto | null): Promise<GetVenueAvailabilityResponseDto | null> {
        const uri = this.buildUri(
            "/venues/{venueId}/availability",
            { venueId: venueId },
            { ...request || undefined }
        );

        const data: GetVenueAvailabilityResponseDto | null = await this.httpClient.get<GetVenueAvailabilityResponseDto | null>(uri);
        return data;
    }
}