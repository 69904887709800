import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PadlockIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M9.86914 22.2324H18.1309C19.4932 22.2324 20.1611 21.5557 20.1611 20.0791V13.8301C20.1611 12.5205 19.6338 11.8438 18.5615 11.7031V9.65527C18.5615 6.31543 16.3379 4.70703 14 4.70703C11.6621 4.70703 9.43848 6.31543 9.43848 9.65527V11.7031C8.36621 11.8438 7.83887 12.5205 7.83887 13.8301V20.0791C7.83887 21.5557 8.50684 22.2324 9.86914 22.2324ZM11.126 9.49707C11.126 7.44922 12.4268 6.32422 14 6.32422C15.5645 6.32422 16.874 7.44922 16.874 9.49707V11.6768H11.126V9.49707ZM10.1504 20.6416C9.76367 20.6416 9.57031 20.457 9.57031 20V13.9092C9.57031 13.4434 9.76367 13.2764 10.1504 13.2764H17.8584C18.2451 13.2764 18.4297 13.4434 18.4297 13.9092V20C18.4297 20.457 18.2451 20.6416 17.8584 20.6416H10.1504Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PadlockIcon;
