import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { initialPaginationState } from 'Models/InitialPaginationState';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import Content from 'Components/content';
import { useService, useStores } from 'Hooks';
import {
    BOOKINGS_URL,
    DEBOUNCE_DELAY_400,
    SHORT_MONTH_DATE_YEAR,
    TABLE_VIEW_GRID_COLUMNS,
} from 'Models/Constants';
import { autorun } from 'mobx';
import debounce from 'lodash.debounce';
import ImageNameTableColumn from 'Components/image-name-table-column';
import Price from 'Components/price';
import { useTranslation } from 'react-i18next';
import AddressPin from 'Components/address-pin';
import TableFilters from 'Components/table-filters/table-filters';
import { FilterStore } from 'Stores';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { useHistory } from 'react-router-dom';
import { BookingService } from 'Services/BookingService';
import { GetBookingsRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingsRequestDto';
import { GetBookingsSortColumnDto } from 'Api/Features/Bookings/Dtos/GetBookingsSortColumnDto';
import Tag from 'Components/tag/tag';
import moment from 'moment';
import { BookingStatusFilterMap, BookingStatusKey } from 'Models/Filters/BookingStatusFilter';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { BookingDto } from 'Api/Features/Bookings/Dtos/BookingDto';
import './index.less';
import { useUrlQuery } from 'Hooks/use-url-query';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';

const Bookings: React.FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { toastStore, userStore } = useStores();
    const bookingService = useService(BookingService);
    const paginationRef = useRef(initialPaginationState);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [loading, setLoading] = useState(false);
    const [bookings, SetBookings] = useState<BookingDto[]>([]);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters: [] }));
    const history = useHistory();
    const urlQuery = useUrlQuery();
    const [userIsOperator, setUserIsOperator] = useState<boolean | undefined>(undefined);
    const [defaultSelectedOperator, setDefaultSelectedOperator] = useState<
        SingleSelectCustomOption | undefined
    >(undefined);

    useEffect(() => {
        setUserIsOperator(userStore.userRole === ManagementRoleDto.Operator);
    }, [userStore.userRole]);

    useEffect(() => {
        //query string can contain default operator to filter on
        const id = urlQuery.get('operatorId');
        const first = urlQuery.get('firstName');
        const last = urlQuery.get('lastName');
        const company = urlQuery.get('companyName');
        if (id && first && last && company) {
            setDefaultSelectedOperator({
                value: id,
                label: `${company} - ${first} ${last}`,
            } as SingleSelectCustomOption);
            console.log(urlQuery.get('operatorId'));
        }
    }, [urlQuery]);

    const fetchBookings = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: any | null;
            sortDirection: SortDirection | null;
            bookingStatus?: BookingStatusKey;
            operatorIds: string[] | null;
        }) => {
            try {
                setLoading(true);
                const bookingStatusfilter = params.bookingStatus
                    ? BookingStatusFilterMap.get(params.bookingStatus)
                    : undefined;
                const request: GetBookingsRequestDto = {
                    pageSize: params.pagination.pageSize || 0,
                    page: (params.pagination.current || 1) - 1,
                    searchTerm: params.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    operatorIds: params.operatorIds,
                    ...bookingStatusfilter,
                };

                const [items, totalItemsCount] = await bookingService.getBookings(request);
                SetBookings(items);
                setPagination({
                    ...params.pagination,
                    total: totalItemsCount,
                });
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [bookingService, toastStore]
    );

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        const { searchTerm, bookingStatus, operatorId } = filterStoreRef.current;
        await fetchBookings({
            pagination,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
            bookingStatus,
            operatorIds: operatorId ? [operatorId] : null,
        });

        paginationRef.current = pagination;
    };

    const debounceSearch = useRef(
        debounce(
            (params: {
                searchTerm?: string;
                bookingStatus?: BookingStatusKey;
                operatorIds: string[] | null;
            }) => {
                fetchBookings({
                    pagination: {
                        ...paginationRef.current,
                        current: 1,
                    },
                    searchTerm: params.searchTerm,
                    sortColumn: null,
                    sortDirection: null,
                    bookingStatus: params.bookingStatus,
                    operatorIds: params.operatorIds,
                });
            },
            DEBOUNCE_DELAY_400
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                bookingStatus: filterStore.bookingStatus,
                operatorIds: filterStore.operatorId ? [filterStore.operatorId] : null,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    const columns: ColumnType<BookingDto>[] = [
        {
            title: t('name'),
            render: (booking: BookingDto) => (
                <ImageNameTableColumn
                    name={booking.venue?.name}
                    subtitle={
                        userIsOperator
                            ? undefined
                            : `${booking.venue?.operator?.companyName} - ${booking.venue?.operator?.firstName} ${booking.venue?.operator?.lastName}`
                    }
                    imageUrl={booking.venue?.images?.[0]?.url}
                />
            ),
            key: GetBookingsSortColumnDto.VenueName,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
        {
            title: t('address'),
            render: (booking: BookingDto) => (
                <div style={{ display: 'flex' }}>
                    <AddressPin address={booking.venue?.address} />
                </div>
            ),
            key: 'address',
            className: 'col-border-right',
        },
        {
            title: t('consumer'),
            render: (booking: BookingDto) => (
                <div className="consumer-col">
                    <div className="p-small">
                        {booking.consumer?.firstName} {booking.consumer?.lastName}
                    </div>
                    <div className="caption-medium">{booking.consumer?.contactInfo?.email}</div>
                </div>
            ),
            key: GetBookingsSortColumnDto.ConsumerName,
            sorter: true,
        },
        {
            title: t('paid_price'),
            render: (booking: BookingDto) => <Price price={booking.price} />,
            key: GetBookingsSortColumnDto.Price,
            sorter: true,
        },
        {
            title: t('booking_date'),
            render: (booking: BookingDto) => (
                <div className="d-flex">
                    <Tag
                        color="darkGreen"
                        text={moment.utc(booking.date).format(SHORT_MONTH_DATE_YEAR) ?? ''}
                    />
                    {booking.isCancelled && (
                        <div className="canceled caption-medium-bold element-light-primary">
                            <div className="circle" />
                            {t('canceled')}
                        </div>
                    )}
                </div>
            ),
            key: GetBookingsSortColumnDto.Date,
            sorter: true,
            width: 175,
        },
    ];

    return (
        <Content className="Bookings" designGridColAmount={TABLE_VIEW_GRID_COLUMNS}>
            <TableFilters
                pageTitle={t('bookings')}
                filterStore={filterStoreRef.current}
                includeSearch
                includeBookingStatus
                includeOperators={!userIsOperator}
                defaultSelectedOperator={defaultSelectedOperator}
            />
            <Table
                className="clickable"
                columns={columns}
                dataSource={bookings}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey={(booking): string => booking.id ?? ''}
                loading={loading}
                onRow={(row: BookingDto) => ({
                    onClick: (): void => {
                        history.push(BOOKINGS_URL + `/${row.id}`);
                    },
                })}
            />
        </Content>
    );
});

export default Bookings;
