import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const MagnifyingGlassIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M12.8411 20.5356C14.4253 20.5356 15.9098 20.0618 17.1453 19.2525L21.5092 23.5855C21.7981 23.8618 22.1668 24 22.5653 24C23.3922 24 24 23.3584 24 22.5491C24 22.174 23.8705 21.8088 23.5915 21.5325L19.2575 17.2192C20.1542 15.9558 20.6822 14.426 20.6822 12.7678C20.6822 8.49403 17.1552 5 12.8411 5C8.53697 5 5 8.49403 5 12.7678C5 17.0416 8.52701 20.5356 12.8411 20.5356ZM12.8411 18.4629C9.68275 18.4629 7.09229 15.8966 7.09229 12.7678C7.09229 9.63896 9.68275 7.07273 12.8411 7.07273C15.9995 7.07273 18.5899 9.63896 18.5899 12.7678C18.5899 15.8966 15.9995 18.4629 12.8411 18.4629Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default MagnifyingGlassIcon;
