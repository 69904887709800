import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PinIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <g clipPath="url(#clip0_1562_16476)">
                    <path
                        d="M14 1.75C11.4482 1.75301 9.00181 2.76804 7.19742 4.57242C5.39304 6.37681 4.37801 8.82322 4.375 11.375C4.37195 13.4603 5.05311 15.4891 6.314 17.15C6.314 17.15 6.5765 17.4956 6.61938 17.5455L14 26.25L21.3841 17.5411C21.4226 17.4948 21.686 17.15 21.686 17.15L21.6869 17.1474C22.9471 15.4872 23.628 13.4594 23.625 11.375C23.622 8.82322 22.607 6.37681 20.8026 4.57242C18.9982 2.76804 16.5518 1.75301 14 1.75ZM14 14.875C13.3078 14.875 12.6311 14.6697 12.0555 14.2851C11.4799 13.9006 11.0313 13.3539 10.7664 12.7144C10.5015 12.0749 10.4322 11.3711 10.5673 10.6922C10.7023 10.0133 11.0356 9.38961 11.5251 8.90013C12.0146 8.41064 12.6383 8.0773 13.3172 7.94225C13.9961 7.8072 14.6999 7.87651 15.3394 8.14142C15.9789 8.40633 16.5256 8.85493 16.9101 9.4305C17.2947 10.0061 17.5 10.6828 17.5 11.375C17.4988 12.3029 17.1297 13.1925 16.4736 13.8486C15.8175 14.5047 14.9279 14.8738 14 14.875Z"
                        fill={fill}
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1562_16476">
                        <rect width="28" height="28" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </span>
    );
};

export default PinIcon;
