import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { initialPaginationState } from 'Models/InitialPaginationState';
import { VenueDto } from 'Api/Features/Venues/Dtos/VenueDto';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import Content from 'Components/content';
import { useService, useStores } from 'Hooks';
import {
    CREATING_ID_URL,
    DEBOUNCE_DELAY_400,
    TABLE_VIEW_GRID_COLUMNS,
    VENUES_URL,
} from 'Models/Constants';
import { autorun } from 'mobx';
import debounce from 'lodash.debounce';
import ImageNameTableColumn from 'Components/image-name-table-column';
import { VenueService } from 'Services/VenueService';
import { GetVenuesRequestDto } from 'Api/Features/Venues/Dtos/GetVenuesRequestDto';
import Price from 'Components/price';
import { useTranslation } from 'react-i18next';
import AddressPin from 'Components/address-pin';
import TableFilters from 'Components/table-filters/table-filters';
import { FilterStore } from 'Stores';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import './index.less';
import { GetVenuesSortColumnDto } from 'Api/Features/Venues/Dtos/GetVenuesSortColumnDto';
import { VenueStatusDto } from 'Api/Features/Venues/Dtos/VenueStatusDto';
import Tag from 'Components/tag/tag';
import { useHistory } from 'react-router-dom';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { getVenueStatusTagColor } from 'Utils/TagUtils';
import { useUrlQuery } from 'Hooks/use-url-query';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';

const Venues: React.FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { toastStore, userStore } = useStores();
    const venueService = useService(VenueService);
    const paginationRef = useRef(initialPaginationState);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [loading, setLoading] = useState(false);
    const [venues, setVenues] = useState<VenueDto[]>([]);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters: [] }));
    const history = useHistory();
    const urlQuery = useUrlQuery();
    const [userIsOperator, setUserIsOperator] = useState<boolean | undefined>(undefined);
    const [defaultSelectedOperator, setDefaultSelectedOperator] = useState<
        SingleSelectCustomOption | undefined
    >(undefined);

    useEffect(() => {
        setUserIsOperator(userStore.userRole === ManagementRoleDto.Operator);
    }, [userStore.userRole]);

    useEffect(() => {
        //query string can contain default operator to filter on
        const id = urlQuery.get('operatorId');
        const first = urlQuery.get('firstName');
        const last = urlQuery.get('lastName');
        const company = urlQuery.get('companyName');
        if (id && first && last && company) {
            setDefaultSelectedOperator({
                value: id,
                label: `${company} - ${first} ${last}`,
            } as SingleSelectCustomOption);
            console.log(urlQuery.get('operatorId'));
        }
    }, [urlQuery]);

    const fetchVenues = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: any | null;
            sortDirection: SortDirection | null;
            operatorIds: string[] | null;
            statuses: VenueStatusDto[] | null;
        }) => {
            try {
                setLoading(true);
                if (userStore.userInfo?.id) {
                    const request: GetVenuesRequestDto = {
                        pageSize: params.pagination.pageSize || 0,
                        page: (params.pagination.current || 1) - 1,
                        searchTerm: params.searchTerm,
                        sortColumn: params.sortColumn,
                        sortDirection: params.sortDirection,
                        operatorIds:
                            userStore.userRole === ManagementRoleDto.Administrator
                                ? params.operatorIds
                                : [userStore.userInfo?.id ?? ''],
                        statuses: params.statuses,
                    };

                    const [items, totalItemsCount] = await venueService.getVenues(request);
                    setVenues(items);
                    setPagination({
                        ...params.pagination,
                        total: totalItemsCount,
                    });
                }
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        []
    );

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        const { searchTerm, operatorId, venueStatuses } = filterStoreRef.current;
        await fetchVenues({
            pagination,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
            operatorIds:
                userStore.userRole === ManagementRoleDto.Operator
                    ? [userStore.userInfo?.id ?? '']
                    : operatorId
                    ? [operatorId]
                    : null,
            statuses: venueStatuses,
        });

        paginationRef.current = pagination;
    };

    const debounceSearch = useRef(
        debounce(
            (params: {
                searchTerm?: string;
                operatorIds: string[] | null;
                statuses: VenueStatusDto[] | null;
            }) => {
                fetchVenues({
                    pagination: {
                        ...paginationRef.current,
                        current: 1,
                    },
                    searchTerm: params.searchTerm,
                    sortColumn: GetVenuesSortColumnDto.Name,
                    sortDirection: SortDirection.Ascending,
                    operatorIds: params.operatorIds,
                    statuses: params.statuses,
                });
            },
            DEBOUNCE_DELAY_400
        )
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                operatorIds: filterStore.operatorId ? [filterStore.operatorId] : null,
                statuses: filterStore.venueStatuses,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    const columns: ColumnType<VenueDto>[] = [
        {
            title: t('name'),
            render: (venue: VenueDto) => (
                <div className="name-column d-flex-align">
                    <ImageNameTableColumn
                        name={venue.name}
                        subtitle={
                            userIsOperator
                                ? undefined
                                : `${venue?.operator?.companyName} - ${venue?.operator?.firstName} ${venue?.operator?.lastName}`
                        }
                        imageUrl={venue.images?.[0]?.url}
                    />
                    {venue.status !== VenueStatusDto.Active && (
                        <Tag
                            color={getVenueStatusTagColor(venue.status)}
                            text={t(`VenueStatusDto.VenueStatusDto_${venue.status}`)}
                        />
                    )}
                </div>
            ),
            key: GetVenuesSortColumnDto.Name,
            defaultSortOrder: 'ascend',
            sorter: true,
        },
        {
            title: t('address'),
            render: (venue: VenueDto) => (
                <div style={{ display: 'flex' }}>
                    <AddressPin address={venue.address} />
                </div>
            ),
            key: 'address',
        },
        {
            title: t('Venues.inventory'),
            render: (venue: VenueDto) => (
                <div className="p-medium-bold element-main-secondary">{venue.inventory}</div>
            ),
            key: GetVenuesSortColumnDto.Inventory,
            sorter: true,
        },
        {
            title: t('Venues.daily_price'),
            render: (venue: VenueDto) => <Price price={venue.price} />,
            key: GetVenuesSortColumnDto.Price,
            sorter: true,
        },
    ];

    return (
        <Content className="Venues" designGridColAmount={TABLE_VIEW_GRID_COLUMNS}>
            <TableFilters
                pageTitle={t('venues')}
                filterStore={filterStoreRef.current}
                includeSearch
                includeVenueStatuses
                includeOperators={userStore.userRole === ManagementRoleDto.Administrator}
                defaultSelectedOperator={defaultSelectedOperator}
                actionbutton={{
                    buttonText: t('create_entity'),
                    buttonIcon: 'PlusIcon',
                    onClick: () => history.push(VENUES_URL + '/' + CREATING_ID_URL),
                }}
            />
            <Table
                className="clickable"
                columns={columns}
                dataSource={venues}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey={(venue): string => venue.id ?? ''}
                loading={loading}
                onRow={(row: VenueDto) => ({
                    onClick: (): void => {
                        history.push(VENUES_URL + `/${row.id}`);
                    },
                })}
            />
        </Content>
    );
});

export default Venues;
