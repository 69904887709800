import { inject } from 'aurelia-dependency-injection';
import { Environment } from 'Misc/Environment';
import { ROOT_URL_TEMP } from 'Models/Constants';
import { AuthenticationStore } from 'Stores';
import { AuthConfig, AuthorizationConfigService } from './AuthorizationConfigService';

@inject(AuthenticationStore, AuthorizationConfigService, Environment)
export class AzureAdAuthenticationService {
    constructor(
        private readonly authenticationStore: AuthenticationStore,
        private readonly authorizationConfigService: AuthorizationConfigService,
        private readonly environment: Environment
    ) {}

    /**
     * Runs our "Sign-in" user flow in Azure AD B2C.
     * Once the flow is finished, Azure will redirect to our site (redirectUri).
     */
    public async runSignInFlow(): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        // PKCE
        const codeVerifier = this.generateRandomString(64);
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        window.sessionStorage.setItem('code_verifier', codeVerifier);
        window.sessionStorage.setItem('tokenEndpoint', parameters.tokenEndpoint);

        const azureADStateId = this.generateRandomString(10);
        this.authenticationStore.setAzureADStateId(azureADStateId);

        const redirectUri =
            window.location.protocol + '//' + window.location.host + '/actions/signin-callback';
        const args = new URLSearchParams({
            response_type: 'code',
            client_id: parameters.clientId,
            code_challenge_method: 'S256',
            code_challenge: codeChallenge,
            redirect_uri: redirectUri,
            scope: parameters.scopes,
            state: azureADStateId,
        });

        // Go to Azure URL.
        window.location = (parameters.authorizeEndpoint + '/?' + args) as any;
    }

    /**
     * Get a new token with the refresh token
     */
    public async refreshToken(token: string): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = () => {
                const response = xhr.response;
                if (xhr.status == 200) {
                    this.authenticationStore.setSession(
                        response.access_token,
                        response.refresh_token
                    );
                    resolve();
                } else {
                    reject();
                    this.runSignInFlow();
                }
            };
            xhr.responseType = 'json';
            xhr.open('POST', parameters.tokenEndpoint, true);
            xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhr.send(
                new URLSearchParams({
                    client_id: parameters.clientId,
                    grant_type: 'refresh_token',
                    redirect_uri: window.location.protocol + '//' + window.location.host,
                    scope: parameters.scopes,
                    refresh_token: token,
                })
            );
        });
    }

    /**
     * Asks Azure to logout the current user, if necessary.
     */
    public async signOut(): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        const args = new URLSearchParams({
            // After logout with Azure, redirect to web app root.
            post_logout_redirect_uri: window.location.protocol + '//' + window.location.host,
        });

        // Go to Azure URL.
        window.location = (parameters.logoutEndpoint + '/?' + args) as any;
    }

    /**
     * Runs our "Reset password" user flow in Azure AD B2C.
     * Once the flow is finished, Azure will redirect to our site (redirectUri).
     */
    public async runResetPasswordFlow(): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        // PKCE
        const codeVerifier = this.generateRandomString(64);
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        window.sessionStorage.setItem('code_verifier', codeVerifier);

        const redirectUri =
            window.location.protocol +
            '//' +
            window.location.host +
            '/actions/reset-password-callback';
        const args = new URLSearchParams({
            response_type: 'code',
            client_id: parameters.clientId,
            code_challenge_method: 'S256',
            code_challenge: codeChallenge,
            redirect_uri: redirectUri,
            scope: parameters.scopes,
        });

        // Go to Azure URL.
        window.location = (parameters.resetPasswordEndpoint + '/?' + args) as any;
    }

    /**
     * Runs our "Change password" user flow in Azure AD B2C.
     * Once the flow is finished, Azure will redirect to our site (redirectUri).
     */
    public async runChangePasswordFlow(userEmail: string): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        // PKCE
        const codeVerifier = this.generateRandomString(64);
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        window.sessionStorage.setItem('code_verifier', codeVerifier);

        const redirectUri =
            window.location.protocol +
            '//' +
            window.location.host +
            '/actions/change-password-callback';
        const args = new URLSearchParams({
            response_type: 'code',
            client_id: parameters.clientId,
            code_challenge_method: 'S256',
            code_challenge: codeChallenge,
            redirect_uri: redirectUri,
            scope: parameters.scopes,
            login_hint: userEmail,
        });

        // Go to Azure URL.
        window.location = (parameters.changePasswordEndpoint + '/?' + args) as any;
    }

    /**
     * Runs our "Change email" user flow in Azure AD B2C.
     * Once the flow is finished, Azure will redirect to our site (redirectUri).
     */
    public async runChangeEmailFlow(userEmail: string): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        // PKCE
        const codeVerifier = this.generateRandomString(64);
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        window.sessionStorage.setItem('code_verifier', codeVerifier);

        const redirectUri =
            window.location.protocol +
            '//' +
            window.location.host +
            '/actions/change-email-callback';
        const args = new URLSearchParams({
            response_type: 'code',
            client_id: parameters.clientId,
            code_challenge_method: 'S256',
            code_challenge: codeChallenge,
            redirect_uri: redirectUri,
            scope: parameters.scopes,
            login_hint: userEmail,
        });

        // Go to Azure URL.
        window.location = (parameters.changeEmailAddressEndpoint + '/?' + args) as any;
    }

    /**
     * Runs our "Confirm account" user flow in Azure AD B2C.
     * Once the flow is finished, Azure will redirect to our site (redirectUri).
     *
     * @param email Email address of the user account to confirm.
     * @param token Account confirmation token (usually sent in an email).
     */
    public async runConfirmAccountFlow(email: string, token: string): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        // PKCE
        const codeVerifier = this.generateRandomString(64);
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        window.sessionStorage.setItem('code_verifier', codeVerifier);

        const redirectUri =
            window.location.protocol +
            '//' +
            window.location.host +
            '/actions/confirm-account-callback';
        const args = new URLSearchParams({
            response_type: 'code',
            client_id: parameters.clientId,
            code_challenge_method: 'S256',
            code_challenge: codeChallenge,
            redirect_uri: redirectUri,
            scope: parameters.scopes,
            email: email,
            token: token,
        });

        // Go to Azure URL.
        window.location = (parameters.confirmAccountEndpoint + '/?' + args) as any;
    }

    // Check if we were just redirected from the AD server after authorize.
    public async handleAuthorizeCallback(): Promise<void> {
        const parameters: AuthConfig = await this.authorizationConfigService.getAuthConfig();

        if (window.location.search) {
            const args = new URLSearchParams(window.location.search);
            const code = args.get('code');
            const state = args.get('state');
            if (code) {
                const xhr = new XMLHttpRequest();
                xhr.onload = () => {
                    const response = xhr.response;
                    if (xhr.status == 200) {
                        this.authenticationStore.setSession(
                            response.access_token,
                            response.refresh_token
                        );
                        if (
                            state &&
                            state === this.authenticationStore.azureADStateId &&
                            this.authenticationStore.returnUrl
                        ) {
                            this.authenticationStore.clearAzureADStateId();
                            window.location = this.authenticationStore.returnUrl as any;
                        } else {
                            window.location = ROOT_URL_TEMP as any;
                        }
                    } else {
                        //something went wrong getting the token. We are now connected on azure side, but cannot get a token on our side.
                        window.location = (window.location.protocol +
                            '//' +
                            window.location.host +
                            '/actions/signin-failure') as any;
                    }
                };
                xhr.responseType = 'json';
                xhr.open('POST', parameters.tokenEndpoint, true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                xhr.send(
                    new URLSearchParams({
                        client_id: parameters.clientId,
                        code_verifier: window.sessionStorage.getItem('code_verifier') ?? '',
                        grant_type: 'authorization_code',
                        redirect_uri: window.location.protocol + '//' + window.location.host,
                        code: code,
                        scope: parameters.scopes,
                        state: state ?? '',
                    })
                );
            }
        }
        return;
    }

    private async generateCodeChallenge(codeVerifier): Promise<string> {
        const digest = await crypto.subtle.digest(
            'SHA-256',
            new TextEncoder().encode(codeVerifier)
        );
        return btoa(String.fromCharCode(...new Uint8Array(digest)))
            .replace(/=/g, '')
            .replace(/\+/g, '-')
            .replace(/\//g, '_');
    }

    private generateRandomString(length): string {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
}
