import moment from 'moment';

export enum BookingStatusKey {
    Past = 'Past',
    CurrentUpcoming = 'CurrentUpcoming',
}

export const BookingStatusFilterMap = new Map<
    BookingStatusKey,
    {
        minStartTime: string | null;
        maxStartTime: string | null;
        minEndTime: string | null;
        maxEndTime: string | null;
    }
>([
    [
        BookingStatusKey.Past,
        {
            maxEndTime: moment().startOf('day').format(),
            minStartTime: null,
            maxStartTime: null,
            minEndTime: null,
        },
    ],
    [
        BookingStatusKey.CurrentUpcoming,
        {
            maxEndTime: null,
            minStartTime: null,
            maxStartTime: null,
            minEndTime: moment().startOf('day').format(),
        },
    ],
]);
