// GENERATED FILE - DO NOT MODIFY
import { BookingDetailsDto } from 'Api/Features/Bookings/Dtos/BookingDetailsDto';
import { CancelBookingRequestDto } from 'Api/Features/Bookings/Dtos/CancelBookingRequestDto';
import { ConfirmBookingPaymentIntentResponseDto } from 'Api/Features/Bookings/Dtos/ConfirmBookingPaymentIntentResponseDto';
import { CreateBookingPaymentIntentRequestDto } from 'Api/Features/Bookings/Dtos/CreateBookingPaymentIntentRequestDto';
import { CreateBookingPaymentIntentResponseDto } from 'Api/Features/Bookings/Dtos/CreateBookingPaymentIntentResponseDto';
import { CreateBookingRequestDto } from 'Api/Features/Bookings/Dtos/CreateBookingRequestDto';
import { CreateBookingResponseDto } from 'Api/Features/Bookings/Dtos/CreateBookingResponseDto';
import { GetBookingPreviewRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingPreviewRequestDto';
import { GetBookingPreviewResponseDto } from 'Api/Features/Bookings/Dtos/GetBookingPreviewResponseDto';
import { GetBookingsRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingsRequestDto';
import { GetBookingsResponseDto } from 'Api/Features/Bookings/Dtos/GetBookingsResponseDto';
import { GetUserBookingsRequestDto } from 'Api/Features/Bookings/Dtos/GetUserBookingsRequestDto';
import { GetUserBookingsResponseDto } from 'Api/Features/Bookings/Dtos/GetUserBookingsResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class BookingProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getBooking(bookingId: string): Promise<BookingDetailsDto | null> {
        const uri = this.buildUri(
            "/bookings/{bookingId}",
            { bookingId: bookingId },
            null
        );

        const data: BookingDetailsDto | null = await this.httpClient.get<BookingDetailsDto | null>(uri);
        return data;
    }

    public async getUserBookings(userId: string, request: GetUserBookingsRequestDto | null): Promise<GetUserBookingsResponseDto | null> {
        const uri = this.buildUri(
            "/users/{userId}/bookings",
            { userId: userId },
            { ...request || undefined }
        );

        const data: GetUserBookingsResponseDto | null = await this.httpClient.get<GetUserBookingsResponseDto | null>(uri);
        return data;
    }

    public async getBookings(request: GetBookingsRequestDto | null): Promise<GetBookingsResponseDto | null> {
        const uri = this.buildUri(
            "/bookings",
            null,
            { ...request || undefined }
        );

        const data: GetBookingsResponseDto | null = await this.httpClient.get<GetBookingsResponseDto | null>(uri);
        return data;
    }

    public async createBooking(request: CreateBookingRequestDto | null): Promise<CreateBookingResponseDto | null> {
        const uri = this.buildUri(
            "/bookings",
            null,
            null
        );

        const data: CreateBookingResponseDto | null = await this.httpClient.post<CreateBookingRequestDto | null, CreateBookingResponseDto | null>(uri, request);
        return data;
    }

    public async getBookingPreview(request: GetBookingPreviewRequestDto | null): Promise<GetBookingPreviewResponseDto | null> {
        const uri = this.buildUri(
            "/booking-preview",
            null,
            null
        );

        const data: GetBookingPreviewResponseDto | null = await this.httpClient.post<GetBookingPreviewRequestDto | null, GetBookingPreviewResponseDto | null>(uri, request);
        return data;
    }

    public async createBookingPaymentIntent(request: CreateBookingPaymentIntentRequestDto | null): Promise<CreateBookingPaymentIntentResponseDto | null> {
        const uri = this.buildUri(
            "/booking-payment-intents",
            null,
            null
        );

        const data: CreateBookingPaymentIntentResponseDto | null = await this.httpClient.post<CreateBookingPaymentIntentRequestDto | null, CreateBookingPaymentIntentResponseDto | null>(uri, request);
        return data;
    }

    public async confirmBookingPaymentIntent(bookingPaymentIntentId: string): Promise<ConfirmBookingPaymentIntentResponseDto | null> {
        const uri = this.buildUri(
            "/booking-payment-intents/{bookingPaymentIntentId}/confirm",
            { bookingPaymentIntentId: bookingPaymentIntentId },
            null
        );

        const data: ConfirmBookingPaymentIntentResponseDto | null = await this.httpClient.post<void, ConfirmBookingPaymentIntentResponseDto | null>(uri, );
        return data;
    }

    public async cancelBooking(bookingId: string, request: CancelBookingRequestDto | null): Promise<void> {
        const uri = this.buildUri(
            "/bookings/{bookingId}/cancel",
            { bookingId: bookingId },
            { ...request || undefined }
        );

        await this.httpClient.post<void, void>(uri, );
    }
}