//to enable color referencing in components

export const theme = {
    // Brand
    'main-primary-color': '#2E605F',
    'main-primary-color-overlay': '#58807F',

    'main-secondary-color': '#D5DFDF',
    'main-secondary-color-overlay': '#EBF0F0',

    'main-tertiary-color': '#234847',
    'main-tertiary-color-overlay': '#173030',
    //--------------------
    'accent-primary-color': '#D58047',
    'accent-primary-color-overlay': '#DD996C',

    'accent-secondary-color': '#F7E6DA',
    'accent-secondary-color-overlay': '#FBF3ED',

    'accent-tertiary-color': '#A06035',
    'accent-tertiary-color-overlay': '#6B4024',
    //--------------------

    // Surfaces
    'surfaces-dark': '#1E1E1E',
    'surfaces-primary': '#BBC8CD',
    'surfaces-secondary': '#CCD6D9',
    'surfaces-tertiary': '#EDF0F2',
    'surfaces-disabled': '#EEF1F2',
    'surfaces-background': '#FFFFFF',

    // Alerts
    'alerts-success-primary': '#37BE6E',
    'alerts-success-overlay': '#5FCB8B',

    'alerts-success-secondary': '#D7F2E2',
    'alerts-success-secondary-overlay': '#ECF9F1',
    //--------------------
    'alerts-warning-primary': '#F2DA52',
    'alerts-warning-primary-overlay': '#F5E175',

    'alerts-warning-secondary': '#FCF8DC',
    'alerts-warning-secondary-overlay': '#FEFCEE',
    //--------------------
    'alerts-error-primary': '#D7382D',
    'alerts-error-primary-overlay': '#DF6057',

    'alerts-error-secondary': '#F7D7D5',
    'alerts-error-secondary-overlay': '#FCECEB',

    // Elements (a.k.a font color)
    'element-light-primary': '#2B2F30',
    'element-light-secondary': '#808C90',
    'element-light-tertiary': '#CCD6D9',
    //--------------------
    'element-dark-primary': '#FFFFFF',
    'element-dark-secondary': '#AABAC0',
    'element-dark-tertiary': '#555D60',
    //--------------------
    'element-main-primary': '#EBF0F0',
    'element-main-secondary': '#2E605F',
    //--------------------
    'element-accent-primary': '#FBF3ED',
    'element-accent-secondary': '#A06035',
    //--------------------
    'element-success-primary': '#ECF9F1',
    'element-success-secondary': '#1C5F37',
    //--------------------
    'element-warning-primary': '#181608',
    'element-warning-secondary': '#3D3715',
    //--------------------
    'element-error-primary': '#FCECEB',
    'element-error-secondary': 'A12A22',

    white: '#ffffff',
    black: '#000000',
};
