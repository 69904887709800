import { WorkhopWrittenGreenLogo } from 'Components/icons';
import WhiteCard from 'Components/white-card';
import { useWindowDimensions } from 'Hooks/use-window-dimensions';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import './index.less';

const TermsAndConditions: React.FunctionComponent = () => {
    const { windowWidth } = useWindowDimensions();
    const { t } = useTranslation();
    return (
        <div className="TermsAndConditions">
            <div className="content">
                <div className="banner">
                    <WorkhopWrittenGreenLogo
                        fill={theme['main-primary-color']}
                        width={190}
                        height={43}
                    />
                </div>

                <WhiteCard
                    paddingTop={40}
                    paddingBottom={40}
                    padding={windowWidth < 1080 ? 20 : 40}
                >
                    <div className="title-h2-bold">{t('terms_and_conditions')}</div>

                    <div className="title-h4-bold">{t('TC.tc_h1')}</div>
                    <div className="p-medium">{t('TC.tc_p1')}</div>
                    <div className="p-medium">{t('TC.tc_p2')}</div>
                    <div className="p-medium">{t('TC.tc_p3')}</div>

                    <div className="title-h4-bold">{t('TC.tc_h2')}</div>
                    <div className="p-medium">{t('TC.tc_p4')}</div>
                    <div className="p-medium">{t('TC.tc_p5')}</div>

                    <div className="title-h4-bold">{t('TC.tc_h3')}</div>
                    <div className="p-medium">{t('TC.tc_p6')}</div>

                    <div className="title-h4-bold">{t('TC.tc_h4')}</div>
                    <div className="p-medium" dangerouslySetInnerHTML={{ __html: t('TC.tc_p7') }} />
                    <div className="p-medium">{t('TC.tc_p8')}</div>
                    <div className="p-medium">{t('TC.tc_p9')}</div>

                    <div className="title-h4-bold">{t('TC.tc_h5')}</div>
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('TC.tc_p10') }}
                    />

                    <div className="title-h4-bold">{t('TC.tc_h6')}</div>
                    <div className="p-medium">{t('TC.tc_p11')}</div>
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('TC.tc_p12') }}
                    />
                    <div className="p-medium">{t('TC.tc_p13')}</div>
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('TC.tc_p14') }}
                    />
                    <div className="p-medium">{t('TC.tc_p15')}</div>
                    <div className="p-medium">{t('TC.tc_p16')}</div>
                    <div className="title-h4-bold">{t('TC.tc_h7')}</div>
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('TC.tc_p17') }}
                    />

                    <div className="title-h4-bold">{t('TC.tc_h8')}</div>
                    <div className="p-medium">{t('TC.tc_p18')}</div>

                    <div className="title-h4-bold">{t('TC.tc_h9')}</div>
                    <div className="p-medium">{t('TC.tc_p19')}</div>

                    <div className="title-h4-bold">{t('TC.tc_h10')}</div>
                    <div className="p-medium">{t('TC.tc_p20')}</div>

                    <div className="title-h4-bold">{t('TC.tc_h11')}</div>
                    <div className="p-medium">{t('TC.tc_p21')}</div>

                    <div className="title-h4-bold">{t('TC.tc_h12')}</div>
                    <div className="p-medium">{t('TC.tc_p22')}</div>
                    <div className="p-medium">{t('TC.tc_p23')}</div>

                    <div className="title-h4-bold">{t('TC.tc_h13')}</div>
                    <div className="p-medium">{t('TC.tc_p24')}</div>
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('TC.tc_p25') }}
                    />
                    <div className="p-medium">{t('TC.tc_p26')}</div>
                </WhiteCard>

                <div className="margin-enabler" />

                <div className="footer p-medium ">
                    {moment().format('YYYY')} ©workhop all rights reserved.
                </div>

                <div className="curve">
                    <svg
                        width="100%"
                        height="30%"
                        viewBox={`0 0 1920 ${
                            windowWidth < 700
                                ? windowWidth + 100
                                : windowWidth < 1470
                                ? '500'
                                : '290'
                        }`}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <ellipse cx="982" cy="518" rx="1234" ry="518" fill="#234847" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
