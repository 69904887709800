import { UserAccountStatusDto } from 'Api/Features/Users/Dtos/UserAccountStatusDto';
import { VenueStatusDto } from 'Api/Features/Venues/Dtos/VenueStatusDto';
import { TagColor } from 'Components/tag/tag';

export const getVenueStatusTagColor = (status?: VenueStatusDto): TagColor => {
    switch (status) {
        case VenueStatusDto.Active:
            return 'darkGreen';
        case VenueStatusDto.Deactivated:
            return 'darkGray';
        case VenueStatusDto.Pending:
            return 'yellow';
        case VenueStatusDto.Rejected:
            return 'red';
        default:
            return 'darkGreen';
    }
};

export const getAccountStatusTagColor = (status?: UserAccountStatusDto): TagColor => {
    switch (status) {
        case UserAccountStatusDto.Active:
            return 'darkGreen';
        case UserAccountStatusDto.Deactivated:
            return 'gray';
        case UserAccountStatusDto.Pending:
            return 'yellow';
        default:
            return 'darkGreen';
    }
};
