import { useService } from 'Hooks';
import React, { FunctionComponent, useEffect } from 'react';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';

export const Login: FunctionComponent = () => {
    const azureAdAuthenticationService = useService(AzureAdAuthenticationService);

    useEffect(() => {
        const signIn = async () => {
            await azureAdAuthenticationService.runSignInFlow();
        };

        signIn();
    }, []);

    return <div></div>;
};

export default Login;
