// GENERATED FILE - DO NOT MODIFY
import { GetVenueSalesReportRequestDto } from 'Api/Features/Reports/Dtos/GetVenueSalesReportRequestDto';
import { GetVenueSalesReportResponseDto } from 'Api/Features/Reports/Dtos/GetVenueSalesReportResponseDto';
import { ApiHttpClient } from 'Api/ApiHttpClient';
import { ApiProxyBase } from 'Api/ApiProxyBase';

export class ReportProxy extends ApiProxyBase {
    static inject = [ApiHttpClient];

    public async getVenueSalesReport(request: GetVenueSalesReportRequestDto | null): Promise<GetVenueSalesReportResponseDto | null> {
        const uri = this.buildUri(
            "/venue-sales-report",
            null,
            { ...request || undefined }
        );

        const data: GetVenueSalesReportResponseDto | null = await this.httpClient.get<GetVenueSalesReportResponseDto | null>(uri);
        return data;
    }
}