import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AmenityConferenceIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.9205 4.76807H6.91949C6.68557 4.76807 6.49597 4.96073 6.49597 5.19844V16.5592C6.49597 16.797 6.68557 16.9896 6.91949 16.9896H12.2075V18.253C12.2075 18.4907 12.397 18.6834 12.631 18.6834H13.8975L9.89242 22.7533C9.72702 22.9214 9.72702 23.1939 9.89242 23.362C9.97515 23.446 10.0835 23.4881 10.1919 23.4881C10.3003 23.4881 10.4086 23.446 10.4914 23.362L14.92 18.8616L18.9402 22.947C19.1057 23.1151 19.3738 23.1151 19.5392 22.947C19.7046 22.7789 19.7046 22.5064 19.5392 22.3383L15.9425 18.6833H17.0479C17.2818 18.6833 17.4714 18.4907 17.4714 18.253V16.9896H22.9205C23.1544 16.9896 23.344 16.7969 23.344 16.5592V5.19844C23.344 4.96073 23.1543 4.76807 22.9205 4.76807ZM7.343 5.62882H22.4969V16.1289H21.2853V14.7639C21.2853 14.5261 21.0957 14.3335 20.8617 14.3335H17.2841C17.0502 14.3335 16.8606 14.5261 16.8606 14.7639V16.1289H7.343V5.62882ZM16.6855 17.8226H13.0941V16.9896H16.6855V17.8226ZM17.7754 15.1942V16.1289H20.5224V15.1942H17.7754Z"
                    fill={fill}
                />
                <path
                    d="M11.7236 10.1532C11.8424 10.2124 11.9819 10.2125 12.1007 10.1532L13.9512 9.23093L15.8015 10.1532C15.9203 10.2124 16.0596 10.2125 16.1785 10.1532L18.0293 9.23093L19.88 10.1532C19.9406 10.1834 20.0049 10.1977 20.0682 10.1977C20.2253 10.1977 20.3764 10.1096 20.4506 9.95769C20.5547 9.74451 20.468 9.48648 20.257 9.38127L18.2179 8.36501C18.0989 8.30581 17.9597 8.30581 17.8408 8.36501L15.99 9.28731L14.1397 8.36501C14.021 8.30586 13.8816 8.30581 13.7626 8.36501L11.9122 9.28731L10.0619 8.36501C9.85101 8.2599 9.59542 8.34737 9.49122 8.56055C9.38711 8.77368 9.47375 9.03176 9.6848 9.13696L11.7236 10.1532Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.9205 4.76807H6.91949C6.68557 4.76807 6.49597 4.96073 6.49597 5.19844V16.5592C6.49597 16.797 6.68557 16.9896 6.91949 16.9896H12.2075V18.253C12.2075 18.4907 12.397 18.6834 12.631 18.6834H13.8975L9.89242 22.7533C9.72702 22.9214 9.72702 23.1939 9.89242 23.362C9.97515 23.446 10.0835 23.4881 10.1919 23.4881C10.3003 23.4881 10.4086 23.446 10.4914 23.362L14.92 18.8616L18.9402 22.947C19.1057 23.1151 19.3738 23.1151 19.5392 22.947C19.7046 22.7789 19.7046 22.5064 19.5392 22.3383L15.9425 18.6833H17.0479C17.2818 18.6833 17.4714 18.4907 17.4714 18.253V16.9896H22.9205C23.1544 16.9896 23.344 16.7969 23.344 16.5592V5.19844C23.344 4.96073 23.1543 4.76807 22.9205 4.76807ZM7.343 5.62882H22.4969V16.1289H21.2853V14.7639C21.2853 14.5261 21.0957 14.3335 20.8617 14.3335H17.2841C17.0502 14.3335 16.8606 14.5261 16.8606 14.7639V16.1289H7.343V5.62882ZM16.6855 17.8226H13.0941V16.9896H16.6855V17.8226ZM17.7754 15.1942V16.1289H20.5224V15.1942H17.7754Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AmenityConferenceIcon;
