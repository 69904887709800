import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const ThreeDotMenu: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M14 22C13.45 22 12.9793 21.8043 12.588 21.413C12.196 21.021 12 20.55 12 20C12 19.45 12.196 18.979 12.588 18.587C12.9793 18.1957 13.45 18 14 18C14.55 18 15.021 18.1957 15.413 18.587C15.8043 18.979 16 19.45 16 20C16 20.55 15.8043 21.021 15.413 21.413C15.021 21.8043 14.55 22 14 22ZM14 16C13.45 16 12.9793 15.804 12.588 15.412C12.196 15.0207 12 14.55 12 14C12 13.45 12.196 12.979 12.588 12.587C12.9793 12.1957 13.45 12 14 12C14.55 12 15.021 12.1957 15.413 12.587C15.8043 12.979 16 13.45 16 14C16 14.55 15.8043 15.0207 15.413 15.412C15.021 15.804 14.55 16 14 16ZM14 10C13.45 10 12.9793 9.804 12.588 9.412C12.196 9.02067 12 8.55 12 8C12 7.45 12.196 6.97933 12.588 6.588C12.9793 6.196 13.45 6 14 6C14.55 6 15.021 6.196 15.413 6.588C15.8043 6.97933 16 7.45 16 8C16 8.55 15.8043 9.02067 15.413 9.412C15.021 9.804 14.55 10 14 10Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default ThreeDotMenu;
