import { ReactNode } from 'react';

export interface MultiSelectCustomOption {
    value: string;
    label: string;
    content?: { node: ReactNode; className?: string };
    // imageUrl?: string;
    isDisabled?: boolean;
    extraData?: any;
}

export const MultiSelectCommonStyles: any = {
    option: (provided: any) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
    }),
    indicatorSeparator: (styles: any) => ({
        ...styles,
        display: 'none',
    }),
    input: (styles: any) => ({
        ...styles,
        marginLeft: '-10px',
        position: 'relative',
        left: '10px',
    }),
    multiValueRemove: (styles: any) => ({
        ...styles,
        ':hover': {
            backgroundColor: 'transparent',
        },
    }),
    //permet au menu ouvert de sortir de l'epace d'un modal
    menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 1000000,
    }),
};

//** Selected tags styles info.
//** Depending on project if style changes, these values need to be updated. This is needed to calculate how many tags can fit in the select
export const SELECT_TAG_CHAR_WIDTH = 6;
export const SELECT_TAG_LABEL_LEFT_PADDING = 4;
export const SELECT_TAG_REMOVE_ICON_WIDTH = 16;
export const SELECT_TAG_REMOVE_ICON_PADDING = 4;
//div[class*="ValueContainer"]
export const SELECT_VALUE_CONTAINER_X_PADDING = 8;
//space between tags
export const SELECT_VALUE_CONTAINER_COLUMN_GAP = 8;
export const SELECT_EXTRA_HIDDEN_TAG = 35;
