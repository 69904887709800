import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import './index.less';
import { theme } from 'Style/theme';

interface GooglePlacesAutoCompleteProps {
    onChange: (value?: string, label?: string) => void;
    defaultValue?: { label: string; value: string };
}

const GooglePlacesAutoComplete: FunctionComponent<GooglePlacesAutoCompleteProps> = observer(
    ({ onChange, defaultValue }) => {
        const handleChange = (event: any) => {
            if (event === null) onChange(undefined, undefined);
            else onChange(event.value.place_id, event.label);
        };

        //https://react-select.com/styles
        const customStyles = {
            indicatorSeparator: (provided: any) => ({
                ...provided,
                display: 'none',
            }),
            dropdownIndicator: (provided: any) => ({
                ...provided,
                display: 'none',
            }),
            singleValue: (provided: any) => ({
                ...provided,
                fontSize: '15px',
                fontWeight: '400',
                color: theme['text-high-contrast'],
            }),
            placeholder: (provided: any) => ({
                ...provided,
            }),
            input: (provided: any) => ({
                ...provided,
            }),
        };

        return (
            <div className="GooglePlacesAutoComplete">
                <GooglePlacesAutocomplete
                    autocompletionRequest={{
                        componentRestrictions: {
                            country: ['usa'],
                        },
                        types: ['address'],
                    }}
                    selectProps={{
                        defaultValue: defaultValue,
                        onChange: handleChange,
                        styles: customStyles,
                        placeholder: 'Search for address',
                    }}
                    apiKey={window.Environment.REACT_APP_GOOGLE_API_KEY}
                />
            </div>
        );
    }
);

export default GooglePlacesAutoComplete;
