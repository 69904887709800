import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AmenityPetIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M13.1733 9.19297C13.1733 8.05498 11.8822 7.15196 10.3078 7.41476C7.99666 7.79955 6.94053 12.3319 7.03305 13.1457C7.09854 13.7212 8.4453 14.5555 10.0262 13.9644C11.0586 13.5779 11.6309 12.7772 11.9452 11.9176M16.6667 9.19297C16.6667 8.05498 17.9578 7.15196 19.5321 7.41476C21.8433 7.79955 22.8994 12.3319 22.8069 13.1457C22.7414 13.7212 21.3947 14.5555 19.8138 13.9644C18.7814 13.5779 18.2951 12.7772 17.9807 11.9176M11.5359 16.4204V16.8298M18.0855 16.4204V16.8298M8.60494 14.1666C8.37581 15.0513 8.26026 15.9617 8.26108 16.8756C8.26108 20.2913 11.1937 22.1513 14.8107 22.1513C18.4277 22.1513 21.3603 20.2913 21.3603 16.8756C21.3603 16.007 21.2276 15.0745 20.9566 14.1666M13.3894 9.18724C13.8575 9.09527 14.3336 9.05002 14.8107 9.05215C15.4493 9.05215 16.0387 9.14057 16.5799 9.30267M14.1967 18.2625H15.4247L14.8107 18.8765L14.1967 18.2625Z"
                    stroke={fill}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </span>
    );
};

export default AmenityPetIcon;
