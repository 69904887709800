import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { initialPaginationState } from 'Models/InitialPaginationState';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import Content from 'Components/content';
import { useService, useStores } from 'Hooks';
import { DEBOUNCE_DELAY_400, TABLE_VIEW_GRID_COLUMNS } from 'Models/Constants';
import { autorun } from 'mobx';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import TableFilters from 'Components/table-filters/table-filters';
import { FilterStore } from 'Stores';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { UserService } from 'Services/UserService';
import Tag from 'Components/tag/tag';
import { UserAccountStatusDto } from 'Api/Features/Users/Dtos/UserAccountStatusDto';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { GetUsersResponseItemDto } from 'Api/Features/Users/Dtos/GetUsersResponseItemDto';
import { GetUsersSortColumnDto } from 'Api/Features/Users/Dtos/GetUsersSortColumnDto';
import { getAccountStatusTagColor } from 'Utils/TagUtils';
import './index.less';

const Consumers: React.FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { toastStore } = useStores();
    const userService = useService(UserService);
    const paginationRef = useRef(initialPaginationState);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [loading, setLoading] = useState(false);
    const [consumers, SetConsumers] = useState<GetUsersResponseItemDto[]>([]);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters: [] }));

    const fetchConsumers = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: any | null;
            sortDirection: SortDirection | null;
            statuses: UserAccountStatusDto[] | null;
        }) => {
            try {
                setLoading(true);
                const request: GetUsersRequestDto = {
                    pageSize: params.pagination.pageSize || 0,
                    page: (params.pagination.current || 1) - 1,
                    searchTerm: params.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    hasManagementRole: false,
                    accountStatuses: params.statuses,
                };

                const [items, totalItemsCount] = await userService.getUsers(request);
                SetConsumers(items);
                setPagination({
                    ...params.pagination,
                    total: totalItemsCount,
                });
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [userService, toastStore]
    );

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        const { searchTerm, accountStatuses } = filterStoreRef.current;
        await fetchConsumers({
            pagination,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
            statuses: accountStatuses,
        });

        paginationRef.current = pagination;
    };

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string; statuses: UserAccountStatusDto[] | null }) => {
            fetchConsumers({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                searchTerm: params.searchTerm,
                sortColumn: null,
                sortDirection: null,
                statuses: params.statuses,
            });
        }, DEBOUNCE_DELAY_400)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                statuses: filterStore.accountStatuses,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    const columns: ColumnType<GetUsersResponseItemDto>[] = [
        {
            title: t('name'),
            render: (consumer: GetUsersResponseItemDto) => (
                <div className="d-flex-align">
                    {`${consumer.firstName} ${consumer.lastName}`}
                    {consumer.accountStatus !== UserAccountStatusDto.Active && (
                        <Tag
                            color={getAccountStatusTagColor(consumer.accountStatus)}
                            text={t(
                                `UserAccountStatusDto.UserAccountStatusDto_${consumer.accountStatus}`
                            )}
                        />
                    )}
                </div>
            ),
            key: GetUsersSortColumnDto.Name,
            sorter: true,
        },
        {
            title: t('email'),
            render: (consumer: GetUsersResponseItemDto) => consumer.contactInfo?.email,
            key: GetUsersSortColumnDto.Email,
            sorter: true,
        },
        {
            title: t('Consumers.booking_in_last_30'),
            render: (consumer: GetUsersResponseItemDto) => (
                <div className="d-flex-align">
                    <span className="p-medium-bold element-main-secondary">
                        {consumer.recentBookingsCount}
                    </span>
                    {(consumer.recentCancelledBookingsCount ?? 0) > 0 && (
                        <Tag
                            text={`${consumer.recentCancelledBookingsCount} Canceled`}
                            color="lightGreen"
                        />
                    )}
                </div>
            ),
        },
    ];
    return (
        <Content className="Consumers" designGridColAmount={TABLE_VIEW_GRID_COLUMNS}>
            <TableFilters
                includeSearch
                pageTitle={t('consumers')}
                filterStore={filterStoreRef.current}
                includeAccountStatuses
            />
            <Table
                columns={columns}
                dataSource={consumers}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey={(consumer): string => consumer.id ?? ''}
                loading={loading}
            />
        </Content>
    );
});

export default Consumers;
