import { AmenityDto } from 'Api/Features/Venues/Dtos/AmenityDto';
import { inject } from 'aurelia-dependency-injection';
import { observable, action } from 'mobx';
import { VenueService } from 'Services/VenueService';
import moment from 'moment';
import { AmenityIdIconMap } from 'Models/Amenities/amenityIdIconMap';

export interface AmenityDtoWithIcon extends AmenityDto {
    icon: string;
}

@inject(VenueService)
class AmenityStore {
    constructor(private readonly venueService: VenueService) {}

    private amenityPromise: any;
    private lastAmenityFetchTime: string | undefined = undefined;

    @observable
    public fetchInProgess = false;

    @observable amenities?: AmenityDtoWithIcon[];

    private async fetchAmenities(): Promise<void> {
        try {
            this.fetchInProgess = true;
            const amenities = await this.venueService.getAmenities();

            this.amenities = amenities?.items
                ?.filter((amenity) => amenity !== null)
                .map((amenity) => ({
                    ...amenity,
                    icon: AmenityIdIconMap[amenity?.id ?? ''],
                }));

            this.lastAmenityFetchTime = moment().format();
        } catch (e: any) {
            this.amenities = undefined;
        }
    }

    @action
    async cachedSetAmenities(): Promise<void> {
        if (
            this.lastAmenityFetchTime === undefined ||
            moment(this.lastAmenityFetchTime).add(5, 'minute').isBefore(moment())
        ) {
            if (!this.fetchInProgess) {
                this.amenityPromise = this.fetchAmenities();
            }

            await this.amenityPromise;
            this.amenityPromise = null;
        }
    }

    @action
    async forceSetAmenities(): Promise<void> {
        this.fetchAmenities();
    }
}

export default AmenityStore;
