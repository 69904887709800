import Content from 'Components/content';
import SquigglyLine from 'Components/squiggly-line';
import WhiteCard from 'Components/white-card';
import { observer } from 'mobx-react';
import { DETAILS_VIEW_GRID_COLUMNS } from 'Models/Constants';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const AboutUs: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    return (
        <Content className="AboutUs" designGridColAmount={DETAILS_VIEW_GRID_COLUMNS}>
            <div className="top-container title-h4-bold mb-20">
                <span>{t('about_us')}</span>
            </div>
            <WhiteCard padding={40}>
                <div className="p-medium">
                    Welcome to Workhop, the ultimate platform for remote workers, freelancers,
                    entrepreneurs, and digital nomads who are seeking a professional yet flexible
                    workspace to grow their careers and businesses.
                </div>

                <div className="p-medium">
                    Our mission is to provide a community-driven and affordable experience for our
                    users, offering a comfortable and inspiring environment that fosters creativity,
                    productivity, and collaboration. We believe that everyone deserves access to
                    high-quality workspace, regardless of their location or budget.
                </div>

                <div className="p-medium">
                    Our simple, yet powerful app is designed to empower the modern worker to work
                    smarter, not harder. With our intuitive platform, people can easily find, book
                    and manage a workspace that fits the needs of flexible working, where users can
                    access our network of workspaces across multiple cities and countries, with the
                    flexibility to switch between locations as you need.
                </div>

                <div className="p-medium">
                    By providing a flexible service, the modern worker now can access on demand a
                    range of amenities like never before as well as services that cater to their
                    professional and personal needs. From high-speed Wi-Fi and printing facilities
                    to complimentary coffee and snacks to make the workday as seamless and enjoyable
                    as possible. Our partner workplaces also organizes networking events, workshops,
                    and social activities to help our on demand users connect with like-minded
                    individuals and expand your professional network.
                </div>

                <div className="p-medium">
                    Workhop is a team of passionate and dedicated professionals who are committed to
                    providing you with an exceptional experience. Our partners are at the heart of
                    everything we do, and we continuously strive to improve our services based on
                    your feedback and suggestions.
                </div>

                <div className="p-medium">
                    Thank you for choosing Workhop to tap into the modern worker. We are excited to
                    welcome you to our community and support you in achieving your business goals.
                </div>

                <SquigglyLine marginTop={20} marginbottom={20} />

                <div className="p-medium">From, The Workhop Team xxx</div>
            </WhiteCard>
        </Content>
    );
});

export default AboutUs;
