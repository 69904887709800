import React, { FunctionComponent } from 'react';
import './index.less';

interface PriceProps {
    price?: number | null;
    priceSize?: 'small' | 'medium' | 'large';
    currencySize?: 'small' | 'medium' | 'large';
    currency?: string;
    color?: 'green' | 'black';
    priceBold?: boolean;
    currencyBold?: boolean;
}

const Price: FunctionComponent<PriceProps> = ({
    price,
    priceSize = 'medium',
    currencySize = 'medium',
    currency = 'USD',
    color = 'green',
    priceBold = true,
    currencyBold = true,
}) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return (
        <span
            className={`Price ${
                color === 'green' ? 'element-main-secondary' : 'element-light-primary'
            } p-${priceSize}${priceBold ? '-bold' : ''}`}
        >
            {formatter.format(price ?? 0)}
            <span className={`currency ${`caption-${currencySize}${currencyBold ? '-bold' : ''}`}`}>
                {currency}
            </span>
        </span>
    );
};

export default Price;
