import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import { UserAccountStatusDto } from 'Api/Features/Users/Dtos/UserAccountStatusDto';
import { VenueStatusDto } from 'Api/Features/Venues/Dtos/VenueStatusDto';
import { observable, action } from 'mobx';
import { AdvancedFilter } from 'Models/Filters/AdvancedFilter';
import { BookingStatusKey } from 'Models/Filters/BookingStatusFilter';

interface FilterStoreArgs {
    advancedFilters: AdvancedFilter[];
}

class FilterStore {
    constructor({ advancedFilters }: FilterStoreArgs) {
        this.advancedFilters = advancedFilters;
    }

    @observable advancedFilters?: AdvancedFilter[];
    @observable searchTerm = '';
    @observable venueStatuses: VenueStatusDto[] = [];
    @observable accountStatuses: UserAccountStatusDto[] = [];
    @observable bookingStatus: BookingStatusKey | undefined = BookingStatusKey.CurrentUpcoming;
    @observable operatorId: string | undefined = undefined;
    @observable month: string | undefined = undefined;
    @observable role: string | undefined = undefined;

    @action
    updateSearchTerm(term: string): void {
        this.searchTerm = term;
    }

    @action
    updateVenueStatuses(statuses: VenueStatusDto[]): void {
        this.venueStatuses = statuses;
    }

    @action
    updateAccountStatuses(statuses: UserAccountStatusDto[]): void {
        this.accountStatuses = statuses;
    }

    @action
    updateBookingStatuses(status?: BookingStatusKey): void {
        this.bookingStatus = status;
    }

    @action
    updateOperator(id?: string): void {
        this.operatorId = id;
    }

    @action
    updateMonth(month?: string): void {
        this.month = month;
    }

    @action
    updateRole(role?: ManagementRoleDto): void {
        this.role = role;
    }

    @action
    clear(): void {
        this.searchTerm = '';
    }
}

export default FilterStore;
