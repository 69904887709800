import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { initialPaginationState } from 'Models/InitialPaginationState';
import { ColumnType, TablePaginationConfig } from 'antd/lib/table';
import Content from 'Components/content';
import { useService, useStores } from 'Hooks';
import {
    CREATING_ID_URL,
    DEBOUNCE_DELAY_400,
    MANAGERS_URL,
    TABLE_VIEW_GRID_COLUMNS,
} from 'Models/Constants';
import { autorun } from 'mobx';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import TableFilters from 'Components/table-filters/table-filters';
import { FilterStore } from 'Stores';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { useHistory } from 'react-router-dom';
import { UserService } from 'Services/UserService';
import { GetOperatorsRequestDto } from 'Api/Features/Users/Dtos/GetOperatorsRequestDto';
import { GetOperatorsSortColumnDto } from 'Api/Features/Users/Dtos/GetOperatorsSortColumnDto';
import { GetOperatorsResponseItemDto } from 'Api/Features/Users/Dtos/GetOperatorsResponseItemDto';
import Tag from 'Components/tag/tag';
import { UserAccountStatusDto } from 'Api/Features/Users/Dtos/UserAccountStatusDto';
import { getAccountStatusTagColor } from 'Utils/TagUtils';

const Managers: React.FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { toastStore } = useStores();
    const userService = useService(UserService);
    const paginationRef = useRef(initialPaginationState);
    const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState);
    const [loading, setLoading] = useState(false);
    const [operators, SetOperators] = useState<GetOperatorsResponseItemDto[]>([]);
    const filterStoreRef = useRef(new FilterStore({ advancedFilters: [] }));
    const history = useHistory();

    const fetchManagers = useCallback(
        async (params: {
            pagination: TablePaginationConfig;
            searchTerm?: string;
            sortColumn: any | null;
            sortDirection: SortDirection | null;
            statuses: UserAccountStatusDto[] | null;
        }) => {
            try {
                setLoading(true);
                const request: GetOperatorsRequestDto = {
                    pageSize: params.pagination.pageSize || 0,
                    page: (params.pagination.current || 1) - 1,
                    searchTerm: params.searchTerm,
                    sortColumn: params.sortColumn,
                    sortDirection: params.sortDirection,
                    accountStatuses: params.statuses,
                };

                const [items, totalItemsCount] = await userService.getOperators(request);
                SetOperators(items);
                setPagination({
                    ...params.pagination,
                    total: totalItemsCount,
                });
            } catch (e: any) {
                if (!e.treated) {
                    toastStore.genericError();
                }
            } finally {
                setLoading(false);
            }
        },
        [userService, toastStore]
    );

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: any,
        sorter: any
    ): Promise<void> => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        const { searchTerm, accountStatuses } = filterStoreRef.current;
        await fetchManagers({
            pagination,
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
            statuses: accountStatuses,
        });

        paginationRef.current = pagination;
    };

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string; statuses: UserAccountStatusDto[] | null }) => {
            fetchManagers({
                pagination: {
                    ...paginationRef.current,
                    current: 1,
                },
                searchTerm: params.searchTerm,
                sortColumn: null,
                sortDirection: null,
                statuses: params.statuses,
            });
        }, DEBOUNCE_DELAY_400)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                statuses: filterStore.accountStatuses,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    const columns: ColumnType<GetOperatorsResponseItemDto>[] = [
        {
            title: t('company_name'),
            render: (manager: GetOperatorsResponseItemDto) => (
                <div className="d-flex">
                    {manager.companyName}
                    {manager.accountStatus !== UserAccountStatusDto.Active && (
                        <div className="ml-auto">
                            <Tag
                                text={t(
                                    `UserAccountStatusDto.UserAccountStatusDto_${manager.accountStatus}`
                                )}
                                color={getAccountStatusTagColor(manager.accountStatus)}
                            />
                        </div>
                    )}
                </div>
            ),
            key: GetOperatorsSortColumnDto.CompanyName,
            sorter: true,
        },
        {
            title: t('name'),
            render: (manager: GetOperatorsResponseItemDto) =>
                `${manager.firstName} ${manager.lastName}`,
            key: GetOperatorsSortColumnDto.Name,
            sorter: true,
        },
        {
            title: t('email'),
            render: (manager: GetOperatorsResponseItemDto) => manager.contactInfo?.email,
            key: GetOperatorsSortColumnDto.Email,
            sorter: true,
        },
        {
            title: t('role'),
            render: (manager: GetOperatorsResponseItemDto) => manager.managementRoles?.[0]?.name,
            key: GetOperatorsSortColumnDto.ManagementRoles,
            sorter: true,
        },
        {
            title: t('total_venues'),
            render: (operator: GetOperatorsResponseItemDto) => (
                <div className="d-flex">
                    <div className="p-medium-bold element-main-secondary">
                        {operator.venuesCount}
                    </div>
                    {(operator.pendingVenuesCount ?? 0) > 0 && (
                        <div className="ml-auto">
                            <Tag
                                color="lightGreen"
                                text={`Needs Approval (${operator.pendingVenuesCount})`}
                            />
                        </div>
                    )}
                </div>
            ),
            key: GetOperatorsSortColumnDto.VenuesCount,
            sorter: true,
        },
    ];
    return (
        <Content className="Manager" designGridColAmount={TABLE_VIEW_GRID_COLUMNS}>
            <TableFilters
                pageTitle={t('managers')}
                filterStore={filterStoreRef.current}
                includeSearch
                includeAccountStatuses
                includeRole
                actionbutton={{
                    buttonText: t('create_entity'),
                    buttonIcon: 'PlusIcon',
                    onClick: () => history.push(MANAGERS_URL + '/' + CREATING_ID_URL),
                }}
            />
            <Table
                className="clickable"
                columns={columns}
                dataSource={operators}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey={(operator): string => operator.id ?? ''}
                loading={loading}
                onRow={(row: GetOperatorsResponseItemDto) => ({
                    onClick: (): void => {
                        history.push(MANAGERS_URL + `/${row.id}`);
                    },
                })}
            />
        </Content>
    );
});

export default Managers;
