import React, { FunctionComponent, ReactNode } from 'react';
import './tag.less';

export type TagColor = 'lightGreen' | 'darkGreen' | 'yellow' | 'red' | 'gray' | 'darkGray';

export interface TagProps extends TagStyle {
    text: string;
    color: TagColor;
}

interface TagStyle {
    squareTag?: boolean;
    uppercase?: boolean;
    capitalize?: boolean;
    dotTag?: boolean;
    canDelete?: boolean;
    onClick?: () => void;
    toolTipTitle?: string;
    icon?: ReactNode;
}

const Tag: FunctionComponent<TagProps> = ({
    text,
    color,
    squareTag = false,
    dotTag = false,
    capitalize = true,
    uppercase = false,
    onClick,
    icon,
}) => {
    const getCapitalizeOrUppercase = (): string => {
        if (capitalize == false) return uppercase == true ? 'uppercase' : '';
        return 'capitalize';
    };

    return (
        <div
            onClick={onClick}
            className={`Tag text-caption-1-bold ${color} square-${squareTag} dot-${dotTag} ${getCapitalizeOrUppercase()}`}
        >
            <div className="tag-inner-container">
                {icon && <div className="icon-container">{icon}</div>}
                <div className="caption-medium-bold">{text}</div>
            </div>
        </div>
    );
};

export default Tag;
