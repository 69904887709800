import React, { FunctionComponent, useEffect } from 'react';
import { autorun } from 'mobx';
import { message } from 'antd';
import './toast-container.less';
import { useStores } from 'Hooks';
import CheckmarkIcon from 'Components/icons/checkmark-icon';
import { CloseIcon } from 'Components/icons';
import { theme } from 'Style/theme';

const ToastContainer: FunctionComponent = () => {
    const { toastStore } = useStores();
    useEffect(
        () =>
            autorun(() => {
                if (toastStore.currentToast) {
                    message[toastStore.currentToast.type]({
                        content:
                            toastStore.currentToast.message ||
                            toastStore.currentToast.messageKey ||
                            '',
                        className: `custom-toast ${toastStore.currentToast.type}`,
                        icon:
                            toastStore.currentToast.type === 'success' ? (
                                <CheckmarkIcon
                                    fill={theme['element-main-primary']}
                                    width={20}
                                    height={20}
                                />
                            ) : (
                                <CloseIcon
                                    fill={theme['element-light-primary']}
                                    width={20}
                                    height={20}
                                />
                            ),
                        duration: toastStore.currentToast.duration ?? 5,
                    });
                }
            }),
        [toastStore.currentToast]
    );
    return null;
};

export default ToastContainer;
