export const AmenityIdIconMap = {
    '22f32967-cb33-4ab3-a434-b8d9f0951036': 'AmenityBikeIcon',
    '1169ad86-0a44-45b5-9fa5-15e08deebd0a': 'AmenityCoffeeIcon',
    '07b651c8-a2cb-474e-9280-35772ff575b7': 'AmenityGiftIcon',
    '10e5565b-514a-4417-8db1-baa888722fc5': 'AmenityConferenceIcon',
    '54e38376-04f9-40ea-83d3-55a622ce59d4': 'AmenityCustomerIcon',
    'f19b0896-e102-478d-875e-b983b4f110c5': 'AmenityBeerIcon',
    'cac12a42-db81-4404-aa4c-bf1086be1868': 'AmenityKitchenIcon',
    '60b35211-36e0-4f24-823e-cd8015f9e5d7': 'AmenityLoungeIcon',
    'b063101c-464d-463a-91f9-44a7bfe43d72': 'AmenityTransportIcon',
    '26023ed3-1394-47fb-a287-ce728114cd16': 'AmenityParkingIcon',
    '3912bb3e-e1b9-4e24-bd56-943292c8c658': 'AmenityPetIcon',
    '9f70e542-7fb8-4caa-ae1d-efa06db3e637': 'AmenityPrintingIcon',
    '3aed1314-b686-4d80-a6b3-06106c5e8425': 'AmenityPhoneIcon',
    '08a4cfdf-7101-4b58-91c0-56754a7b5891': 'AmenityStorageIcon',
    '2f00fcb6-b091-42e5-bff8-9c44cc2712c4': 'AmenityWifiIcon',
};
