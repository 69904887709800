import * as yup from 'yup';

export const CreateVenueSchema = yup.object({
    name: yup.string().required('Errors.error_required').max(85, 'Errors.error_max_85'),
    description: yup
        .string()
        .required('Errors.error_required')
        .max(340, 'Errors.error_max_340')
        .test(
            'not empty test',
            () => 'Errors.error_required',
            (value) => value !== '\n'
        ),
    price: yup.number().required('Errors.error_required'),
    inventory: yup.number().required('Errors.error_required'),
    openingTime: yup.string().required('Errors.error_required'),
    closingTime: yup.string().required('Errors.error_required'),
    addressGooglePlaceId: yup.string().required('Errors.error_required'),
    contactInfo: yup.object({
        email: yup.string().required('Errors.error_required').email('Errors.error_invalid_email'),
        phoneNumber: yup.string().required('Errors.error_required'),
        website: yup.string().required('Errors.error_required'),
    }),
    mainImage: yup.string().required('Errors.error_required'),
});
