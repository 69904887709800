import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AmenityPhoneIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M14.4518 21.0645L14.4525 21.0638C14.5763 20.9396 14.7257 20.8768 14.92 20.8768C15.1143 20.8768 15.2643 20.9396 15.3888 21.0641C15.5125 21.1879 15.5752 21.3374 15.5752 21.532C15.5752 21.7267 15.5125 21.8762 15.3888 21.9999C15.2643 22.1245 15.1143 22.1872 14.92 22.1872C14.7257 22.1872 14.5763 22.1245 14.4525 22.0002L14.4518 21.9996C14.3276 21.8758 14.2648 21.7264 14.2648 21.532C14.2648 21.3377 14.3276 21.1883 14.4518 21.0645Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.5208 24.5272C9.48693 24.5272 8.6488 23.6891 8.6488 22.6552V6.36883C8.6488 5.33495 9.48693 4.49683 10.5208 4.49683H19.3192C20.3531 4.49683 21.1912 5.33495 21.1912 6.36883V22.6552C21.1912 23.6891 20.3531 24.5272 19.3192 24.5272H10.5208ZM9.9592 8.61523V18.5368H19.8808V8.61523H9.9592ZM9.9592 19.8472V23.2168H19.8808V19.8472H9.9592ZM9.9592 5.80723V7.30483H19.8808V5.80723H9.9592Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AmenityPhoneIcon;
