import React from 'react';
import ValidatedRoute from 'Components/validated-route/validated-route';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedLayout from 'Components/authenticated-layout/authenticated-layout';
import NotFoundPage from './404-not-found';
import Venues from './venues';
import {
    ABOUT_US_URL,
    BOOKINGS_URL,
    CONSUMERS_URL,
    MANAGERS_URL,
    PROFILE_URL,
    SALES_URL,
    VENUES_URL,
} from 'Models/Constants';
import VenueDetails from './venues/id';
import NavigationBlocker from 'Components/navigation-blocker';
import AnonymousLayout from 'Components/anonymous-layout/anonymous-layout';
import ConfirmAccount from './actions/confirm-account';
import ConfirmAccountCallback from './actions/confirm-account-callback';
import Bookings from './bookings';
import BookingDetails from './bookings/id';
import Profile from './profile';
import Login from './login';
import SignInCallback from './actions/signin-callback';
import SignInFailure from './actions/signin-failure';
import ChangePasswordCallback from './actions/change-password-callback';
import Sales from './sales';
import PrivacyPolicy from './privacy-policy';
import ChangeEmailCallback from './actions/change-email-callback';
import Managers from './managers';
import ManagerDetails from './managers/id';
import Consumers from './consumers';
import AboutUs from './about-us';
import TermsAndConditions from './terms-conditions';

const Routes: React.FunctionComponent = observer(() => {
    return (
        <Switch>
            {/* Anonymous routes */}
            <Route exact path="/actions/confirm-account-callback">
                <AnonymousLayout>
                    <ConfirmAccountCallback />
                </AnonymousLayout>
            </Route>

            <Route exact path="/actions/confirm-account">
                <AnonymousLayout>
                    <ConfirmAccount />
                </AnonymousLayout>
            </Route>

            <Route exact path="/actions/signin-callback">
                <AnonymousLayout>
                    <SignInCallback />
                </AnonymousLayout>
            </Route>

            <Route exact path="/actions/signin-failure">
                <AnonymousLayout>
                    <SignInFailure />
                </AnonymousLayout>
            </Route>

            <Route exact path="/privacy-policy">
                <AnonymousLayout>
                    <PrivacyPolicy />
                </AnonymousLayout>
            </Route>

            <Route exact path="/terms-and-conditions">
                <AnonymousLayout>
                    <TermsAndConditions />
                </AnonymousLayout>
            </Route>

            <Route exact path="/">
                <AnonymousLayout>
                    <Login />
                </AnonymousLayout>
            </Route>
            {/* -- END Anonymous routes */}

            {/* Authenticated routes */}
            <ValidatedRoute exact path={PROFILE_URL}>
                <AuthenticatedLayout>
                    <Profile />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={VENUES_URL}>
                <AuthenticatedLayout>
                    <Venues />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={VENUES_URL + '/:id'}>
                <AuthenticatedLayout>
                    <NavigationBlocker>
                        <VenueDetails />
                    </NavigationBlocker>
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={BOOKINGS_URL}>
                <AuthenticatedLayout>
                    <Bookings />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={BOOKINGS_URL + '/:id'}>
                <AuthenticatedLayout>
                    <BookingDetails />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={MANAGERS_URL}>
                <AuthenticatedLayout>
                    <Managers />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={MANAGERS_URL + '/:id'}>
                <AuthenticatedLayout>
                    <NavigationBlocker>
                        <ManagerDetails />
                    </NavigationBlocker>
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={CONSUMERS_URL}>
                <AuthenticatedLayout>
                    <Consumers />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={SALES_URL}>
                <AuthenticatedLayout>
                    <Sales />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path={ABOUT_US_URL}>
                <AuthenticatedLayout>
                    <AboutUs />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path="/actions/change-password-callback">
                <AuthenticatedLayout>
                    <ChangePasswordCallback />
                </AuthenticatedLayout>
            </ValidatedRoute>

            <ValidatedRoute exact path="/actions/change-email-callback">
                <AuthenticatedLayout>
                    <ChangeEmailCallback />
                </AuthenticatedLayout>
            </ValidatedRoute>

            {/* must be last route */}
            <ValidatedRoute>
                <NotFoundPage />
            </ValidatedRoute>

            {/* -- END Authenticated routes */}
        </Switch>
    );
});

export default Routes;
