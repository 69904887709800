import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const WorkhopWLogo: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="126"
                height="100"
                viewBox="0 0 126 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.784314 14.6467C-1.39631 9.21029 1.24301 3.03547 6.67941 0.854838C12.1158 -1.32579 18.2906 1.31353 20.4713 6.74993L41.8575 60.0667L59.7082 15.5639C61.8889 10.1275 68.0637 7.48822 73.5001 9.66885C73.6329 9.72212 73.764 9.77777 73.8934 9.83574C76.3639 10.918 78.4323 12.9215 79.525 15.601L92.8663 48.3172L104.503 19.7807C106.715 14.3569 112.925 11.7028 118.374 13.8526C123.822 16.0024 126.446 22.142 124.234 27.5658L102.83 80.0548C102.721 80.3212 102.603 80.5808 102.476 80.8335C101.38 83.1615 99.4247 85.0926 96.8277 86.1173C91.379 88.2671 85.169 85.6129 82.9572 80.1891L69.6903 47.6553L51.8097 92.2325L51.8003 92.2558C50.7943 94.9591 48.6956 97.2448 45.8076 98.4032C40.3712 100.584 34.1964 97.9445 32.0158 92.5081L0.784314 14.6467ZM93.4759 99.2064C103.249 99.2064 111.171 97.766 111.171 95.9891C111.171 94.2123 103.249 92.7718 93.4759 92.7718C83.7031 92.7718 75.7808 94.2123 75.7808 95.9891C75.7808 97.766 83.7031 99.2064 93.4759 99.2064Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default WorkhopWLogo;
