import Icon from 'Components/icons/Icon';
import React, { HTMLProps } from 'react';
import './index.less';

const getIcon = (icons: Icons) => {
    if (icons.leftIcon)
        return (
            <span className="icon-container leftIcon">
                <Icon iconName={icons.leftIcon} />
            </span>
        );
    if (icons.rightIcon)
        return (
            <span className="icon-container rightIcon">
                <Icon iconName={icons.rightIcon} />
            </span>
        );

    return null;
};

export type Type = 'primary' | 'secondary' | 'soft' | 'link';

export type Width = 'full' | 'auto' | 'hugged';

export type Size = 'small' | 'medium' | 'large';

interface Icons {
    leftIcon?: string;
    rightIcon?: string;
}

export interface ButtonProps {
    text: string;
    type: Type;
    link?: string;
    width?: Width;
    sizeType: Size;
    onlyIcon?: boolean;
}

type Button = ButtonProps & Icons & HTMLProps<HTMLButtonElement>;

const Button: React.FunctionComponent<Button> = ({
    text,
    type,
    width,
    sizeType,
    leftIcon,
    rightIcon,
    className,
    disabled = false,
    onlyIcon = false,
    ...htmlProps
}) => {
    const classes = `Button ${
        className ?? ''
    } type-${type} width-${width} disabled-${disabled} size-${sizeType} onlyIcon-${onlyIcon}`;

    return (
        <button className={classes} disabled={disabled} {...htmlProps}>
            {getIcon({ leftIcon })}
            {!onlyIcon && (
                <span className={`${sizeType === 'small' ? 'p-small' : 'p-medium'}`}>{text}</span>
            )}
            {getIcon({ rightIcon })}
        </button>
    );
};

export default Button;
