import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PencilIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M20.0117 9.49719L20.9697 8.54797C21.4443 8.07336 21.4619 7.5548 21.0312 7.11535L20.6885 6.76379C20.2578 6.33312 19.7217 6.37707 19.2558 6.84289L18.2978 7.78332L20.0117 9.49719ZM9.2451 20.2462L19.1679 10.3234L17.4629 8.62707L7.54002 18.5323L6.67869 20.6066C6.58201 20.8702 6.85447 21.1603 7.11814 21.0636L9.2451 20.2462Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PencilIcon;
