import React, { useContext, useEffect, useState } from 'react';
import { Divider } from 'antd';
import Button from 'Components/button';
import { ChevronDownIcon } from 'Components/icons';
import Icon from 'Components/icons/Icon';
import { useService, useStores } from 'Hooks';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SideNavigationContext } from 'Components/authenticated-layout/authenticated-layout';
import { PROFILE_URL, SIDENAV_WIDTH_COLLAPSED, SIDENAV_WIDTH_OPEN } from 'Models/Constants';
import './layout-header.less';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';
import Popover from 'Components/popover';

export interface HeaderBackButton {
    url: string;
    title: string;
}

interface ListSectionHeaderProps {
    backButton?: HeaderBackButton;
    onHandleDisplayModeClick: () => void;
}

const LayoutHeader: React.FunctionComponent<ListSectionHeaderProps> = observer(
    ({ backButton, onHandleDisplayModeClick }) => {
        const sideNavContext = useContext(SideNavigationContext);
        const [marginLeft, setMarginLeft] = useState<number | undefined>();
        const { userStore, authenticationStore } = useStores();
        const azureAdAuthenticationService = useService(AzureAdAuthenticationService);
        const { t } = useTranslation();
        const history = useHistory();

        const logOut = async (): Promise<void> => {
            authenticationStore.clearSession();
            await azureAdAuthenticationService.signOut();
        };

        useEffect(() => {
            if (sideNavContext?.isAutoCollapseThreshold) {
                setMarginLeft(
                    sideNavContext?.collapsed
                        ? undefined
                        : SIDENAV_WIDTH_OPEN - SIDENAV_WIDTH_COLLAPSED
                );
            } else {
                setMarginLeft(undefined);
            }
        }, [sideNavContext?.collapsed, sideNavContext?.isAutoCollapseThreshold]);

        return (
            <div className={`LayoutHeader Shadow-100`} style={{ marginLeft }}>
                <div className="container-icon-menu-burger">
                    <Icon iconName="MenuIcon" onClick={onHandleDisplayModeClick} />
                </div>

                {backButton && (
                    <Button
                        text={backButton.title}
                        type="link"
                        width="hugged"
                        leftIcon="ArrowLeftIcon"
                        sizeType="medium"
                        onClick={() => history.push(backButton.url)}
                    />
                )}

                <div className="right-section">
                    <Divider className="divider-full-height" type="vertical" />

                    <div className="contact-card">
                        <Popover
                            triggerContent={
                                <div className="info-container">
                                    <div className="info">
                                        <div className="p-medium-bold">
                                            {userStore.userInfo?.firstName}{' '}
                                            {userStore.userInfo?.lastName}
                                        </div>
                                        <div className="caption-medium element-light-secondary">
                                            {userStore.userInfo?.contactInfo?.email}
                                        </div>
                                    </div>
                                    <ChevronDownIcon width={24} height={24} />
                                </div>
                            }
                            openMenuContent={
                                <>
                                    <div
                                        className="item text-body text-high-contrast"
                                        onClick={() => {
                                            history.push(PROFILE_URL);
                                        }}
                                    >
                                        {t('profile')}
                                    </div>
                                    <Divider />
                                    <div
                                        className="item text-body text-high-contrast"
                                        onClick={logOut}
                                    >
                                        {t('sign_out')}
                                    </div>
                                </>
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
);

export default LayoutHeader;
