import React, { FunctionComponent } from 'react';
import { img, ImgSize } from 'Utils/ImgUtils';
import './index.less';

interface ImageNameTableColumnProps {
    imageUrl?: string | null;
    name?: string | null;
    subtitle?: string | null;
}

const ImageNameTableColumn: FunctionComponent<ImageNameTableColumnProps> = ({
    imageUrl,
    name,
    subtitle,
}) => {
    return (
        <div className="ImageNameTableColumn">
            {imageUrl && <img src={img(imageUrl, ImgSize.s)} />}
            <div>
                <div className="p-medium element-light-primary">{name}</div>
                {subtitle && <div className="caption-medium">{subtitle}</div>}
            </div>
        </div>
    );
};

export default ImageNameTableColumn;
