import React, { FunctionComponent, ReactNode } from 'react';
import { Form } from 'antd';
import { FormItemProps } from 'antd/es/form';
import './index.less';

interface ValidatedFormItemProps extends FormItemProps {
    errors?: Map<string, string[]>;
    label: string;
    disabled?: boolean;
    classname?: string;
}

const ValidatedFormItem: FunctionComponent<ValidatedFormItemProps> = ({
    children,
    name,
    label,
    errors,
    disabled,
    className,
    ...props
}) => {
    const fieldErrors = errors?.get(name?.toString() || '');
    const errorProps: FormItemProps = {};
    if (fieldErrors) {
        errorProps.hasFeedback = true;
        errorProps.help = fieldErrors.map((errorKey) => `${errorKey}`);
        errorProps.validateStatus = 'error';
    }

    const formattedLabel = (): ReactNode => {
        return (
            <div className={`${disabled ? 'disabled' : ''} caption-medium element-light-primary`}>
                {label}
                {/* {props.required && <span>{'*'}</span>} */}
            </div>
        );
    };

    return (
        <div className={`ValidatedFormItem ${className ?? ''}`}>
            <Form.Item {...props} name={name} label={formattedLabel()} {...errorProps}>
                {children}
            </Form.Item>
        </div>
    );
};

export default ValidatedFormItem;
