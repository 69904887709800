import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const TableSorterIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path d="M8 11.6667L13.6667 6L19.3333 11.6667H8Z" fill={fill} />
                <path d="M8 17.3333L13.6667 23L19.3333 17.3333H8Z" fill={fill} />
            </svg>
        </span>
    );
};

export default TableSorterIcon;
