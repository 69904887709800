import { action, observable } from 'mobx';

interface ToastConfig {
    type: 'info' | 'success' | 'error' | 'warning';
    message?: string;
    messageKey?: string;
    duration?: number;
}

class ToastStore {
    @observable currentToast: ToastConfig | null = null;

    @action
    genericError(): void {
        this.currentToast = {
            type: 'error',
            messageKey: 'An unexpected error occurred.',
        };
    }

    @action
    serverError(): void {
        this.currentToast = {
            type: 'error',
            messageKey: 'An unexpected server error occurred.',
        };
    }

    @action
    toast(config: ToastConfig): void {
        this.currentToast = config;
    }
}

export default ToastStore;
