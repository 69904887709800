import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AmenityBikeIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.4406 12.2238L20.5061 12.3994H20.7045H21.472C23.9377 12.3994 25.8712 14.3328 25.8712 16.7986C25.8712 19.2673 23.713 21.3123 21.2512 21.1981C19.1908 21.1012 17.5481 19.6509 17.1611 17.6896L17.1164 17.4631H12.723L12.6788 17.6902C12.2832 19.721 10.5357 21.2071 8.36801 21.2071C5.90229 21.2071 3.96881 19.2736 3.96881 16.8079C3.96881 14.3479 6.12824 12.2947 8.58883 12.4178C10.6492 12.5146 12.2919 13.965 12.6789 15.9263L12.7236 16.1527H13.9826L12.0746 10.9111H11.176C10.8163 10.9111 10.5208 10.6156 10.5208 10.2559C10.5208 9.89621 10.8163 9.60072 11.176 9.60072H13.9653C14.325 9.60072 14.6205 9.89621 14.6205 10.2559C14.6205 10.6156 14.325 10.9111 13.9653 10.9111H13.4815L14.0207 12.4087H19.1216L17.55 8.10312H15.856C15.4963 8.10312 15.2008 7.80764 15.2008 7.44792C15.2008 7.08821 15.4963 6.79272 15.856 6.79272H17.3531C17.68 6.79386 17.9987 6.89518 18.2662 7.08302C18.5338 7.27088 18.7374 7.53653 18.8495 7.84365L20.4406 12.2238ZM17.1164 16.1527H16.8856H15.3788L14.4905 13.7191H18.2935L17.9517 14.1696C17.5635 14.6814 17.2839 15.2687 17.1616 15.9235L17.1164 16.1527ZM11.2733 17.8347L11.4001 17.4631H9.30401C8.94429 17.4631 8.64881 17.1676 8.64881 16.8079C8.64881 16.4482 8.94429 16.1527 9.30401 16.1527H11.4003L11.2732 15.7811C10.8609 14.5749 9.73563 13.7191 8.36801 13.7191C6.58848 13.7191 5.20757 15.1536 5.27943 16.9502L5.27952 16.9521C5.35168 18.4984 6.62492 19.7937 8.16346 19.8868C9.62133 19.979 10.8401 19.1033 11.2733 17.8347ZM18.3833 16.8887L18.3833 16.89C18.4246 18.4887 19.7591 19.8339 21.3476 19.8965C21.7645 19.9159 22.1809 19.8503 22.5717 19.7038C22.9629 19.5572 23.32 19.3326 23.6216 19.0435C23.9231 18.7545 24.1627 18.4072 24.3258 18.0226C24.4888 17.6382 24.572 17.2246 24.5702 16.8071C24.5697 15.0488 23.1667 13.6781 21.4 13.7192L21.2875 13.7218L21.0721 13.9372L21.7231 15.7249C21.8469 16.0638 21.6721 16.4375 21.3288 16.5629C20.99 16.6867 20.6163 16.512 20.4909 16.1687L19.7725 14.1989L19.4651 14.4597C18.7845 15.0373 18.3627 15.9036 18.3833 16.8887Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AmenityBikeIcon;
