import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const LaptopIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M2.16113 21.0283H25.8389C26.4541 21.0283 26.9639 20.5186 26.9639 19.9033C26.9639 19.2793 26.4541 18.7695 25.8389 18.7695H23.9844V8.40723C23.9844 7.02734 23.2285 6.29785 21.8574 6.29785H6.14258C4.82422 6.29785 4.01562 7.02734 4.01562 8.40723V18.7695H2.16113C1.5459 18.7695 1.03613 19.2793 1.03613 19.9033C1.03613 20.5186 1.5459 21.0283 2.16113 21.0283ZM5.76465 8.87305C5.76465 8.31055 6.03711 8.04688 6.59082 8.04688H21.4092C21.9541 8.04688 22.2354 8.31055 22.2354 8.87305V18.7695H5.76465V8.87305Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default LaptopIcon;
