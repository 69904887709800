import { WorkhopWrittenGreenLogo } from 'Components/icons';
import WhiteCard from 'Components/white-card';
import { useWindowDimensions } from 'Hooks/use-window-dimensions';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'Style/theme';
import './index.less';

const PrivacyPolicy: React.FunctionComponent = () => {
    const { windowWidth } = useWindowDimensions();
    const { t } = useTranslation();
    return (
        <div className="PrivacyPolicy">
            <div className="content">
                <div className="banner">
                    <WorkhopWrittenGreenLogo
                        fill={theme['main-primary-color']}
                        width={190}
                        height={43}
                    />
                </div>

                <WhiteCard
                    paddingTop={40}
                    paddingBottom={40}
                    padding={windowWidth < 1080 ? 20 : 40}
                >
                    <div className="title-h2-bold">{t('privacy_policy')}</div>
                    <div className="p-medium">{t('PP.pp_p1')}</div>
                    <div className="p-medium">{t('PP.pp_p2')}</div>
                    <div className="p-medium">{t('PP.pp_p3')}</div>

                    <div className="title-h4-bold">{t('PP.pp_h1')}</div>
                    <div className="p-medium">{t('PP.pp_p4')}</div>

                    <div className="title-h4-bold">{t('PP.pp_h2')}</div>
                    <div className="p-medium">{t('PP.pp_p5')}</div>

                    <div className="title-h4-bold">{t('PP.pp_h3')}</div>
                    <div className="p-medium">{t('PP.pp_p6')}</div>
                    <div className="p-medium">{t('PP.pp_p7')}</div>
                    <div className="p-medium">{t('PP.pp_p8')}</div>

                    <div className="title-h4-bold">{t('PP.pp_h4')}</div>
                    <div className="p-medium">{t('PP.pp_p9')}</div>

                    <div className="title-h4-bold">{t('PP.pp_h5')}</div>
                    <div className="p-medium">{t('PP.pp_p10')}</div>

                    <div className="title-h4-bold">{t('PP.pp_h6')}</div>
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('PP.pp_p11') }}
                    />
                    <div className="p-medium">{t('PP.pp_p12')}</div>
                    <div className="p-medium">{t('PP.pp_p13')}</div>
                    <div className="p-medium">{t('PP.pp_p14')}</div>
                    <div className="p-medium">{t('PP.pp_p15')}</div>
                    <div className="p-medium">{t('PP.pp_p16')}</div>

                    <div className="title-h4-bold">{t('PP.pp_h7')}</div>
                    <div className="p-medium">{t('PP.pp_p17')}</div>
                    <div className="p-medium">{t('PP.pp_p18')}</div>
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('PP.pp_p19') }}
                    />
                    <div className="p-medium">{t('PP.pp_p20')}</div>
                    <div className="p-medium">{t('PP.pp_p21')}</div>

                    <div className="title-h4-bold">{t('PP.pp_h8')}</div>
                    <div className="p-medium">{t('PP.pp_p22')}</div>

                    <div className="title-h4-bold">{t('PP.pp_h9')}</div>
                    <div className="p-medium">{t('PP.pp_p23')}</div>

                    <div className="title-h4-bold">{t('PP.pp_h10')}</div>
                    <div className="p-medium">{t('PP.pp_p24')}</div>

                    <div className="title-h4-bold">{t('PP.pp_h11')}</div>
                    <div className="p-medium">{t('PP.pp_p25')}</div>
                    <div className="p-medium">{t('PP.pp_p26')}</div>

                    <div className="title-h4-bold">{t('PP.pp_h12')}</div>
                    <div className="p-medium">{t('PP.pp_p27')}</div>

                    <div className="title-h4-bold">{t('PP.pp_h13')}</div>
                    <div className="p-medium">{t('PP.pp_p28')}</div>
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('PP.pp_p29') }}
                    />
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('PP.pp_p30') }}
                    />
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('PP.pp_p31') }}
                    />
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('PP.pp_p32') }}
                    />
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('PP.pp_p33') }}
                    />
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('PP.pp_p34') }}
                    />

                    <div className="title-h4-bold">{t('PP.pp_h14')}</div>
                    <div className="p-medium">{t('PP.pp_p35')}</div>
                    <div
                        className="p-medium"
                        dangerouslySetInnerHTML={{ __html: t('PP.pp_p36') }}
                    />
                </WhiteCard>

                <div className="margin-enabler" />

                <div className="footer p-medium ">
                    {moment().format('YYYY')} ©workhop all rights reserved.
                </div>

                <div className="curve">
                    <svg
                        width="100%"
                        height="30%"
                        viewBox={`0 0 1920 ${
                            windowWidth < 700
                                ? windowWidth + 100
                                : windowWidth < 1470
                                ? '500'
                                : '290'
                        }`}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <ellipse cx="982" cy="518" rx="1234" ry="518" fill="#234847" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
