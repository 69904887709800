import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const WorkhopWrittenGreenLogo: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="600"
                height="128"
                viewBox="0 0 600 128"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M210.547 102.492L190 52.0045H205.719L217.739 84.3943L229.759 52.0045H240.033L252.053 84.8056L264.073 52.0045H278.97L258.628 102.492H245.478L234.382 73.8034L223.595 102.492H210.547Z"
                    fill={'#1E1E1E'}
                />
                <path
                    d="M304.716 103.623C299.306 103.623 294.58 102.56 290.539 100.435C286.566 98.2414 283.484 95.1909 281.293 91.2836C279.101 87.3077 278.005 82.612 278.005 77.1966C278.005 71.8497 279.101 67.2226 281.293 63.3153C283.484 59.3394 286.566 56.2889 290.539 54.1638C294.58 52.0388 299.306 50.9763 304.716 50.9763C310.127 50.9763 314.819 52.0388 318.791 54.1638C322.832 56.2889 325.949 59.3394 328.14 63.3153C330.4 67.2226 331.531 71.8497 331.531 77.1966C331.531 82.612 330.4 87.3077 328.14 91.2836C325.949 95.1909 322.832 98.2414 318.791 100.435C314.819 102.56 310.127 103.623 304.716 103.623ZM304.716 92.0034C308.004 92.0034 310.675 90.8038 312.73 88.4045C314.853 86.0053 315.915 82.2693 315.915 77.1966C315.915 72.1925 314.853 68.525 312.73 66.1943C310.675 63.7951 308.004 62.5955 304.716 62.5955C301.429 62.5955 298.758 63.7951 296.703 66.1943C294.648 68.525 293.621 72.1925 293.621 77.1966C293.621 82.2693 294.648 86.0053 296.703 88.4045C298.758 90.8038 301.429 92.0034 304.716 92.0034Z"
                    fill={'#1E1E1E'}
                />
                <path
                    d="M337.887 102.492V52.0045H353.092V60.7446C355.489 54.8493 360.626 51.5932 368.502 50.9763L373.022 50.6678L373.947 63.7266L365.214 64.652C357.544 65.406 353.708 69.3134 353.708 76.374V102.492H337.887Z"
                    fill={'#1E1E1E'}
                />
                <path
                    d="M377.143 102.492V30H392.656V72.878H392.862L411.457 52.0045H429.847L408.478 76.1684L430.874 102.492H412.176L392.862 80.6926H392.656V102.492H377.143Z"
                    fill={'#1E1E1E'}
                />
                <path
                    d="M431.349 102.492V30H446.862V58.8938C448.643 56.2889 450.937 54.3352 453.745 53.0328C456.553 51.6618 459.67 50.9763 463.094 50.9763C474.943 50.9763 480.868 58.0369 480.868 72.1582V102.492H465.354V72.878C465.354 69.3819 464.704 66.8798 463.402 65.3717C462.101 63.7951 460.183 63.0068 457.649 63.0068C454.362 63.0068 451.725 64.035 449.738 66.0915C447.821 68.148 446.862 70.89 446.862 74.3175V102.492H431.349Z"
                    fill={'#1E1E1E'}
                />
                <path
                    d="M513.612 103.623C508.202 103.623 503.476 102.56 499.435 100.435C495.462 98.2414 492.38 95.1909 490.188 91.2836C487.997 87.3077 486.901 82.612 486.901 77.1966C486.901 71.8497 487.997 67.2226 490.188 63.3153C492.38 59.3394 495.462 56.2889 499.435 54.1638C503.476 52.0388 508.202 50.9763 513.612 50.9763C519.023 50.9763 523.715 52.0388 527.687 54.1638C531.728 56.2889 534.845 59.3394 537.036 63.3153C539.296 67.2226 540.427 71.8497 540.427 77.1966C540.427 82.612 539.296 87.3077 537.036 91.2836C534.845 95.1909 531.728 98.2414 527.687 100.435C523.715 102.56 519.023 103.623 513.612 103.623ZM513.612 92.0034C516.9 92.0034 519.571 90.8038 521.626 88.4045C523.749 86.0053 524.811 82.2693 524.811 77.1966C524.811 72.1925 523.749 68.525 521.626 66.1943C519.571 63.7951 516.9 62.5955 513.612 62.5955C510.325 62.5955 507.654 63.7951 505.599 66.1943C503.544 68.525 502.517 72.1925 502.517 77.1966C502.517 82.2693 503.544 86.0053 505.599 88.4045C507.654 90.8038 510.325 92.0034 513.612 92.0034Z"
                    fill={'#1E1E1E'}
                />
                <path
                    d="M546.783 121V52.0045H561.988V59.5107C563.357 56.9058 565.446 54.8493 568.254 53.3412C571.131 51.7646 574.35 50.9763 577.912 50.9763C582.295 50.9763 586.131 52.0388 589.418 54.1638C592.774 56.2889 595.377 59.3051 597.226 63.2124C599.075 67.1198 600 71.7812 600 77.1966C600 82.612 599.075 87.3077 597.226 91.2836C595.377 95.1909 592.774 98.2414 589.418 100.435C586.131 102.56 582.295 103.623 577.912 103.623C574.556 103.623 571.474 102.903 568.665 101.463C565.926 100.024 563.803 98.1043 562.296 95.7051V121H546.783ZM573.289 92.0034C576.576 92.0034 579.247 90.8038 581.302 88.4045C583.357 86.0053 584.384 82.2693 584.384 77.1966C584.384 72.1925 583.357 68.525 581.302 66.1943C579.247 63.7951 576.576 62.5955 573.289 62.5955C569.932 62.5955 567.227 63.7951 565.172 66.1943C563.118 68.525 562.09 72.1925 562.09 77.1966C562.09 82.2693 563.118 86.0053 565.172 88.4045C567.227 90.8038 569.932 92.0034 573.289 92.0034Z"
                    fill={'#1E1E1E'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.986482 18.7673C-1.82483 11.7585 1.57784 3.7978 8.58658 0.986482C15.5953 -1.82483 23.556 1.57784 26.3673 8.58657L53.939 77.3239L76.9527 19.9499C79.764 12.9411 87.7247 9.53846 94.7334 12.3498C94.9073 12.4195 95.079 12.4924 95.2484 12.5684C98.4298 13.9645 101.093 16.5461 102.501 19.9977L119.701 62.1762L134.703 25.3863C137.555 18.3938 145.561 14.972 152.585 17.7436C159.61 20.5151 162.993 28.4305 160.141 35.4229L132.546 103.093C132.406 103.436 132.254 103.771 132.09 104.096C130.677 107.098 128.156 109.588 124.808 110.909C117.783 113.681 109.777 110.259 106.926 103.266L89.8218 61.3229L66.7696 118.793L66.7562 118.826C65.4588 122.31 62.7537 125.255 59.0316 126.748C52.0229 129.559 44.0622 126.157 41.2508 119.148L0.986482 18.7673ZM120.487 127.784C133.086 127.784 143.3 125.927 143.3 123.636C143.3 121.345 133.086 119.488 120.487 119.488C107.887 119.488 97.6738 121.345 97.6738 123.636C97.6738 125.927 107.887 127.784 120.487 127.784Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default WorkhopWrittenGreenLogo;
