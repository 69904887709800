import { Checkbox } from 'antd';
import { ChevronDownIcon } from 'Components/icons';
import {
    MultiSelectCommonStyles,
    MultiSelectCustomOption,
} from 'Components/select-custom/multi-select/multi-select-common';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { theme } from 'Style/theme';

interface AmenityDropdownProp {
    className?: string;
    options?: MultiSelectCustomOption[];
    onChange?: (value: MultiSelectCustomOption[]) => void | undefined;
    placeholder?: string | null;
    strongLabel?: boolean;
    closeMenuOnSelect?: boolean;
    hideSelectedOptions?: boolean;
    selected?: string[] | null;
    disabled?: boolean;
    borderless?: boolean;
    round?: boolean;
    dropdownIndicatorIcon?: ReactNode;
    formId?: string;
}

const StaticTaglessMultiSelect: FunctionComponent<AmenityDropdownProp> = ({
    className,
    options,
    onChange,
    placeholder,
    closeMenuOnSelect = true,
    hideSelectedOptions = false,
    selected,
    disabled,
    borderless,
    round,
    dropdownIndicatorIcon,
    formId,
}) => {
    const [value, setValue] = useState<MultiSelectCustomOption[]>([]);

    useEffect(() => {
        if (options === undefined) return;
        if (selected) {
            setValue(
                options?.filter((x) => {
                    return selected?.includes(x.value);
                })
            );
        } else {
            setValue([]);
        }
    }, [selected, options]);

    const handleOnChange = (data: any): void => {
        if (onChange) {
            onChange(data ?? []);
        }
    };

    /* -- The open dropdown menu -- */
    const menuOptionDetails = (data: MultiSelectCustomOption, selectProps: any) => {
        let labelColor = 'black';
        let contentColor = theme['text-mid-contrast'];

        if (data?.isDisabled) {
            labelColor = theme['text-disabled'];
            contentColor = theme['text-disabled'];
        }

        let optionIsSelected = false;
        if (selectProps.value.some((option) => option.value === data.value)) {
            optionIsSelected = true;
        }

        return (
            <div className="menuOptionDetails">
                <div className="selected-checkmark">
                    <Checkbox checked={optionIsSelected} value={optionIsSelected} />
                </div>

                <div className="center-content">
                    <div className="dropdown-menu-option-label">
                        {selectProps.strongLabel ? (
                            <strong>{data.label}</strong>
                        ) : (
                            <div style={{ color: labelColor }}>{data.label}</div>
                        )}
                    </div>
                    {data.content && (
                        <div
                            className={`p-small ${data.content.className ?? ''}`}
                            style={{ color: contentColor }}
                        >
                            {data.content.node}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const Option = (props: any) => {
        return (
            <div>
                <components.Option {...props} className="multi-dropdown-menu-option-container">
                    {menuOptionDetails(props.data, props.selectProps)}
                </components.Option>
            </div>
        );
    };
    /* -- The open dropdown menu -- */

    const Input = useCallback(({ ...props }: any) => {
        return <components.Input {...props} autoComplete="new-password" />;
    }, []);

    const DropdownIndicator = useCallback(
        (props): any => {
            return dropdownIndicatorIcon ? (
                <components.DropdownIndicator {...props}>
                    {dropdownIndicatorIcon}
                </components.DropdownIndicator>
            ) : (
                <components.DropdownIndicator {...props}>
                    <ChevronDownIcon width={20} height={20} />
                </components.DropdownIndicator>
            );
        },
        [dropdownIndicatorIcon]
    );

    // the input with selected options. Overrides the default tags that get put there.
    // Only display a string with number of selected options. Ignore the other options after the 1st passed in here
    const MultiValue = useCallback(
        ({ index }): any => {
            if (index > 0) return null;
            return <div>Selected ({selected?.length})</div>;
        },
        [selected]
    );

    return (
        <div className="AmenityDropdown">
            <Select
                components={{
                    Option,
                    MultiValue,
                    DropdownIndicator,
                    Input,
                }}
                theme={(defaultTheme) => ({
                    ...defaultTheme,
                    colors: {
                        ...defaultTheme.colors,
                        primary: 'transparent', //dictates the color of select option
                        primary25: theme['surfaces-disabled'], //dictates the color hovering options
                        primary50: theme['surfaces-disabled'], //dictates the on clicking option
                    },
                })}
                className={`MultiSelect StaticMultiSelect ${className || ''} ${
                    disabled ? 'disabled' : ''
                } ${borderless ? 'borderless' : ''}  ${round ? 'round' : ''}`}
                value={value}
                styles={MultiSelectCommonStyles}
                options={options}
                onChange={handleOnChange}
                closeMenuOnSelect={closeMenuOnSelect}
                hideSelectedOptions={hideSelectedOptions}
                placeholder={placeholder}
                isDisabled={disabled}
                noOptionsMessage={(): string => 'No options'}
                //permet au menu ouvert de sortir de l'epace d'un modal
                // menuPortalTarget={document.body}
                isMulti
                isClearable={false}
                id={formId}
            />
        </div>
    );
};

export default StaticTaglessMultiSelect;
