import React, { FunctionComponent, useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'Hooks';
import ConfirmationModal from 'Components/confirmation-modal';

const NavigationBlocker: FunctionComponent = observer(({ children }) => {
    const history = useHistory();
    const { navigationStore } = useStores();
    const [modalVisible, setModalVisible] = useState(false);
    const [nextLocation, setNextLocation] = useState<any | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const handleBlockedNavigation = (nextLocation: any): boolean => {
        if (!confirmedNavigation) {
            setModalVisible(true);
            setNextLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
        navigationStore.setNavigationBlockState(false);
    };

    useEffect(() => {
        if (confirmedNavigation && nextLocation) {
            history.push(nextLocation.pathname);
        }
    }, [confirmedNavigation, nextLocation]);

    return (
        <div>
            <Prompt when={navigationStore.navigationIsBlocked} message={handleBlockedNavigation} />

            <ConfirmationModal
                visible={modalVisible}
                title="Save changes?"
                message="Your unsaved changes will be lost."
                positive={{ text: 'Continue', action: handleConfirmNavigationClick }}
                negative={{
                    text: 'Cancel',
                    action: () => {
                        setModalVisible(false);
                    },
                }}
            />

            {children}
        </div>
    );
});

export default NavigationBlocker;
