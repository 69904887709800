import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AmenityKitchenIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.24 6.53413C9.59378 6.53413 9.06995 7.05795 9.06995 7.70413V20.8081C9.06995 21.4543 9.59378 21.9781 10.24 21.9781H19.6C20.2461 21.9781 20.77 21.4543 20.77 20.8081V7.70413C20.77 7.05795 20.2461 6.53413 19.6 6.53413H10.24ZM7.66595 7.70413C7.66595 6.28255 8.81837 5.13013 10.24 5.13013H19.6C21.0215 5.13013 22.174 6.28255 22.174 7.70413V20.8081C22.174 22.2297 21.0215 23.3821 19.6 23.3821H10.24C8.81837 23.3821 7.66595 22.2297 7.66595 20.8081V7.70413Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.112 7.93813C12.4997 7.93813 12.814 8.25242 12.814 8.64013V9.57613C12.814 9.96383 12.4997 10.2781 12.112 10.2781C11.7243 10.2781 11.41 9.96383 11.41 9.57613V8.64013C11.41 8.25242 11.7243 7.93813 12.112 7.93813ZM7.66595 12.3841C7.66595 11.9964 7.98025 11.6821 8.36795 11.6821H21.472C21.8597 11.6821 22.174 11.9964 22.174 12.3841C22.174 12.7718 21.8597 13.0861 21.472 13.0861H8.36795C7.98025 13.0861 7.66595 12.7718 7.66595 12.3841ZM12.112 14.4901C12.4997 14.4901 12.814 14.8044 12.814 15.1921V18.0001C12.814 18.3878 12.4997 18.7021 12.112 18.7021C11.7243 18.7021 11.41 18.3878 11.41 18.0001V15.1921C11.41 14.8044 11.7243 14.4901 12.112 14.4901Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AmenityKitchenIcon;
