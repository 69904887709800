import Button from 'Components/button';
import { CheckmarkIcon, WorkhopWrittenGreenLogo } from 'Components/icons';
import SquigglyLine from 'Components/squiggly-line';
import WhiteCard from 'Components/white-card';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { theme } from 'Style/theme';
import './confirm-account-callback.less';

/**
 * ConfirmAccountCallback
 * Azure AD will redirect here once the "confirm account" flow is finished
 */
const ConfirmAccountCallback: React.FunctionComponent = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const urlParams = new URLSearchParams(window.location.search);
    const [errorString, setErrorString] = useState<string | undefined>(undefined);

    useEffect(() => {
        const confirmAccountCallback = () => {
            // Check if success or error.
            const error = urlParams.get('error');
            const errorDescription = urlParams.get('error_description');

            if (error) {
                // Errors: invalid token, expired token, other unknown problem with Azure...

                if (errorDescription?.startsWith('E003007')) {
                    setErrorString('This user account is already confirmed.');
                    // Ex: "E003007: This user account is already confirmed.\r\nCorrelation ID: 37392ab1-04f4-4dcf-b5ad-d46606f22daa\r\nTimestamp: 2022-04-26 16:51:28Z\r\n"
                } else if (errorDescription?.startsWith('E003008')) {
                    setErrorString('The account confirmation link is invalid.');
                    // Ex: "E003008: The account confirmation link is invalid.\r\nCorrelation ID: 37392ab1-04f4-4dcf-b5ad-d46606f22daa\r\nTimestamp: 2022-04-26 16:51:28Z\r\n"
                } else if (errorDescription?.startsWith('E003009')) {
                    setErrorString('The account confirmation link is expired.');
                    // Ex: "E003009: The account confirmation link is expired.\r\nCorrelation ID: 37392ab1-04f4-4dcf-b5ad-d46606f22daa\r\nTimestamp: 2022-04-26 16:51:28Z\r\n"
                } else if (errorDescription?.startsWith('AADB2C90091')) {
                    setErrorString('The user has cancelled entering self-asserted information.');
                } else if (errorDescription?.startsWith('E003002')) {
                    setErrorString('Error');
                }

                // Other error, show error page?
            }
        };

        confirmAccountCallback();
    }, []);

    return (
        <div className="ConfirmAccountCallback">
            <WhiteCard className={`errorString-${errorString}`} padding={60}>
                <div className="content">
                    <WorkhopWrittenGreenLogo
                        fill={theme['main-primary-color']}
                        width={275}
                        height={61}
                    />
                    <SquigglyLine marginTop={40} marginbottom={40} />
                    {errorString ? (
                        <div className="p-medium text">{errorString}</div>
                    ) : (
                        <>
                            <div className="check">
                                <CheckmarkIcon
                                    width={61}
                                    height={61}
                                    fill={theme['element-main-secondary']}
                                />
                            </div>
                            <div className="title-h4-bold">
                                {t('ConfirmAccount.welcome_workhop')}
                            </div>
                            <div className="p-medium text">
                                {t('ConfirmAccount.account_created')}
                                <br /> {t('ConfirmAccount.log_in_to_start')}
                            </div>
                            <Button
                                text={'Sign In'}
                                type="primary"
                                onClick={() => history.push('/')}
                                sizeType="large"
                            />
                        </>
                    )}
                </div>
            </WhiteCard>

            <div className="curve">
                <svg
                    width="100%"
                    height="30%"
                    viewBox="0 0 1920 440"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <ellipse cx="982" cy="518" rx="1234" ry="518" fill="#234847" />
                </svg>
            </div>
        </div>
    );
};

export default ConfirmAccountCallback;
