import React from 'react';
import './index.less';

interface ContentProps {
    className?: string;
    /** Value from 0 to 12 based on the design 12 column grid. see antd.theme.less layout section */
    designGridColAmount?: number;
    children: any | any[];
}

const Content: React.FunctionComponent<ContentProps> = ({
    className,
    designGridColAmount = 10, //Base value should be 10 columns design says
    children,
}) => {
    return (
        <div
            className={`Content ${className} ${
                designGridColAmount !== undefined ? `column-${designGridColAmount}` : ''
            }`}
        >
            {children}
        </div>
    );
};

export default Content;
