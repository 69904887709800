import * as yup from 'yup';

export const EditProfileSchema = yup.object({
    firstName: yup.string().required('Errors.error_required'),
    lastName: yup.string().required('Errors.error_required'),
    companyName: yup.string().required('Errors.error_required'),
    contactInfo: yup.object({
        email: yup.string().required('Errors.error_required').email('Errors.error_invalid_email'),
    }),
});
