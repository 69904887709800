import React, { FC, FunctionComponent, ReactElement } from 'react';
import { SvgProps } from './SvgProps';
import * as Icons from 'Components/icons';

type IconProps = { iconName: string } & SvgProps;

type Ic = IconProps & SvgProps;

const Icon: FunctionComponent<Ic> = ({ iconName, ...rest }): any => {
    const getIconComponent = (iconName: string): ReactElement | undefined => {
        const IconName = iconName as keyof typeof Icons;
        const Icon: FC<SvgProps> = Icons[IconName];

        if (Icon) return <Icon {...rest} />;
        else return <Icons.CloseIcon />;
    };

    return getIconComponent(iconName);
};

export default Icon;
