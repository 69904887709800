import SquigglyLine from 'Components/squiggly-line';
import React, { FunctionComponent, ReactNode } from 'react';
import './index.less';

interface TitledWhiteCardCardProps {
    title: ReactNode;
    padding?: number;
    paddingTop?: number;
    paddingBottom?: number;
    paddingRight?: number;
    paddingLeft?: number;
    width?: number;
    className?: string;
    style?: React.CSSProperties;
}

const TitledWhiteCard: FunctionComponent<TitledWhiteCardCardProps> = ({
    title,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    width,
    className,
    children,
    style,
}) => {
    return (
        <div className={`TitledWhiteCard ${className ? className : ''}`}>
            <div
                style={{
                    paddingTop: paddingTop ?? padding,
                    paddingRight: paddingRight ?? padding,
                    paddingLeft: paddingLeft ?? padding,
                    width: width,
                    ...style,
                }}
            >
                <div className="p-medium-bold">{title}</div>
            </div>
            <SquigglyLine marginTop={17} marginbottom={20} />
            <div
                style={{
                    paddingBottom: paddingBottom ?? padding,
                    paddingRight: paddingRight ?? padding,
                    paddingLeft: paddingLeft ?? padding,
                    width: width,
                    ...style,
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default TitledWhiteCard;
