import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AmenityStorageIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.6217 5.69996C14.8069 5.59578 15.0331 5.59578 15.2183 5.69996L22.7063 9.91196C22.8979 10.0197 23.0164 10.2224 23.0164 10.4422V18.3982C23.0164 18.5986 22.9178 18.7861 22.7527 18.8996L15.2647 24.0476C15.0734 24.1791 14.824 24.1903 14.6217 24.0765L7.13373 19.8645C6.94216 19.7567 6.82361 19.554 6.82361 19.3342V10.4422C6.82361 10.2224 6.94216 10.0197 7.13373 9.91196L14.6217 5.69996ZM12.417 8.33623L18.664 11.8502L21.167 10.4422L14.92 6.92827L12.417 8.33623ZM21.7996 11.4825L15.5284 15.01V22.3896L21.7996 18.0782V11.4825ZM14.3116 22.506V15.01L8.04041 11.4825V18.9784L14.3116 22.506ZM8.67298 10.4422L14.92 13.9562L17.423 12.5482L11.176 9.03427L8.67298 10.4422Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AmenityStorageIcon;
