import { CHANGED_EMAIL_SUCCESS_QUERY, PROFILE_URL } from 'Models/Constants';
import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * ChangeEmailCallback
 * Azure AD will redirect here once the "change email" flow is finished
 */
const ChangeEmailCallback: FunctionComponent = () => {
    const history = useHistory();

    useEffect(() => {
        //we just need to redirect to profile from where the call originated. Add a query param to signal toast is required
        history.push(PROFILE_URL + CHANGED_EMAIL_SUCCESS_QUERY);
    }, []);

    return <div className="ChangeEmailCallback"></div>;
};

export default ChangeEmailCallback;
