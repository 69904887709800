import React, { FunctionComponent } from 'react';
import './index.less';

interface LayoutErrorsPageProps {
    className?: string;
}

const ErrorLayout: FunctionComponent<LayoutErrorsPageProps> = ({ children, className }) => {
    return (
        <div className={`ErrorLayout ${className}`}>
            <div className="header"></div>

            <div className="content">{children}</div>
        </div>
    );
};

export default ErrorLayout;
