import { observer } from 'mobx-react-lite';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import './index.less';

interface SkeletonProps {
    isLoading: boolean;
    placeholder: ReactElement;
    imgsSrc?: string[];
    children?: ReactElement | ReactElement[];
    animation?: boolean;
}

const Skeleton: React.FunctionComponent<SkeletonProps> = observer(
    ({ isLoading, placeholder, imgsSrc, children, animation = true }) => {
        const [imgs, setImgs] = useState<ReactNode[]>([]);
        const [imgsToLoad, setImgsToLoad] = useState<boolean[]>([]);
        const [imgsAreLoaded, setImgsAreLoaded] = useState<boolean[]>(imgsToLoad);
        const [isAllLoaded, setIsAllLoaded] = useState(false);

        const placeholderWithClassName = React.cloneElement(placeholder, {
            className: `Skeleton ${placeholder.props.className} ${animation ? 'animation' : ''}`,
        });

        const nonNullChildren = children ? (
            Array.isArray(children) ? (
                <>{children}</>
            ) : (
                children
            )
        ) : (
            <></>
        );

        useEffect(() => {
            const newImgsToLoad = imgsSrc && imgsSrc?.length > 0 ? imgsSrc.map(() => false) : [];
            newImgsToLoad.length > 0 && setImgsToLoad(newImgsToLoad);
        }, []);

        useEffect(() => {
            if (imgsToLoad.length === imgsSrc?.length) {
                const newImgs = imgsSrc
                    ? imgsSrc.map((src, i) =>
                          React.createElement('img', {
                              src: src.startsWith('http') ? src : window.location.origin + src,
                              key: `preloaded-img-${i}`,
                              onLoad: () => {
                                  setImgsAreLoaded((prev) => [...prev, (prev[i] = true)]);
                              },
                              onError: () => {
                                  setImgsAreLoaded((prev) => [...prev, (prev[i] = true)]);
                              },
                          })
                      )
                    : [];

                if (newImgs.length > 0) setImgs(newImgs);
            }
        }, [imgsToLoad]);

        useEffect(() => {
            setIsAllLoaded(!isLoading && !imgsAreLoaded.includes(false));
        }, [isLoading, imgsAreLoaded]);

        return isAllLoaded ? (
            nonNullChildren
        ) : (
            <>
                {placeholderWithClassName}
                <div>{imgs.map((img) => img)}</div>
            </>
        );
    }
);
export default Skeleton;
