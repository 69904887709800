import { GetVenueSalesReportRequestDto } from 'Api/Features/Reports/Dtos/GetVenueSalesReportRequestDto';
import { GetVenueSalesReportResponseDto } from 'Api/Features/Reports/Dtos/GetVenueSalesReportResponseDto';
import { GetVenueSalesReportResponseVenueDto } from 'Api/Features/Reports/Dtos/GetVenueSalesReportResponseVenueDto';
import { ReportProxy } from 'Api/Features/Reports/ReportProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

export interface VenueSalesReportResponse extends GetVenueSalesReportResponseDto {
    venues: GetVenueSalesReportResponseVenueDto[];
}
@inject(ReportProxy)
export class ReportService extends ApiService {
    constructor(private readonly reportProxy: ReportProxy) {
        super();
    }

    public async getVenueSalesReport(
        request: GetVenueSalesReportRequestDto | null
    ): Promise<VenueSalesReportResponse | null> {
        const data: GetVenueSalesReportResponseDto | null =
            await this.reportProxy.getVenueSalesReport(request);
        if (data) {
            const response = {
                ...data,
                venues: data?.venues?.filter((x) => x !== null).map((x) => x!) ?? [],
            };
            return response;
        }
        return null;
    }
}
