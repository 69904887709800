import { DROPDOWN_PAGE_SIZE } from 'Models/Constants';
import { useState, useEffect } from 'react';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { singleSelectMergeSelectedOptionsWithSearchResults } from 'Components/select-custom/select-custom-utils';
import { SelectFetchFunctionPromise } from 'Components/select-custom/single-select/async-single-select';

interface AsyncSingleSelectFetchProps {
    /**Async fetch function. Should be service function. EX: "async (request: GetOfficesRequestDto) => await officesService.getOffices(request)" */
    fetchFunction: (request: any) => Promise<[unknown[], number]>;
    /**Extra parameters for the request object passed in fetchFunction.  EX: GetOfficesRequestDto - workplacesForUserId, pageSize, sortColumn... */
    fetchFunctionExtraParams?: any;
}

interface extraMandatoryOptions {
    /**Puts extra options on top of list */
    unshift?: SingleSelectCustomOption[];
    /**Puts extra options on bottom of list */
    push?: SingleSelectCustomOption[];
}

interface useAsyncSingleSelectPropsReturn {
    /**Props that are normally spread to the select component. These props are still available to use outside of the select component. Ex. using asyncSingleSelectProps.selected in a submit function*/
    asyncSingleSelectProps: {
        /**AsyncSingleSelect.fetchFunction. Prop seulement pour le component  */
        fetchFunction: (page: number, searchTerm: string) => Promise<SelectFetchFunctionPromise>;

        /**AsyncSingleSelect.fetchFunction. Prop seulement pour le component */
        fetchFunctionExtraParams?: any;

        /**Normally only used inside the select component */
        resetSearchResults: () => void;

        /**Current options for the select component */
        options: SingleSelectCustomOption[];

        /**Currently selected option value. Usually an ID */
        selected: string | undefined;

        /**Currently selected option. Contains all of the option fields */
        selectedOption: SingleSelectCustomOption | undefined;

        /**Normally only used inside the select component. Could be used in specific case where a side effect should trigger when dropdown selection changes? */
        onChange: (value?: SingleSelectCustomOption) => void;
    };
}

/**Custom hook for AsyncSingleSelect. Returns the props to be spread to component or used in code */
export function useAsyncSingleSelectProps(props: {
    /**fetchProps: {fetchFunction, fetchRequestExtraParams?} */
    fetchProps: AsyncSingleSelectFetchProps;

    /**entityToSingleSelectCustomOption: Parsing function from entityDTO to SingleSelectCustomOption */
    entityToSingleSelectCustomOption: (entity: any) => SingleSelectCustomOption;

    /**extraMandatoryOptions?: { shift: SingleSelectCustomOption[], unshift: SingleSelectCustomOption[] }. If extra options need to be added ex: (all) */
    extraMandatoryOptions?: extraMandatoryOptions;

    /**defaultSelectedOption?: Specific option selected by default even if not currently in search result options. Ex: edit modal select value */
    defaultSelectedOption?: SingleSelectCustomOption;
}): useAsyncSingleSelectPropsReturn {
    const [options, setOptions] = useState<SingleSelectCustomOption[]>([]);
    const [selectedId, setSelectedId] = useState<string | undefined>(
        props.defaultSelectedOption?.value
    );
    const [selectedOption, setSelectedOption] = useState<SingleSelectCustomOption | undefined>(
        props.defaultSelectedOption
    );
    const [fetchSearchResult, setFetchSearchResult] = useState<unknown[]>([]);

    const fetchFunction = async (
        page: number,
        searchTerm: string,
        extraParams?: any
    ): Promise<SelectFetchFunctionPromise> => {
        const request = {
            ...extraParams,
            page,
            searchTerm,
            pageSize: extraParams?.pageSize ?? DROPDOWN_PAGE_SIZE,
        };
        const [results, totalItemCount] = await props.fetchProps.fetchFunction(request);
        setFetchSearchResult((prev) => [...prev, ...results]);

        return {
            maxResultHit:
                results.length + extraParams?.pageSize ??
                DROPDOWN_PAGE_SIZE * page >= totalItemCount,
        };
    };

    const resetSearchResults = () => setFetchSearchResult([]);

    const onChange = (value?: SingleSelectCustomOption) => {
        setSelectedId(value?.value);
        setSelectedOption(value);
    };

    useEffect(() => {
        const searchResults = fetchSearchResult?.map(props.entityToSingleSelectCustomOption);

        if (props.extraMandatoryOptions) {
            if (props.extraMandatoryOptions.unshift) {
                props.extraMandatoryOptions.unshift.forEach((el) => {
                    searchResults.unshift(el);
                });
            }

            if (props.extraMandatoryOptions.push) {
                props.extraMandatoryOptions.push.forEach((el) => {
                    searchResults.push(el);
                });
            }
        }

        const merged = singleSelectMergeSelectedOptionsWithSearchResults(searchResults, [
            selectedOption,
        ]);

        setOptions(merged);
    }, [fetchSearchResult, selectedId]);

    return {
        asyncSingleSelectProps: {
            fetchFunction,
            fetchFunctionExtraParams: props.fetchProps.fetchFunctionExtraParams,
            resetSearchResults,
            options,
            selected: selectedId,
            selectedOption,
            onChange,
        },
    };
}
