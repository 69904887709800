import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Content from 'Components/content';
import { DEBOUNCE_DELAY_400, TABLE_VIEW_GRID_COLUMNS } from 'Models/Constants';
import './index.less';
import WhiteCard from 'Components/white-card';
import { DollarSignIcon } from 'Components/icons';
import Price from 'Components/price';
import { theme } from 'Style/theme';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { useService, useStores } from 'Hooks';
import { ReportService, VenueSalesReportResponse } from 'Services/ReportService';
import { GetVenueSalesReportRequestDto } from 'Api/Features/Reports/Dtos/GetVenueSalesReportRequestDto';
import TableFilters from 'Components/table-filters/table-filters';
import { SortDirection } from 'Api/Features/General/Dtos/SortDirection';
import { FilterStore } from 'Stores';
import { GetVenueSalesReportSortColumnDto } from 'Api/Features/Reports/Dtos/GetVenueSalesReportSortColumnDto';
import { GetVenueSalesReportResponseVenueDto } from 'Api/Features/Reports/Dtos/GetVenueSalesReportResponseVenueDto';
import { ColumnType } from 'antd/lib/table';
import debounce from 'lodash.debounce';
import { autorun } from 'mobx';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';

const Sales: React.FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { toastStore, userStore } = useStores();
    const filterStoreRef = useRef(new FilterStore({ advancedFilters: [] }));
    const reportService = useService(ReportService);
    const [salesData, setSalesData] = useState<VenueSalesReportResponse | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const fetchSales = async (params: {
        searchTerm?: string;
        sortColumn: any | null;
        sortDirection: SortDirection | null;
        operatorIds: string[] | null;
        month: string | undefined;
    }) => {
        try {
            setLoading(true);
            const request: GetVenueSalesReportRequestDto = {
                searchTerm: params.searchTerm,
                sortColumn: params.sortColumn,
                sortDirection: params.sortDirection,
                operatorIds:
                    userStore.userRole === ManagementRoleDto.Operator
                        ? [userStore.userInfo?.id ?? '']
                        : params.operatorIds,
                month: params.month,
            };
            const response = await reportService.getVenueSalesReport(request);
            if (response) setSalesData(response);
        } catch (e: any) {
            if (!e.treated) toastStore.genericError();
        } finally {
            setLoading(false);
        }
    };

    const columns: ColumnType<GetVenueSalesReportResponseVenueDto>[] = [
        {
            title: t('venue'),
            render: (venue: GetVenueSalesReportResponseVenueDto) => <div>{venue.name}</div>,
            key: GetVenueSalesReportSortColumnDto.VenueName,
            defaultSortOrder: 'ascend',
            sorter: true,
            className: 'col-border-right',
        },
        {
            title: t('operator'),
            render: (venue: GetVenueSalesReportResponseVenueDto) => (
                <div>{venue.operator?.companyName}</div>
            ),
            key: GetVenueSalesReportSortColumnDto.OperatorCompanyName,
            sorter: true,
            className: 'col-border-right',
        },
        {
            title: t('month'),
            render: (venue: GetVenueSalesReportResponseVenueDto) => (
                <div className="price-col">
                    <Price price={venue.monthTotal} />
                </div>
            ),
            key: GetVenueSalesReportSortColumnDto.MonthTotal,
            sorter: true,
            className: 'col-border-right',
            width: 272,
        },
        {
            title: t('year'),
            render: (venue: GetVenueSalesReportResponseVenueDto) => (
                <div className="price-col">
                    <Price price={venue.yearTotal} />
                </div>
            ),
            key: GetVenueSalesReportSortColumnDto.YearTotal,
            sorter: true,
            width: 272,
        },
    ];

    const debounceSearch = useRef(
        debounce((params: { searchTerm?: string; operatorIds: string[] | null; month: string }) => {
            fetchSales({
                searchTerm: params.searchTerm,
                sortColumn: null,
                sortDirection: null,
                month: params.month,
                operatorIds: params.operatorIds,
            });
        }, DEBOUNCE_DELAY_400)
    );

    useEffect(() => {
        const disposer = autorun(() => {
            const filterStore = filterStoreRef.current;
            debounceSearch.current({
                searchTerm: filterStore.searchTerm,
                month: filterStore.month ?? '',
                operatorIds: filterStore.operatorId ? [filterStore.operatorId] : null,
            });
        });

        return (): void => {
            disposer();
        };
    }, [debounceSearch]);

    const handleTableChange = async (pagination, filter: any, sorter: any): Promise<void> => {
        let sortDirection: SortDirection | null;
        switch (sorter.order) {
            case 'ascend':
                sortDirection = SortDirection.Ascending;
                break;
            case 'descend':
                sortDirection = SortDirection.Descending;
                break;
            default:
                sortDirection = null;
                break;
        }
        const { searchTerm, operatorId, month } = filterStoreRef.current;
        await fetchSales({
            searchTerm,
            sortColumn: sorter.columnKey,
            sortDirection: sortDirection,
            month,
            operatorIds: operatorId ? [operatorId] : null,
        });
    };

    return (
        <Content className="Sales" designGridColAmount={TABLE_VIEW_GRID_COLUMNS}>
            <TableFilters
                pageTitle={t('Sales')}
                filterStore={filterStoreRef.current}
                includeSearch
                includeOperators={userStore.userRole === ManagementRoleDto.Administrator}
                includeMonth
            />
            <Table
                className="clickable"
                columns={columns}
                dataSource={salesData?.venues}
                onChange={handleTableChange}
                rowKey={(venue: GetVenueSalesReportResponseVenueDto): string => venue.id ?? ''}
                loading={loading}
                onRow={() => ({})}
            />

            <WhiteCard padding={10} className="mt-10 Shadow-50">
                <div className="total-container">
                    <div className="d-flex-align title-container">
                        <DollarSignIcon width={24} fill={theme['main-primary-color']} />
                        <div className="p-large-bold">Total Revenue</div>
                    </div>
                    <div className="amounts">
                        <div>
                            <Price
                                price={salesData?.monthTotal}
                                currencySize="medium"
                                priceSize="large"
                                currencyBold={false}
                            />
                        </div>
                        <div>
                            <Price
                                price={salesData?.yearTotal}
                                currencySize="medium"
                                priceSize="large"
                                currencyBold={false}
                            />
                        </div>
                    </div>
                </div>
            </WhiteCard>
        </Content>
    );
});

export default Sales;
