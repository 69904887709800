import { Gutter } from 'antd/lib/grid/row';

export const DEBOUNCE_DELAY_400 = 400;
export const DROPDOWN_PAGE_SIZE = 25;
export const CREATING_ID_URL = 'create';
export const TABLE_VIEW_GRID_COLUMNS = 10;
export const DETAILS_VIEW_GRID_COLUMNS = 8;
export const FORM_GUTTER: [Gutter, Gutter] = [20, 0];
export const MINIMUM_TIME_PERIOD_MINUTES = 30;
export const SIDENAV_WIDTH_OPEN = 312;
export const SIDENAV_WIDTH_COLLAPSED = 50;
export const SIDENAV_AUTO_COLLAPSE_WIDTH_THRESHOLD = 1560;
export const CHANGED_EMAIL_SUCCESS_QUERY = '?emailChanged';

//TIME FORMATS
export const MOMENT_DEFAULT = 'YYYY-MM-DDTHH:mm:SS';
export const TWELVE_HOUR_AM_PM = 'h a';
export const TWELVE_HOUR_AM_PM_NO_SPACE = 'ha';
export const TWELVE_HOUR_MINUTE_AM_PM = 'h:mm a';
export const TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE = 'h:mma';
export const TWELVE_HOUR_MINUTE_AM_PM_NO_SPACE_CAPS = 'h:mmA';
export const TWELVE_HOUR_MINUTE_AM_PM_CAPS = 'h:mm A';
export const TWENTY_FOUR_HOUR_MINUTE = 'HH:mm';
export const TWENTY_FOUR_HOUR_MINUTE_SECOND = 'HH:mm:ss';
export const DAY_WRITTEN = 'dddd';
export const MONTH_WRITTEN_DATE_YEAR = 'MMMM DD YYYY';
export const MONTH_WRITTEN_DATE = 'MMMM DD';
export const DAY_WRITTEN_ABBR = 'ddd';
export const YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const DAY_DASH_MONTH_DATE_YEAR = 'dddd - MMM DD YYYY';
export const SHORT_MONTH_DATE = 'MMM DD';
export const SHORT_MONTH_DATE_YEAR = 'MMM DD YYYY';
export const MONTH_DATE_COMMA_YEAR = 'MMM DD, YYYY';
export const MONTH_DAY = 'MMMM DD';
export const DAY_COMMA_YEAR = 'DD, YYYY';
export const MONTH_DAY_COMMA_YEAR = 'MMMM DD, YYYY';
export const MONTH = 'MMMM';
export const DAY = 'DD';
export const SHORT_MONTH = 'MMM';
export const MONTH_YEAR = 'MMMM YYYY';
export const SHORT_MONTH_DATE_TIME = 'MMM D, h:mma';

//URLS
export const VENUES_URL_REGEX = /^\/venues\/?.*/;
export const BOOKNIGS_URL_REGEX = /^\/bookings\/?.*/;
export const MANAGERS_URL_REGEX = /^\/managers\/?.*/;
export const CONSUMERS_URL_REGEX = /^\/consumers\/?.*/;
export const ABOUT_US_URL_REGEX = /^\/about-us\/?.*/;

export const ROOT_URL_TEMP = '/venues';
export const PROFILE_URL = '/profile';
export const VENUES_URL = '/venues';
export const BOOKINGS_URL = '/bookings';
export const MANAGERS_URL = '/managers';
export const CONSUMERS_URL = '/consumers';
export const SALES_URL = '/sales';
export const ABOUT_US_URL = '/about-us';

// KEY LOCAL STORAGE
export const KEY_LOCAL_STORAGE_SIDE_NAVIGATION_COLLAPSED = 'side-nav-collapsed';
