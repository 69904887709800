import React, { ReactNode, useEffect, useState } from 'react';
import { Popover as AntdPopover } from 'antd';
import { observer } from 'mobx-react-lite';
import './index.less';
import { useHistory } from 'react-router-dom';

interface PopoverProps {
    triggerContent: ReactNode;
    openMenuContent: ReactNode;
}

const Popover: React.FunctionComponent<PopoverProps> = observer(
    ({ triggerContent, openMenuContent }) => {
        const history = useHistory();
        const [popOverVisible, setPopOverVisible] = useState(false);

        useEffect(() => {
            //close menu on route change
            setPopOverVisible(false);
        }, [history.location]);

        return (
            <AntdPopover
                content={openMenuContent}
                trigger="click"
                visible={popOverVisible}
                onVisibleChange={(visible: boolean) => setPopOverVisible(visible)}
                placement="bottomRight"
                overlayClassName="Popover"
            >
                {triggerContent}
            </AntdPopover>
        );
    }
);

export default Popover;
