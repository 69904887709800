import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AmenityCustomerIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.92 6.78217C10.6553 6.78217 7.19801 10.2394 7.19801 14.5042C7.19801 18.7689 10.6553 22.2262 14.92 22.2262C19.1848 22.2262 22.642 18.7689 22.642 14.5042C22.642 10.2394 19.1848 6.78217 14.92 6.78217ZM5.79401 14.5042C5.79401 9.46402 9.87986 5.37817 14.92 5.37817C19.9602 5.37817 24.046 9.46402 24.046 14.5042C24.046 19.5443 19.9602 23.6302 14.92 23.6302C9.87986 23.6302 5.79401 19.5443 5.79401 14.5042Z"
                    fill={fill}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.176 12.6322C11.176 12.1152 11.5951 11.6962 12.112 11.6962H12.1214C12.6383 11.6962 13.0574 12.1152 13.0574 12.6322C13.0574 13.1491 12.6383 13.5682 12.1214 13.5682H12.112C11.5951 13.5682 11.176 13.1491 11.176 12.6322ZM16.792 12.6322C16.792 12.1152 17.2111 11.6962 17.728 11.6962H17.7374C18.2543 11.6962 18.6734 12.1152 18.6734 12.6322C18.6734 13.1491 18.2543 13.5682 17.7374 13.5682H17.728C17.2111 13.5682 16.792 13.1491 16.792 12.6322ZM11.9249 16.6436C12.2942 16.2818 12.8868 16.2879 13.2486 16.6571C13.4665 16.8795 13.7265 17.0561 14.0135 17.1767C14.3005 17.2973 14.6087 17.3595 14.92 17.3595C15.2313 17.3595 15.5395 17.2973 15.8265 17.1767C16.1135 17.0561 16.3736 16.8795 16.5914 16.6571C16.9532 16.2879 17.5458 16.2818 17.9151 16.6436C18.2843 17.0054 18.2904 17.598 17.9286 17.9672C17.5364 18.3675 17.0683 18.6855 16.5517 18.9026C16.0351 19.1196 15.4804 19.2315 14.92 19.2315C14.3596 19.2315 13.8049 19.1196 13.2883 18.9026C12.7717 18.6855 12.3036 18.3675 11.9114 17.9672C11.5497 17.598 11.5557 17.0054 11.9249 16.6436Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AmenityCustomerIcon;
