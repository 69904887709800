import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PeopleIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M18.5967 13.5752C20.5215 13.5752 22.1475 11.8701 22.1475 9.66406C22.1475 7.51074 20.5127 5.8584 18.5967 5.8584C16.6895 5.8584 15.0459 7.53711 15.0547 9.68164C15.0547 11.8701 16.6719 13.5752 18.5967 13.5752ZM8.43652 13.7773C10.1152 13.7773 11.5303 12.2832 11.5303 10.3672C11.5303 8.48633 10.0977 7.05371 8.43652 7.05371C6.77539 7.05371 5.34277 8.5127 5.35156 10.3848C5.35156 12.2832 6.7666 13.7773 8.43652 13.7773ZM3.59375 21.7578H10.1855C9.24512 20.4131 10.2559 17.7588 12.2422 16.1943C11.2754 15.5703 10.0186 15.1133 8.43652 15.1133C4.50781 15.1133 1.96777 18.0049 1.96777 20.3867C1.96777 21.2568 2.40723 21.7578 3.59375 21.7578ZM13.1211 21.749H24.0723C25.5225 21.749 26.0234 21.3096 26.0234 20.501C26.0234 18.2422 23.1494 15.1309 18.5967 15.1309C14.0352 15.1309 11.1699 18.2422 11.1699 20.501C11.1699 21.3096 11.6709 21.749 13.1211 21.749Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PeopleIcon;
