import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const PhoneIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M18.2627 22.4258C19.8008 22.4258 20.8467 21.9863 21.7959 20.958C21.8574 20.8877 21.9189 20.8086 21.9805 20.7383C22.499 20.1582 22.7539 19.5517 22.7539 18.9892C22.7539 18.3301 22.4023 17.7324 21.6201 17.1963L19.001 15.3681C18.1748 14.7969 17.2168 14.7529 16.4785 15.4912L15.8281 16.1416C15.5996 16.3701 15.3799 16.3877 15.1162 16.2031C14.6416 15.8867 13.7451 15.0957 13.1299 14.4804C12.5059 13.8564 11.873 13.1533 11.5303 12.6172C11.3457 12.3447 11.3633 12.1338 11.5918 11.9052L12.2422 11.2549C12.9805 10.5166 12.9365 9.55857 12.3564 8.7324L10.4932 6.06931C9.97461 5.31345 9.35059 4.96189 8.69141 4.9531C8.12891 4.94431 7.53125 5.19919 6.95117 5.70896C6.88086 5.77048 6.81055 5.83201 6.74023 5.89353C5.69434 6.85154 5.24609 7.87986 5.24609 9.47947C5.24609 12.0722 6.74902 15.1308 9.67578 18.04C12.585 20.9316 15.6084 22.4258 18.2627 22.4258ZM18.2627 20.958C16.0215 20.9931 13.2354 19.4023 10.792 16.9765C8.33105 14.5508 6.69629 11.6592 6.73145 9.41794C6.74902 8.47751 7.08301 7.65134 7.74219 7.07126C7.8125 7.01853 7.86523 6.97459 7.93555 6.92185C8.18164 6.71091 8.44531 6.59666 8.69141 6.59666C8.94629 6.59666 9.1748 6.70212 9.35059 6.9658L10.9941 9.43552C11.2051 9.73435 11.2139 10.0771 10.9062 10.376L10.2031 11.0703C9.53516 11.7207 9.59668 12.4853 10.0186 13.083C10.4932 13.7422 11.3809 14.7529 12.1279 15.4912C12.9277 16.2822 14.0176 17.2578 14.6504 17.7148C15.248 18.1367 16.0127 18.1982 16.6631 17.5302L17.3574 16.8271C17.6475 16.5195 17.9902 16.5283 18.2979 16.7304L20.7236 18.3476C20.9961 18.5234 21.0928 18.7431 21.0928 18.998C21.0928 19.2441 20.9785 19.5078 20.7676 19.7627C20.7148 19.8242 20.6709 19.8769 20.6182 19.9472C20.0381 20.6152 19.2119 20.9492 18.2627 20.958Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default PhoneIcon;
