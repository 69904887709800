import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AdminFilledIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
                <path
                    d="M16.3586 12.2964C18.3414 12.2964 20.0073 10.6842 20.0073 8.59834C20.0073 6.56233 18.3232 5 16.3586 5C14.3848 5 12.7008 6.58726 12.7008 8.61496C12.7098 10.6842 14.3758 12.2964 16.3586 12.2964ZM16.3495 13.7673C14.8737 13.7673 13.57 14.0748 12.4926 14.5485C13.7873 15.554 14.6202 17.0499 14.6202 18.7119C14.6202 19.1607 14.5568 19.6094 14.4301 20.0249H21.9991C23.484 20.0249 24 19.6094 24 18.8449C24 16.7091 21.0485 13.7673 16.3495 13.7673Z"
                    fill={fill}
                />
                <path
                    d="M8.23912 22.9999H9.0364C9.29781 22.9999 9.49386 22.856 9.5505 22.62L9.76834 21.7763C9.91647 21.7283 10.0559 21.6763 10.1866 21.6203L10.9926 22.0762C11.2061 22.2001 11.4631 22.1802 11.6417 22.0162L12.2038 21.5003C12.3824 21.3364 12.4085 21.0924 12.2648 20.8925L11.7724 20.1607C11.8334 20.0407 11.8901 19.9128 11.9336 19.7888L12.8616 19.5889C13.1187 19.5369 13.2712 19.3569 13.2712 19.117V18.3972C13.2712 18.1612 13.1187 17.9773 12.8616 17.9253L11.9424 17.7214C11.8944 17.5814 11.8334 17.4574 11.7812 17.3495L12.2735 16.6017C12.4129 16.3977 12.3955 16.1698 12.2081 16.0018L11.6417 15.486C11.4588 15.334 11.2278 15.306 11.0057 15.418L10.1866 15.8819C10.0602 15.8259 9.92082 15.7739 9.77269 15.7259L9.5505 14.8701C9.49386 14.6342 9.29781 14.4902 9.0364 14.4902H8.23912C7.97336 14.4902 7.7773 14.6342 7.72066 14.8701L7.50283 15.7219C7.3547 15.7659 7.21092 15.8179 7.08022 15.8779L6.26551 15.418C6.04332 15.306 5.81676 15.33 5.62942 15.486L5.06305 16.0018C4.87571 16.1698 4.85828 16.3977 4.9977 16.6017L5.49001 17.3495C5.43773 17.4574 5.38109 17.5814 5.32881 17.7214L4.40953 17.9253C4.15249 17.9773 4 18.1612 4 18.3972V19.117C4 19.3569 4.15249 19.5369 4.40953 19.5889L5.33752 19.7888C5.38109 19.9128 5.43773 20.0407 5.49872 20.1607L5.00641 20.8925C4.86264 21.0924 4.88878 21.3364 5.07176 21.5003L5.62942 22.0162C5.80805 22.1802 6.0651 22.2001 6.28294 22.0762L7.08458 21.6203C7.21528 21.6763 7.3547 21.7283 7.50283 21.7763L7.72066 22.62C7.7773 22.856 7.97336 22.9999 8.23912 22.9999ZM8.63558 20.1447C7.79909 20.1447 7.11508 19.5129 7.11508 18.7411C7.11508 17.9773 7.79909 17.3495 8.63558 17.3495C9.47643 17.3495 10.1604 17.9773 10.1604 18.7411C10.1604 19.5129 9.47643 20.1447 8.63558 20.1447Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AdminFilledIcon;
