import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const EyeIcon: FunctionComponent<SvgProps> = ({ fill = 'currentColor', onClick, ...props }) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M14.0088 21.2832C21.2246 21.2832 26.0762 15.4648 26.0762 13.6631C26.0762 11.8613 21.207 6.05176 14.0088 6.05176C6.89844 6.05176 1.92383 11.8613 1.92383 13.6631C1.92383 15.4648 6.88086 21.2832 14.0088 21.2832ZM14.0088 19.5605C8.40137 19.5605 3.96289 14.8936 3.96289 13.6631C3.96289 12.6348 8.40137 7.77441 14.0088 7.77441C19.5898 7.77441 24.0371 12.6348 24.0371 13.6631C24.0371 14.8936 19.5898 19.5605 14.0088 19.5605ZM14.0088 18.3828C16.6279 18.3828 18.7285 16.2295 18.7285 13.6631C18.7285 11.0352 16.6279 8.95215 14.0088 8.95215C11.3721 8.95215 9.2627 11.0352 9.27148 13.6631C9.28906 16.2295 11.3721 18.3828 14.0088 18.3828ZM14 15.1836C13.1562 15.1836 12.4707 14.498 12.4707 13.6631C12.4707 12.8281 13.1562 12.1426 14 12.1426C14.8438 12.1426 15.5293 12.8281 15.5293 13.6631C15.5293 14.498 14.8438 15.1836 14 15.1836Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default EyeIcon;
