import React, { FunctionComponent, useState } from 'react';
import { Modal } from 'antd';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './index.less';
import { ChevronLeftIcon, ChevronRightIcon, CloseIcon } from 'Components/icons';
import { theme } from 'Style/theme';

export interface CarouselImage {
    id: string;
    url: string;
}

export interface ImageCarouselModal {
    images: CarouselImage[];
    startingImgId: string;
    width?: number | null;
    onClose: () => void;
}

const ImageCarouselModal: FunctionComponent<ImageCarouselModal> = ({
    images,
    startingImgId,
    width,
    onClose,
}) => {
    const [imageIndex] = useState<number>(images.findIndex((img) => img.id === startingImgId));

    return (
        <Modal
            visible
            className="ImageCarouselModal"
            footer={null}
            centered
            width={width ? width : 1195}
            closeIcon={<CloseIcon fill={theme.white} />}
            onCancel={onClose}
        >
            <Carousel
                dynamicHeight={false}
                interval={0}
                showThumbs={false}
                selectedItem={imageIndex}
                showStatus={false}
                renderArrowNext={(onClickHandler, hasNext) =>
                    hasNext && (
                        <div className="control-arrow control-next" onClick={onClickHandler}>
                            <ChevronRightIcon />
                        </div>
                    )
                }
                renderArrowPrev={(onClickHandler, hasPrev) =>
                    hasPrev && (
                        <div className="control-arrow control-prev" onClick={onClickHandler}>
                            <ChevronLeftIcon />
                        </div>
                    )
                }
            >
                {images.map((image) => (
                    <div className="image-wrapper" key={image.id ?? ''}>
                        <img width={'auto'} height={'100%'} src={image.url ?? ''} alt="" />
                    </div>
                ))}
            </Carousel>
        </Modal>
    );
};

export default ImageCarouselModal;
