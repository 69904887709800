import { Button } from 'antd';
import ErrorLayout from 'Components/error-layout';
import { useService } from 'Hooks';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';

/**
 * SignInFailure
 * In the event of getting token failure
 */
const SignInFailure: FunctionComponent = () => {
    const { t } = useTranslation();
    const azureAdAuthenticationService = useService(AzureAdAuthenticationService);
    //TODO this page needs styling
    return (
        <ErrorLayout>
            <div className="text-titleXL">{t('Errors.token_error_title')}</div>
            <div className="text-callout">{t('Errors.token_error_subtitle')}</div>
            <Button
                type="default"
                className="primary"
                onClick={() => azureAdAuthenticationService.signOut()}
            >
                {'Click here to try again'}
            </Button>
        </ErrorLayout>
    );
};

export default SignInFailure;
