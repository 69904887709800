import GlobalLoadingContainer from 'Components/global-loading-container/global-loading-container';
import { ToastContainer } from 'Components/toast-container';
import { useService } from 'Hooks';
import React, { FunctionComponent, useLayoutEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'Routes';
import { AuthenticationService } from 'Services/AuthenticationService';
import './App.less';

const App: FunctionComponent = () => {
    const authenticationService = useService(AuthenticationService);
    useLayoutEffect(() => {
        authenticationService.installInterceptors();
    }, [authenticationService]);

    return (
        <BrowserRouter>
            <Routes />
            <ToastContainer />
            <GlobalLoadingContainer />
        </BrowserRouter>
    );
};

export default App;
