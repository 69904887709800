import React, {
    FunctionComponent,
    ReactNode,
    useEffect,
    useState,
    useCallback,
    useRef,
} from 'react';
import './multi-select-common.less';
import Select, { components, ValueContainerProps } from 'react-select';
import {
    MultiSelectCommonStyles,
    MultiSelectCustomOption,
    SELECT_EXTRA_HIDDEN_TAG,
    SELECT_TAG_CHAR_WIDTH,
    SELECT_TAG_LABEL_LEFT_PADDING,
    SELECT_TAG_REMOVE_ICON_PADDING,
    SELECT_TAG_REMOVE_ICON_WIDTH,
    SELECT_VALUE_CONTAINER_COLUMN_GAP,
    SELECT_VALUE_CONTAINER_X_PADDING,
} from './multi-select-common';
import { Checkbox } from 'antd';
import { theme } from 'Style/theme';
import { ChevronDownIcon, CloseIcon } from 'Components/icons';

export interface StaticMultiSelectProps {
    className?: string;
    options?: MultiSelectCustomOption[];
    onChange?: (value: MultiSelectCustomOption[]) => void | undefined;
    placeholder?: string | null;
    // defaultImg?: ReactNode;
    strongLabel?: boolean;
    closeMenuOnSelect?: boolean;
    hideSelectedOptions?: boolean;
    selected?: string[] | null;
    disabled?: boolean;
    borderless?: boolean;
    round?: boolean;
    dropdownIndicatorIcon?: ReactNode;
    formId?: string;
}

const StaticMultiSelect: FunctionComponent<StaticMultiSelectProps> = ({
    className,
    options,
    onChange,
    placeholder,
    // defaultImg,
    closeMenuOnSelect = true,
    hideSelectedOptions = false,
    selected,
    disabled,
    borderless,
    round,
    dropdownIndicatorIcon,
    formId,
}) => {
    const [value, setValue] = useState<MultiSelectCustomOption[]>([]);
    const selectRef = useRef<any>(null);

    useEffect(() => {
        if (options === undefined) return;
        if (selected) {
            setValue(
                options?.filter((x) => {
                    return selected?.includes(x.value);
                })
            );
        } else {
            setValue([]);
        }
    }, [selected, options]);

    const handleOnChange = (data: any): void => {
        if (onChange) {
            onChange(data ?? []);
        }
    };

    // -- 02/11/2022 as of now, never have an option tu put image --
    // const optionImage = (data: MultiSelectCustomOption, selectProps: any) => {
    //     return (
    //         <>
    //             {data.imageUrl ? (
    //                 <img
    //                     style={{ flex: '0 0 auto' }}
    //                     className="dropdown-option-img"
    //                     width="24px"
    //                     height="24px"
    //                     src={data.imageUrl}
    //                     alt=""
    //                 />
    //             ) : (
    //                 <span className="dropdown-option-img">{selectProps.defaultImg}</span>
    //             )}
    //         </>
    //     );
    // };

    /* -- The open dropdown menu -- */
    const menuOptionDetails = (data: MultiSelectCustomOption, selectProps: any) => {
        let labelColor = 'black';
        let contentColor = theme['text-mid-contrast'];

        if (data?.isDisabled) {
            labelColor = theme['text-disabled'];
            contentColor = theme['text-disabled'];
        }

        let optionIsSelected = false;
        if (selectProps.value.some((option) => option.value === data.value)) {
            optionIsSelected = true;
        }

        return (
            <div className="menuOptionDetails">
                <div className="selected-checkmark">
                    <Checkbox checked={optionIsSelected} value={optionIsSelected} />
                </div>

                <div className="center-content">
                    <div className="dropdown-menu-option-label">
                        {selectProps.strongLabel ? (
                            <strong>{data.label}</strong>
                        ) : (
                            <div style={{ color: labelColor }}>{data.label}</div>
                        )}
                    </div>
                    {data.content && (
                        <div
                            className={`p-small ${data.content.className ?? ''}`}
                            style={{ color: contentColor }}
                        >
                            {data.content.node}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const Option = (props: any) => {
        return (
            <div>
                <components.Option {...props} className="multi-dropdown-menu-option-container">
                    {/* {defaultImg && optionImage(props.data, props.selectProps)} */}

                    {menuOptionDetails(props.data, props.selectProps)}
                </components.Option>
            </div>
        );
    };
    /* -- The open dropdown menu -- */

    /* -- The selected values in dropdown -- */
    const selectedOptionDetails = (data: MultiSelectCustomOption) => {
        return (
            <div className="selected-option-details">
                <div className="caption-small element-dark-tertiary">{data.label}</div>
            </div>
        );
    };

    //This is not the input container, it is the rounded badge container
    const MultiValueContainer = ({ innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-multivalue-container';
        return <components.MultiValueContainer innerProps={innerProps} {...props} />;
    };

    const MultiValueLabel = ({ data, innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-selected-element';
        data.content = data.content ? data.content : null;
        return (
            <components.MultiValueLabel innerProps={innerProps} {...props}>
                {selectedOptionDetails(data)}
            </components.MultiValueLabel>
        );
    };

    //icone X dans les selected items pour retirer
    const MultiValueRemove = ({ innerProps, ...props }: any): any => {
        innerProps.className += ' dropdown-value-remove';
        return (
            <components.MultiValueRemove innerProps={innerProps} {...props}>
                <CloseIcon width={16} height={16} fill={theme['element-dark-tertiary']} />
            </components.MultiValueRemove>
        );
    };
    /* -- The selected values in dropdown -- */

    const Input = useCallback(({ ...props }: any) => {
        return <components.Input {...props} autoComplete="new-password" />;
    }, []);

    const DropdownIndicator = useCallback(
        (props): any => {
            return dropdownIndicatorIcon ? (
                <components.DropdownIndicator {...props}>
                    {dropdownIndicatorIcon}
                </components.DropdownIndicator>
            ) : (
                <components.DropdownIndicator {...props}>
                    <ChevronDownIcon width={20} height={20} />
                </components.DropdownIndicator>
            );
        },
        [dropdownIndicatorIcon]
    );

    const ValueContainer = ({
        children,
        ...props
    }: ValueContainerProps<MultiSelectCustomOption, true>) => {
        //Quelques exemples d'inspiration
        //https://codesandbox.io/s/react-select-custom-multi-value-messages-7o2gn?file=/src/MySelect2.tsx
        //https://codesandbox.io/s/react-select-longest-option-width-forked-bwk4uf?file=/src/App.js:149-158

        const [values, input] = children as any;
        //get available width for the tags container. This does not include space for cursor or dropdown icon
        const valueContainerWidth =
            selectRef.current?.select?.controlRef?.firstChild?.clientWidth -
            SELECT_VALUE_CONTAINER_X_PADDING * 2;

        //determine how many tags we can display
        let tagWidths = 0;
        const tagsToDisplay: any[] = [];

        for (let i = 0; i < values.length; i++) {
            const currentTagWidth =
                values[i]?.props.children.length * SELECT_TAG_CHAR_WIDTH +
                SELECT_TAG_LABEL_LEFT_PADDING +
                SELECT_TAG_REMOVE_ICON_WIDTH +
                SELECT_TAG_REMOVE_ICON_PADDING;
            if (
                tagWidths +
                    currentTagWidth +
                    tagsToDisplay.length * SELECT_VALUE_CONTAINER_COLUMN_GAP +
                    SELECT_EXTRA_HIDDEN_TAG +
                    SELECT_VALUE_CONTAINER_COLUMN_GAP <=
                valueContainerWidth
            ) {
                tagWidths += currentTagWidth;
                tagsToDisplay.push(values[i]);
            }
        }

        return (
            <components.ValueContainer {...props}>
                {/* If values is not an array, its the placeholder just render it. Otherwise its tags */}
                {Array.isArray(values) ? tagsToDisplay : values}

                {values && values.length > tagsToDisplay.length && (
                    <div className="additional-selected caption-medium">
                        {values.length - tagsToDisplay.length}+
                    </div>
                )}

                {input}
            </components.ValueContainer>
        );
    };

    return (
        <Select
            components={{
                Option,
                MultiValueContainer,
                MultiValueLabel,
                MultiValueRemove,
                DropdownIndicator,
                Input,
                ValueContainer,
            }}
            theme={(defaultTheme) => ({
                ...defaultTheme,
                colors: {
                    ...defaultTheme.colors,
                    primary: 'transparent', //dictates the color of select option
                    primary25: theme['surfaces-disabled'], //dictates the color hovering options
                    primary50: theme['surfaces-disabled'], //dictates the on clicking option
                },
            })}
            className={`MultiSelect StaticMultiSelect ${className || ''} ${
                disabled ? 'disabled' : ''
            } ${borderless ? 'borderless' : ''}  ${round ? 'round' : ''}`}
            value={value}
            styles={MultiSelectCommonStyles}
            options={options}
            onChange={handleOnChange}
            closeMenuOnSelect={closeMenuOnSelect}
            hideSelectedOptions={hideSelectedOptions}
            placeholder={placeholder}
            // defaultImg={defaultImg}
            isDisabled={disabled}
            noOptionsMessage={(): string => 'No options'}
            //permet au menu ouvert de sortir de l'epace d'un modal
            // menuPortalTarget={document.body}
            isMulti
            isClearable={false}
            id={formId}
            isSearchable
            ref={selectRef}
        />
    );
};

export default StaticMultiSelect;
