import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AmenityLoungeIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.1912 9.01609V11.1689H21.472C22.8613 11.1689 23.9992 12.3068 23.9992 13.6961V18.3761C23.9992 19.7654 22.8613 20.9033 21.472 20.9033H21.1912V22.7753H19.8808V20.9033H9.95916V22.7753H8.64876V20.9033H8.36796C6.97864 20.9033 5.84076 19.7654 5.84076 18.3761V13.6961C5.84076 12.3068 6.97864 11.1689 8.36796 11.1689H8.64876V9.01609C8.64876 7.62677 9.78664 6.48889 11.176 6.48889H18.664C20.0533 6.48889 21.1912 7.62677 21.1912 9.01609ZM19.788 11.8267L19.8808 11.7431V9.01609C19.8808 8.34621 19.3338 7.79929 18.664 7.79929H11.176C10.5061 7.79929 9.95916 8.34621 9.95916 9.01609V11.7431L10.0519 11.8267C10.5682 12.2922 10.8952 12.9576 10.8952 13.6961V15.8489H18.9448V13.6961C18.9448 12.9576 19.2717 12.2922 19.788 11.8267ZM21.472 19.5929C22.1418 19.5929 22.6888 19.046 22.6888 18.3761V13.6961C22.6888 13.0262 22.1418 12.4793 21.472 12.4793C20.8021 12.4793 20.2552 13.0262 20.2552 13.6961V17.1593H9.58476V13.6961C9.58476 13.0262 9.03784 12.4793 8.36796 12.4793C7.69808 12.4793 7.15116 13.0262 7.15116 13.6961V18.3761C7.15116 19.046 7.69808 19.5929 8.36796 19.5929H21.472Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AmenityLoungeIcon;
