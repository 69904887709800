import Button from 'Components/button';
import { WorkhopWrittenGreenLogo } from 'Components/icons';
import SquigglyLine from 'Components/squiggly-line';
import WhiteCard from 'Components/white-card';
import { useStores } from 'Hooks';
import { useWindowDimensions } from 'Hooks/use-window-dimensions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { theme } from 'Style/theme';
import './index.less';

const ForbiddenPage: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { windowWidth } = useWindowDimensions();
    const history = useHistory();
    const { authorizationStore } = useStores();

    return (
        <div className="ForbiddenPage">
            <div className="banner">
                <WorkhopWrittenGreenLogo
                    fill={theme['main-primary-color']}
                    width={190}
                    height={43}
                />
            </div>

            <WhiteCard paddingTop={133} paddingBottom={133} paddingLeft={70} paddingRight={70}>
                <div className="content">
                    <div className="title-display-bold main-primary">{t('Page403.403_title')}</div>
                    <div className="p-medium">{t('Page403.403_subtitle')}</div>
                    <div className="squiggle">
                        <SquigglyLine marginTop={40} marginbottom={40} />
                    </div>
                    <div className="btn">
                        <Button
                            text="Go back"
                            type="primary"
                            width="full"
                            sizeType="large"
                            onClick={() => {
                                authorizationStore.resetForbidden();
                                history.goBack();
                            }}
                        />
                    </div>
                </div>
            </WhiteCard>

            {/* <div className="margin-enabler" /> */}

            <div className="curve">
                <svg
                    width="100%"
                    height="30%"
                    viewBox={`0 0 1920 ${
                        windowWidth < 700 ? windowWidth + 100 : windowWidth < 1470 ? '500' : '290'
                    }`}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <ellipse cx="982" cy="518" rx="1234" ry="518" fill="#D5DFDF" />
                </svg>
            </div>
        </div>
    );
};

export default ForbiddenPage;
