import { useService } from 'Hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { AzureAdAuthenticationService } from 'Services/AzureAdAuthenticationService';

/**
 * ConfirmAccount
 * This page is normally accessed by clicking a confirmation link in an email sent to the user when we create their account.
 */
const ConfirmAccount: FunctionComponent = () => {
    const [errorString, setErrorString] = useState<string | undefined>(undefined);
    const azureAdAuthenticationService = useService(AzureAdAuthenticationService);
    const urlParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        const confirmAccount = async () => {
            // Get email and token from query string.
            const email = urlParams.get('email');
            const token = urlParams.get('token');

            if (!email || !token) {
                setErrorString('The account confirmation link is invalid.');
                return;
            }

            await azureAdAuthenticationService.runConfirmAccountFlow(email, token);
        };

        confirmAccount();
    }, []);

    return <div className="ConfirmAccount">{errorString && <div>{errorString}</div>}</div>;
};

export default ConfirmAccount;
