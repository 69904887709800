import { CloseIcon } from 'Components/icons';
import Icon from 'Components/icons/Icon';
import React, { FunctionComponent } from 'react';
import { theme } from 'Style/theme';
import './index.less';

interface AmenityTagProps {
    id: string;
    label: string;
    icon: string;
    onRemove?: (id: string) => void;
}

const AmenityTag: FunctionComponent<AmenityTagProps> = ({ id, label, icon, onRemove }) => {
    return (
        <div className="AmenityTag">
            <Icon iconName={icon} width={30} height={30} fill={theme['main-primary-color']} />

            <span className="label caption-medium element-light-primary">{label}</span>

            {onRemove && (
                <span onClick={() => onRemove(id)} className="cursor-pointer d-flex">
                    <CloseIcon width={16} height={16} fill={theme['element-light-primary']} />
                </span>
            )}
        </div>
    );
};

export default AmenityTag;
