import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import {
    MINIMUM_TIME_PERIOD_MINUTES,
    TWELVE_HOUR_MINUTE_AM_PM_CAPS,
    TWENTY_FOUR_HOUR_MINUTE,
} from 'Models/Constants';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import './index.less';

export interface TimePickerDropdownProps {
    selected?: string;
    onChange: (value?: string) => void;
    minimumAvailableTime?: string;
    maximumAvailableTime?: string;
    disabled?: boolean;
    midnightAvailable?: boolean;
    forSpecificDate?: string;
}

const TimePickerDropdown: React.FunctionComponent<TimePickerDropdownProps> = ({
    selected,
    onChange,
    minimumAvailableTime,
    maximumAvailableTime,
    disabled,
    midnightAvailable,
    forSpecificDate,
}) => {
    const options = useCallback((): SingleSelectCustomOption[] => {
        const time = minimumAvailableTime
            ? moment(forSpecificDate)
                  .startOf('day')
                  .add(minimumAvailableTime)
                  .add(MINIMUM_TIME_PERIOD_MINUTES, 'minute')
            : moment(forSpecificDate).startOf('day');
        const resultOptions: SingleSelectCustomOption[] = [];

        const maxTime = moment(forSpecificDate).startOf('day').add(maximumAvailableTime);

        while (time.isBefore(moment(forSpecificDate).startOf('day').add(1, 'day'))) {
            if (maximumAvailableTime && time.isAfter(maxTime)) {
                //break out
                time.endOf('day').add(1, 'second');
                break;
            } else {
                resultOptions.push({
                    label: time.format(TWELVE_HOUR_MINUTE_AM_PM_CAPS),
                    value: time.format(TWENTY_FOUR_HOUR_MINUTE),
                });
                time.add(MINIMUM_TIME_PERIOD_MINUTES, 'minute');
            }
        }
        if (midnightAvailable) {
            resultOptions.push({
                label: '12:00 AM',
                value: '23:59',
            });
        }

        return resultOptions;
    }, [minimumAvailableTime, maximumAvailableTime, midnightAvailable, forSpecificDate]);

    useEffect(() => {
        if (selected) {
            if (minimumAvailableTime && selected <= minimumAvailableTime) onChange(undefined);
            if (maximumAvailableTime && selected >= maximumAvailableTime) onChange(undefined);
        }
    }, [minimumAvailableTime, maximumAvailableTime, selected]);

    return (
        <div className="TimePickerDropdown">
            <StaticSingleSelect
                options={options()}
                onChange={(value?: SingleSelectCustomOption) => {
                    onChange(value?.value ?? '');
                }}
                selected={selected}
                disabled={disabled}
                hideSelectedOptions={false}
            />
        </div>
    );
};

export default TimePickerDropdown;
