// import { Input } from 'antd';
// import { ValidatedFormItem } from 'Components/validated-form-item';
import debounce from 'lodash.debounce';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill-wrapper.less';

interface QuillWrapperProps {
    value: string;
    onChange: (value: string, noMarkupValue: string) => void;
    fieldName: string;
    errors: Map<string, string[]>;
    formId?: string;
}

//https://github.com/zenoamaro/react-quill

const QuillWrapper: FunctionComponent<QuillWrapperProps> = ({
    value,
    onChange,
    fieldName,
    errors,
    formId,
}) => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        setHasError(errors.has(fieldName));
    }, [errors]);

    const debounceOnDescriptionChange = useRef(
        debounce((value: string, editor: any) => {
            onChange(value, editor.getText());
        }, 200)
    );

    useEffect(() => {
        return () => {
            debounceOnDescriptionChange.current.cancel();
        };
    }, [debounceOnDescriptionChange]);

    return (
        <div className="QuillWrapper">
            <ReactQuill
                className={`${hasError ? 'error' : ''}`}
                theme="snow"
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                //https://github.com/zenoamaro/react-quill/tree/v1.3.5#the-unprivileged-editor
                onChange={(value: string, _delta: any, _source: any, editor: any) =>
                    debounceOnDescriptionChange.current(value, editor)
                }
                value={value || ''}
                modules={{
                    toolbar: [['bold', 'italic', 'underline'], ['link']],
                }}
                preserveWhitespace={false}
                formats={['bold', 'italic', 'underline', 'link']}
                id={formId}
            />
            {/* <ValidatedFormItem label="" errors={errors} name={fieldName}>
                <Input hidden />
            </ValidatedFormItem> */}
        </div>
    );
};

export default QuillWrapper;
