import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Button from 'Components/button';
import Content from 'Components/content';
import FormSectionTitle from 'Components/form-section-title';
import { ValidatedFormItem } from 'Components/validated-form-item';
import WhiteCard from 'Components/white-card';
import { useService, useStores } from 'Hooks';
import {
    BOOKINGS_URL,
    CREATING_ID_URL,
    DETAILS_VIEW_GRID_COLUMNS,
    FORM_GUTTER,
    MANAGERS_URL,
    VENUES_URL,
} from 'Models/Constants';
import { useHistory, useParams } from 'react-router-dom';
import PhoneInput from 'Components/phone-input';
import Skeleton from 'Components/skeleton';
import { InputSkeletonShape } from 'Components/skeleton/generic-component-shapes/inputs';
import { DetailsTitleShape } from 'Components/skeleton/generic-component-shapes/details-title';
import { observer } from 'mobx-react';
import { useNavigationBlocker } from 'Hooks/use-form-navigation-blocker';
import { useFormValidation } from 'Hooks/use-form-validation';
import { fromE164, toE164 } from 'Utils/PhoneNumberUtils';
import { useTranslation } from 'react-i18next';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { UserService } from 'Services/UserService';
import { CreateUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserRequestDto';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';
import './index.less';
import Popover from 'Components/popover';
import Divider from 'Components/divider';
import { UserAccountStatusDto } from 'Api/Features/Users/Dtos/UserAccountStatusDto';
import Tag from 'Components/tag/tag';
import ConfirmationModal from 'Components/confirmation-modal';
import StaticSingleSelect from 'Components/select-custom/single-select/static-single-select';
import { SingleSelectCustomOption } from 'Components/select-custom/single-select/single-select-common';
import { ManagerSchema } from 'Schemas/ManagerSchema';
import SquigglyLine from 'Components/squiggly-line';

const ManagerDetails: FunctionComponent = observer(() => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [form] = useForm();
    const [errors, validateForm] = useFormValidation(ManagerSchema, form);
    const navBlocked = useNavigationBlocker();
    const userService = useService(UserService);
    const { globalLoadingStore, toastStore, userStore, authorizationStore } = useStores();
    const [managerDetails, setManagerDetails] = useState<UserDetailsDto>();
    const [selectedRole, setSelectedRole] = useState<ManagementRoleDto | undefined>(undefined);
    const [managerIsLoading, setManagerIsLoading] = useState(id !== CREATING_ID_URL);
    const [deactivateWarningVisible, setDeactivateWarningVisible] = useState(false);

    //special fields
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [extension, setExtension] = useState<string>();
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(true);
    const [phoneNumberWasFocused, setPhoneNumberWasFocused] = useState(false);

    useEffect(() => {
        if (id === CREATING_ID_URL) setManagerIsLoading(false);
    }, [id]);

    useEffect(() => {
        //operators not allowed here
        if (userStore.userRole && userStore.userRole === ManagementRoleDto.Operator)
            authorizationStore.setCurrentRequestForbidden();
    }, [userStore.userRole]);

    useEffect(() => {
        if (managerDetails) {
            form.setFieldsValue({
                firstName: managerDetails.firstName,
                lastName: managerDetails.lastName,
                'contactInfo.email': managerDetails.contactInfo?.email,
                'contactInfo.phoneNumber': managerDetails.contactInfo?.phoneNumber,
                companyName: managerDetails.companyName,
            });
            const phoneNumber = managerDetails.contactInfo?.phoneNumber
                ? fromE164(managerDetails.contactInfo?.phoneNumber).number
                : undefined;
            const extension = managerDetails.contactInfo?.phoneNumber
                ? fromE164(managerDetails.contactInfo?.phoneNumber).ext
                : undefined;

            setPhoneNumber(phoneNumber);
            setExtension(extension);
            setSelectedRole(managerDetails.managementRoles?.[0]?.name);
        }
    }, [managerDetails]);

    const fetchManagerDetails = useCallback(async () => {
        try {
            setManagerIsLoading(true);
            const response = await userService.getUser(id);
            if (response) setManagerDetails(response);
        } catch (e: any) {
            if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            setManagerIsLoading(false);
        }
    }, [globalLoadingStore, toastStore, userService, id]);

    useEffect(() => {
        if (id !== CREATING_ID_URL) {
            fetchManagerDetails();
        }
    }, [id]);

    const resendInviteEmail = async () => {
        try {
            globalLoadingStore.addLoading();
            await userService.resendAccountConfirmation(managerDetails?.id ?? '');
            toastStore.toast({
                type: 'success',
                message: 'Invitation email sent',
            });
        } catch (e: any) {
            toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const handleDeactivate = async () => {
        try {
            globalLoadingStore.addLoading();
            await userService.deactivateUser(managerDetails?.id ?? '');
            await fetchManagerDetails();
            toastStore.toast({
                type: 'success',
                message: 'Manager Deactivated',
            });
        } catch (e: any) {
            if (e.response?.data?.errorCode === 'E003007') {
                toastStore.toast({
                    type: 'error',
                    message:
                        "You must deactivated manager's venues before deactivating this manager",
                });
            } else toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const handleReactivate = async () => {
        try {
            globalLoadingStore.addLoading();
            await userService.reactivateUser(managerDetails?.id ?? '');
            await fetchManagerDetails();
            toastStore.toast({
                type: 'success',
                message: 'Manager Reactivated',
            });
        } catch (e: any) {
            toastStore.genericError();
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    const submit = async (): Promise<void> => {
        const formData = form.getFieldsValue();

        const validationObject = {
            ...formData,
            role: selectedRole,
            contactInfo: {
                phoneNumber: phoneNumber
                    ? fromE164(toE164(phoneNumber, extension)).number
                    : undefined,
                email: formData['contactInfo.email'],
            },
        };

        if (!(await validateForm(validationObject)) || !phoneNumberIsValid) return;

        try {
            globalLoadingStore.addLoading();

            const request: CreateUserRequestDto = {
                ...formData,
                managementRoles: [{ name: selectedRole, context: {} }],
                contactInfo: {
                    phoneNumber: phoneNumber ? toE164(phoneNumber, extension) : undefined,
                    email: formData['contactInfo.email'],
                },
            };
            if (managerDetails && managerDetails.id)
                await userService.updateUser(managerDetails.id, request);
            else await userService.createUser(request);

            toastStore.toast({
                type: 'success',
                message: t('Toast.success_message', {
                    param1: 'Manager',
                }),
            });
            navBlocked(false);
            history.push(MANAGERS_URL);
        } catch (e: any) {
            if (e.response?.data?.errors?.['contactInfo.Email']) {
                toastStore.toast({
                    type: 'error',
                    message: e.response?.data?.errors?.['contactInfo.Email'][0].description,
                });
            } else if (!e.treated) {
                toastStore.genericError();
            }
        } finally {
            globalLoadingStore.removeLoading();
        }
    };

    return (
        <Content className="OperatorDetails" designGridColAmount={DETAILS_VIEW_GRID_COLUMNS}>
            <div className="top-container title-h4-bold mb-20">
                <Skeleton placeholder={DetailsTitleShape()} isLoading={managerIsLoading}>
                    <span className="d-flex-align">
                        {managerDetails ? `${managerDetails.companyName}` : 'Create Manager'}
                        {managerDetails?.accountStatus === UserAccountStatusDto.Pending && (
                            <Tag text={t('pending')} color={'yellow'} />
                        )}
                        {managerDetails?.accountStatus === UserAccountStatusDto.Deactivated && (
                            <Tag text={t('deactivated')} color={'darkGray'} />
                        )}
                    </span>
                    <div className="btn-container">
                        <Button
                            text={managerDetails ? 'Save' : 'Create'}
                            type="primary"
                            width="hugged"
                            sizeType="medium"
                            onClick={() => submit()}
                            className="save"
                        />
                        {id !== CREATING_ID_URL && managerDetails?.id && (
                            <Popover
                                triggerContent={
                                    <Button
                                        text=""
                                        type="secondary"
                                        width="hugged"
                                        rightIcon="ThreeDotMenu"
                                        sizeType="medium"
                                        onlyIcon={true}
                                        className="menu"
                                    />
                                }
                                openMenuContent={
                                    <>
                                        {managerDetails?.accountStatus ===
                                            UserAccountStatusDto.Pending && (
                                            <>
                                                <div
                                                    className="item text-body text-high-contrast"
                                                    onClick={() => resendInviteEmail()}
                                                >
                                                    {t('resend_invitation')}
                                                </div>
                                                <Divider />
                                            </>
                                        )}

                                        <div
                                            className="item text-body text-high-contrast"
                                            onClick={() =>
                                                history.push(
                                                    BOOKINGS_URL +
                                                        `?operatorId=${managerDetails.id}&firstName=${managerDetails.firstName}&lastName=${managerDetails.lastName}&companyName=${managerDetails.companyName}`
                                                )
                                            }
                                        >
                                            {t('view_bookings')}
                                        </div>
                                        <Divider />

                                        <div
                                            className="item text-body text-high-contrast"
                                            onClick={() =>
                                                history.push(
                                                    VENUES_URL +
                                                        `?operatorId=${managerDetails.id}&firstName=${managerDetails.firstName}&lastName=${managerDetails.lastName}&companyName=${managerDetails.companyName}`
                                                )
                                            }
                                        >
                                            {t('view_venues')}
                                        </div>
                                        <Divider />

                                        {managerDetails.accountStatus ===
                                        UserAccountStatusDto.Deactivated ? (
                                            <div
                                                className="item text-body text-high-contrast"
                                                onClick={handleReactivate}
                                            >
                                                {t('reactivate')}
                                            </div>
                                        ) : (
                                            <div
                                                className="item text-body text-high-contrast"
                                                onClick={handleDeactivate}
                                            >
                                                {t('deactivate')}
                                            </div>
                                        )}
                                    </>
                                }
                            />
                        )}
                    </div>
                </Skeleton>
            </div>

            <WhiteCard padding={40} paddingBottom={20}>
                <Form
                    layout="vertical"
                    onFinish={submit}
                    form={form}
                    onFieldsChange={(changedFields) => {
                        const phoneFieldIndex = changedFields.findIndex(
                            (field) => field.name[0] === 'contactInfo.phoneNumber'
                        );
                        //phone input triggers on change when setting data from venue. only block if user focused input
                        if (phoneFieldIndex !== -1 && phoneNumberWasFocused) {
                            navBlocked(true);
                        } else if (changedFields.length > 0 && phoneFieldIndex === -1)
                            //normally only need this
                            navBlocked(true);
                    }}
                >
                    <FormSectionTitle title="Contact" />
                    <Row gutter={FORM_GUTTER}>
                        <Col span={8}>
                            <Skeleton
                                isLoading={managerIsLoading}
                                placeholder={InputSkeletonShape('company')}
                            >
                                <ValidatedFormItem
                                    name={'companyName'}
                                    label="company"
                                    errors={errors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>

                        <Col span={8}>
                            <Skeleton
                                isLoading={managerIsLoading}
                                placeholder={InputSkeletonShape('first name')}
                            >
                                <ValidatedFormItem
                                    name={'firstName'}
                                    label="first name"
                                    errors={errors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>

                        <Col span={8}>
                            <Skeleton
                                isLoading={managerIsLoading}
                                placeholder={InputSkeletonShape('last name')}
                            >
                                <ValidatedFormItem
                                    name={'lastName'}
                                    label="last name"
                                    errors={errors}
                                >
                                    <Input />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                    </Row>

                    <Row gutter={FORM_GUTTER}>
                        <Col span={12}>
                            <Skeleton
                                isLoading={managerIsLoading}
                                placeholder={InputSkeletonShape('email')}
                            >
                                <ValidatedFormItem
                                    name={'contactInfo.email'}
                                    label="email"
                                    errors={errors}
                                    disabled
                                >
                                    <Input disabled={id !== CREATING_ID_URL} type="email" />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>

                        <Col span={12}>
                            <Skeleton
                                isLoading={managerIsLoading}
                                placeholder={InputSkeletonShape('phone (optional)')}
                            >
                                <ValidatedFormItem
                                    name={'contactInfo.phoneNumber'}
                                    label="phone (optional)"
                                    errors={errors}
                                >
                                    <PhoneInput
                                        onChange={(
                                            isValid: boolean,
                                            fullNumber?: string,
                                            extension?: string
                                        ) => {
                                            setPhoneNumber(fullNumber);
                                            setExtension(extension);
                                            setPhoneNumberIsValid(isValid || fullNumber === '');
                                        }}
                                        startingValue={
                                            managerDetails?.contactInfo?.phoneNumber
                                                ? fromE164(managerDetails.contactInfo.phoneNumber)
                                                      .number
                                                : undefined
                                        }
                                        extension={extension}
                                        formId="contactInfo.phoneNumber"
                                        onFocus={() => setPhoneNumberWasFocused(true)}
                                        formErrors={errors}
                                        formInputName="contactInfo.phoneNumber"
                                    />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                    </Row>

                    <SquigglyLine marginbottom={20} />

                    <FormSectionTitle title="Manager role" />
                    <Row gutter={FORM_GUTTER}>
                        <Col span={8}>
                            <Skeleton
                                isLoading={managerIsLoading}
                                placeholder={InputSkeletonShape('company')}
                            >
                                <ValidatedFormItem name={'role'} label="role" errors={errors}>
                                    <StaticSingleSelect
                                        selected={selectedRole}
                                        onChange={(value?: SingleSelectCustomOption) =>
                                            setSelectedRole(
                                                value?.value
                                                    ? (value.value as ManagementRoleDto)
                                                    : undefined
                                            )
                                        }
                                        options={Object.keys(ManagementRoleDto).map(
                                            (role) =>
                                                ({
                                                    label: t(
                                                        `ManagementRoleDto.ManagementRoleDto_${role}`
                                                    ),
                                                    value: role,
                                                } as SingleSelectCustomOption)
                                        )}
                                    />
                                </ValidatedFormItem>
                            </Skeleton>
                        </Col>
                    </Row>
                </Form>
            </WhiteCard>

            <ConfirmationModal
                visible={deactivateWarningVisible}
                title="Deactivate Manager?"
                message="You are about to deactivate this manager"
                positive={{ text: 'Continue', action: handleDeactivate }}
                negative={{
                    text: 'Cancel',
                    action: () => {
                        setDeactivateWarningVisible(false);
                    },
                }}
            />
        </Content>
    );
});

export default ManagerDetails;
