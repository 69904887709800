import React from 'react';
import './index.less';

export const imageShape = (
    <div className="imageShape">
        <div className="rect-1" />
    </div>
);

export const venueShape = (
    <div className="venueShape">
        <div className="rect-1" />
        <div className="rect-1" />
        <div className="rect-1" />
        <div className="rect-1" />
        <div className="rect-1" />
        <div className="rect-1" />
        <div className="rect-1" />
    </div>
);

export const bookingShape = (
    <div className="bookingShape">
        <div className="rect-1" />
        <div className="rect-2" />
        <div className="rect-2" />
        <div className="rect-2" />
    </div>
);
