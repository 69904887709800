import { Input } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import './index.less';

interface PhoneInputProps {
    onChange: (isValid: boolean, fullNumber?: string, extension?: string) => void;
    startingValue?: string;
    extension?: string;
    formId: string;
    onFocus?: () => void;
    formInputName: string;
    formErrors?: Map<string, string[]>;
}

const PhoneInput: FunctionComponent<PhoneInputProps> = ({
    onChange,
    startingValue,
    extension,
    formId,
    onFocus,
    formInputName,
    formErrors,
}) => {
    const [isValid, setIsValid] = useState(true);
    const [fullNum, setFullNum] = useState<string>();
    const { t } = useTranslation();
    /* eslint-disable @typescript-eslint/no-unused-vars*/

    return (
        <div className={`PhoneInput ${formErrors?.has(formInputName) && 'form-errors'}`}>
            <IntlTelInput
                containerClassName="intl-tel-input"
                inputClassName={`form-control ${isValid ? '' : 'invalid-number'}`}
                preferredCountries={['us']}
                format
                fieldName={formId}
                fieldId={formId}
                defaultValue={startingValue}
                onPhoneNumberFocus={onFocus}
                onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber) => {
                    onChange(isValid, fullNumber, extension);
                    setFullNum(fullNumber);
                }}
                onPhoneNumberBlur={(isValid, value, selectedCountryData, fullNumber) => {
                    setIsValid(isValid || fullNumber.length == 0);
                }}
                onSelectFlag={(currentNumber, selectedCountryData, fullNumber, isValid) => {
                    const valid = isValid || fullNumber.length == 0;
                    onChange(valid, fullNumber, extension);
                    setFullNum(fullNumber);
                }}
            />
            <Input
                className={`extension ${isValid ? '' : 'invalid-number'}`}
                placeholder="ext."
                onChange={(e) => onChange(isValid, fullNum, e.target.value)}
                value={extension}
            />

            {!isValid && <span className="error-text">{t('invalid_number')}</span>}
        </div>
    );
};

export default React.memo(PhoneInput);
