import * as React from 'react';
import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const AmenityCoffeeIcon: FunctionComponent<SvgProps> = ({
    fill = 'currentColor',
    onClick,
    ...props
}) => {
    return (
        <span role="img" className="anticon" onClick={onClick}>
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.99482 6.18628C10.3825 6.18628 10.6968 6.50058 10.6968 6.88828V9.13468C10.6968 9.52238 10.3825 9.83668 9.99482 9.83668C9.60712 9.83668 9.29282 9.52238 9.29282 9.13468V6.88828C9.29282 6.50058 9.60712 6.18628 9.99482 6.18628ZM13.0257 6.18628C13.4134 6.18628 13.7277 6.50058 13.7277 6.88828V9.13468C13.7277 9.52238 13.4134 9.83668 13.0257 9.83668C12.638 9.83668 12.3237 9.52238 12.3237 9.13468V6.88828C12.3237 6.50058 12.638 6.18628 13.0257 6.18628ZM16.0565 6.18628C16.4442 6.18628 16.7585 6.50058 16.7585 6.88828V9.13468C16.7585 9.52238 16.4442 9.83668 16.0565 9.83668C15.6688 9.83668 15.3545 9.52238 15.3545 9.13468V6.88828C15.3545 6.50058 15.6688 6.18628 16.0565 6.18628ZM6.26196 12.1299C6.26196 11.7422 6.57626 11.4279 6.96396 11.4279H19.8451C21.8989 11.4279 23.578 13.0754 23.578 15.1251C23.578 17.1748 21.8988 18.8223 19.8451 18.8223H19.7894V18.8691C19.7894 20.9188 18.1103 22.5663 16.0565 22.5663H9.99482C7.94108 22.5663 6.26196 20.9188 6.26196 18.8691V12.1299ZM19.7894 17.4183H19.8451C21.1392 17.4183 22.174 16.3838 22.174 15.1251C22.174 13.8664 21.1392 12.8319 19.8451 12.8319H19.7894V17.4183ZM18.3854 12.8319V18.8691C18.3854 20.1278 17.3506 21.1623 16.0565 21.1623H9.99482C8.70077 21.1623 7.66596 20.1278 7.66596 18.8691V12.8319H18.3854Z"
                    fill={fill}
                />
            </svg>
        </span>
    );
};

export default AmenityCoffeeIcon;
