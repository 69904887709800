import { CreateUserRequestDto } from 'Api/Features/Users/Dtos/CreateUserRequestDto';
import { CreateUserResponseDto } from 'Api/Features/Users/Dtos/CreateUserResponseDto';
import { GetOperatorsRequestDto } from 'Api/Features/Users/Dtos/GetOperatorsRequestDto';
import { GetOperatorsResponseDto } from 'Api/Features/Users/Dtos/GetOperatorsResponseDto';
import { GetOperatorsResponseItemDto } from 'Api/Features/Users/Dtos/GetOperatorsResponseItemDto';
import { GetUsersRequestDto } from 'Api/Features/Users/Dtos/GetUsersRequestDto';
import { GetUsersResponseDto } from 'Api/Features/Users/Dtos/GetUsersResponseDto';
import { GetUsersResponseItemDto } from 'Api/Features/Users/Dtos/GetUsersResponseItemDto';
import { UpdateUserRequestDto } from 'Api/Features/Users/Dtos/UpdateUserRequestDto';
import { UserDetailsDto } from 'Api/Features/Users/Dtos/UserDetailsDto';
import { UserProxy } from 'Api/Features/Users/UserProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(UserProxy)
export class UserService extends ApiService {
    constructor(private readonly userProxy: UserProxy) {
        super();
    }

    public async getUser(userId: string): Promise<UserDetailsDto | null> {
        const data: UserDetailsDto | null = await this.userProxy.getUser(userId);
        return data;
    }

    public async getUsers(
        request: GetUsersRequestDto | null
    ): Promise<[GetUsersResponseItemDto[], number]> {
        const data: GetUsersResponseDto | null = await this.userProxy.getUsers(request);

        return [
            data?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async createUser(
        request: CreateUserRequestDto | null
    ): Promise<CreateUserResponseDto | null> {
        const data: CreateUserResponseDto | null = await this.userProxy.createUser(request);
        return data;
    }

    public async deactivateUser(userId: string): Promise<void> {
        await this.userProxy.deactivateUser(userId);
    }

    public async reactivateUser(userId: string): Promise<void> {
        await this.userProxy.reactivateUser(userId);
    }

    public async updateUser(userId: string, request: UpdateUserRequestDto | null): Promise<void> {
        await this.userProxy.updateUser(userId, request);
    }

    public async resendAccountConfirmation(userId: string): Promise<void> {
        await this.userProxy.resendAccountConfirmation(userId);
    }

    public async getOperators(
        request: GetOperatorsRequestDto | null
    ): Promise<[GetOperatorsResponseItemDto[], number]> {
        const data: GetOperatorsResponseDto | null = await this.userProxy.getOperators(request);
        return [
            data?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }
}
