import { BookingProxy } from 'Api/Features/Bookings/BookingProxy';
import { BookingDetailsDto } from 'Api/Features/Bookings/Dtos/BookingDetailsDto';
import { BookingDto } from 'Api/Features/Bookings/Dtos/BookingDto';
import { CancelBookingRequestDto } from 'Api/Features/Bookings/Dtos/CancelBookingRequestDto';
import { GetBookingsRequestDto } from 'Api/Features/Bookings/Dtos/GetBookingsRequestDto';
import { GetBookingsResponseDto } from 'Api/Features/Bookings/Dtos/GetBookingsResponseDto';
import { GetUserBookingsRequestDto } from 'Api/Features/Bookings/Dtos/GetUserBookingsRequestDto';
import { GetUserBookingsResponseDto } from 'Api/Features/Bookings/Dtos/GetUserBookingsResponseDto';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(BookingProxy)
export class BookingService extends ApiService {
    constructor(private readonly bookingProxy: BookingProxy) {
        super();
    }

    public async getBooking(bookingId: string): Promise<BookingDetailsDto | null> {
        const data: BookingDetailsDto | null = await this.bookingProxy.getBooking(bookingId);
        return data;
    }

    public async getUserBookings(
        userId: string,
        request: GetUserBookingsRequestDto | null
    ): Promise<GetUserBookingsResponseDto | null> {
        const data: GetUserBookingsResponseDto | null = await this.getUserBookings(userId, request);
        return data;
    }

    public async getBookings(
        request: GetBookingsRequestDto | null
    ): Promise<[BookingDto[], number]> {
        const data: GetBookingsResponseDto | null = await this.bookingProxy.getBookings(request);
        return [
            data?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            data?.totalItemCount ?? 0,
        ];
    }

    public async cancelBooking(
        bookingId: string,
        request: CancelBookingRequestDto | null
    ): Promise<void> {
        await this.bookingProxy.cancelBooking(bookingId, request);
    }
}
