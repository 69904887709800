import { PROFILE_URL } from "Models/Constants";
import React, { FunctionComponent, useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * ChangePasswordCallback
 * Azure AD will redirect here once the "change password" flow is finished
 */
const ChangePasswordCallback: FunctionComponent = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const history = useHistory();

    useEffect(() => {
        const changePasswordCallback = () => {
            // Check if success or error.
            const error = urlParams.get('error');
            const errorDescription = urlParams.get('error_description');

            if (!error) {
                // Success, go back to profle page
                history.push(PROFILE_URL);
            } else {
                // User cancelled
                if (errorDescription?.startsWith('AADB2C90091')) {
                    history.push(PROFILE_URL);
                }
            }
        };

        changePasswordCallback();
    }, []);

    return <div className="ChangePasswordCallback"></div>;
};

export default ChangePasswordCallback;
