import React, { FunctionComponent } from 'react';

interface SectionTitlePrps {
    title: string;
    marginBottom?: number;
    marginTop?: number;
}

const FormSectionTitle: FunctionComponent<SectionTitlePrps> = ({
    title,
    marginBottom,
    marginTop,
}) => {
    return (
        <div
            className="FormSectionTitle p-small-bold capitalize"
            style={{ marginBottom: marginBottom ?? 10, marginTop }}
        >
            {title}
        </div>
    );
};

export default FormSectionTitle;
