import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';
import { PinIcon } from 'Components/icons';
import React, { FunctionComponent } from 'react';
import { theme } from 'Style/theme';
import { displayAddress, googleMapLinkAddress } from 'Utils/TextUtils';
import './index.less';

interface AddressPinProps {
    address?: AddressDto | null;
    pinColor?: string;
    textColor?: string;
    asLink?: boolean;
}

const AddressPin: FunctionComponent<AddressPinProps> = ({
    address,
    pinColor,
    textColor,
    asLink,
}) => {
    return (
        <div className="AddressPin">
            <PinIcon width={20} fill={pinColor ?? theme['main-primary-color']} />
            {asLink ? (
                <a
                    href={`https://www.google.com/maps/place/${googleMapLinkAddress(address)}`}
                    target="_blank"
                    rel={'noreferrer'}
                >
                    {displayAddress(address)}
                </a>
            ) : (
                <span style={{ color: textColor }}>{displayAddress(address)}</span>
            )}
        </div>
    );
};

export default AddressPin;
