import { CreateVenueRequestDto } from 'Api/Features/Venues/Dtos/CreateVenueRequestDto';
import { CreateVenueResponseDto } from 'Api/Features/Venues/Dtos/CreateVenueResponseDto';
import { GetAmenitiesResponseDto } from 'Api/Features/Venues/Dtos/GetAmenitiesResponseDto';
import { GetVenuesRequestDto } from 'Api/Features/Venues/Dtos/GetVenuesRequestDto';
import { GetVenuesResponseDto } from 'Api/Features/Venues/Dtos/GetVenuesResponseDto';
import { UpdateVenueRequestDto } from 'Api/Features/Venues/Dtos/UpdateVenueRequestDto';
import { VenueDto } from 'Api/Features/Venues/Dtos/VenueDto';
import { VenueProxy } from 'Api/Features/Venues/VenueProxy';
import { inject } from 'aurelia-dependency-injection';
import { ApiService } from './ApiService';

@inject(VenueProxy)
export class VenueService extends ApiService {
    constructor(private readonly venueProxy: VenueProxy) {
        super();
    }

    public async getVenue(venueId: string): Promise<VenueDto | null> {
        const data: VenueDto | null = await this.venueProxy.getVenue(venueId);
        return data;
    }

    public async getVenues(request: GetVenuesRequestDto | null): Promise<[VenueDto[], number]> {
        const response: GetVenuesResponseDto | null = await this.venueProxy.getVenues(request);
        return [
            response?.items?.filter((x) => x !== null).map((x) => x!) ?? [],
            response?.totalItemCount ?? 0,
        ];
    }

    public async createVenue(
        request: CreateVenueRequestDto | null
    ): Promise<CreateVenueResponseDto | null> {
        const data: CreateVenueResponseDto | null = await this.venueProxy.createVenue(request);
        return data;
    }

    public async updateVenue(
        venueId: string,
        request: UpdateVenueRequestDto | null
    ): Promise<void> {
        await this.venueProxy.updateVenue(venueId, request);
    }

    public async deactivateVenue(venueId: string): Promise<void> {
        await this.venueProxy.deactivateVenue(venueId);
    }

    public async getAmenities(): Promise<GetAmenitiesResponseDto | null> {
        const data: GetAmenitiesResponseDto | null = await this.venueProxy.getAmenities();
        return data;
    }

    public async approveVenue(venueId: string): Promise<void> {
        await this.venueProxy.approveVenue(venueId);
    }

    public async rejectVenue(venueId: string): Promise<void> {
        await this.venueProxy.rejectVenue(venueId);
    }
}
