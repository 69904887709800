import { Divider, Layout, Menu, Tooltip } from 'antd';
import Icon from 'Components/icons/Icon';
import { observer } from 'mobx-react';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { theme } from 'Style/theme';
import './side-navigation.less';
import {
    BOOKINGS_URL,
    BOOKNIGS_URL_REGEX,
    SIDENAV_AUTO_COLLAPSE_WIDTH_THRESHOLD,
    SIDENAV_WIDTH_OPEN,
    SIDENAV_WIDTH_COLLAPSED,
    VENUES_URL,
    VENUES_URL_REGEX,
    SALES_URL,
    MANAGERS_URL,
    CONSUMERS_URL,
    CONSUMERS_URL_REGEX,
    MANAGERS_URL_REGEX,
    ABOUT_US_URL_REGEX,
    ABOUT_US_URL,
} from 'Models/Constants';
import { WorkhopWLogo, WorkhopWrittenLogo } from 'Components/icons';
import { useWindowDimensions } from 'Hooks/use-window-dimensions';
import { SideNavigationContext } from 'Components/authenticated-layout/authenticated-layout';
import { useStores } from 'Hooks';
import { ManagementRoleDto } from 'Api/Features/Users/Dtos/ManagementRoleDto';

const { Sider } = Layout;

const SideNavigation: React.FunctionComponent = observer(() => {
    const sideNavContext = useContext(SideNavigationContext);
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const { userStore } = useStores();
    const { windowWidth } = useWindowDimensions();
    const [menuItems, setMenuItems] = useState<any[]>([]);

    const getIcon = (iconName: string, label: string): ReactElement =>
        sideNavContext?.collapsed ? (
            <Tooltip title={label} placement="right">
                <Icon iconName={iconName} {...PROPS_ICON_CURRENT} />
            </Tooltip>
        ) : (
            <Icon iconName={iconName} {...PROPS_ICON_CURRENT} />
        );

    const onSelectItem = (param: MenuInfo): void => {
        history.push(`${param.key}`);
    };

    const selectedKeys = useCallback((): string[] => {
        const keys: string[] = [];

        if (VENUES_URL_REGEX.test(location.pathname)) {
            keys.push(VENUES_URL);
        }

        if (BOOKNIGS_URL_REGEX.test(location.pathname)) {
            keys.push(BOOKINGS_URL);
        }

        if (MANAGERS_URL_REGEX.test(location.pathname)) {
            keys.push(MANAGERS_URL);
        }

        if (CONSUMERS_URL_REGEX.test(location.pathname)) {
            keys.push(CONSUMERS_URL);
        }

        if (ABOUT_US_URL_REGEX.test(location.pathname)) {
            keys.push(ABOUT_US_URL);
        }
        return keys;
    }, [location.pathname]);

    // CONSTANT
    const WIDTH_ICON_MENU = 24;
    const HEIGHT_ICON_MENU = 24;
    const FILL_IS_CURRENT = theme['white'];
    const PROPS_ICON_CURRENT = {
        width: WIDTH_ICON_MENU,
        height: HEIGHT_ICON_MENU,
        fill: FILL_IS_CURRENT,
    };
    const INLINE_INDENT = 20;
    const INLINE_INDENT_SMALL = (SIDENAV_WIDTH_COLLAPSED - WIDTH_ICON_MENU) / 2;

    useEffect(() => {
        const menuItems = [
            {
                currentIcon: getIcon('PinIcon', t('venues')),
                label: t('venues'),
                url: VENUES_URL,
            },
            {
                currentIcon: getIcon('CalendarIcon', t('bookings')),
                label: t('bookings'),
                url: BOOKINGS_URL,
            },
            {
                currentIcon: getIcon('DollarSignIcon', t('sales')),
                label: t('sales'),
                url: SALES_URL,
            },
            {
                currentIcon: getIcon('WorkhopWLogo', t('about_us')),
                label: t('about_us'),
                url: ABOUT_US_URL,
            },
        ];
        if (userStore.userRole === ManagementRoleDto.Administrator) {
            menuItems.splice(2, 0, {
                currentIcon: getIcon('AdminFilledIcon', t('managers')),
                label: t('managers'),
                url: MANAGERS_URL,
            });
            menuItems.splice(3, 0, {
                currentIcon: getIcon('PeopleIcon', t('consumers')),
                label: t('consumers'),
                url: CONSUMERS_URL,
            });
        }

        setMenuItems(menuItems);
    }, [userStore.userRole]);

    return (
        <Sider
            className={`SideNavigation collapsed-${sideNavContext?.collapsed} ${
                windowWidth <= SIDENAV_AUTO_COLLAPSE_WIDTH_THRESHOLD ? 'small-screen-threshold' : ''
            }`}
            width={sideNavContext?.collapsed ? SIDENAV_WIDTH_COLLAPSED : SIDENAV_WIDTH_OPEN}
        >
            <div
                className={'top-logo-container'}
                onClick={() =>
                    selectedKeys().includes(VENUES_URL) ? history.go(0) : history.push(VENUES_URL)
                }
            >
                {sideNavContext?.collapsed ? (
                    <WorkhopWLogo fill="white" width={29} height={22} />
                ) : (
                    <WorkhopWrittenLogo fill="white" width={190} height={40} />
                )}
            </div>

            <Divider />

            <Menu
                mode="inline"
                onSelect={onSelectItem}
                onClick={onSelectItem}
                selectedKeys={selectedKeys()}
                inlineIndent={sideNavContext?.collapsed ? INLINE_INDENT : INLINE_INDENT_SMALL}
            >
                {menuItems.map((option) => (
                    <Menu.Item key={option.url} icon={option.currentIcon} className="p-medium">
                        {option?.label}
                    </Menu.Item>
                ))}
            </Menu>
        </Sider>
    );
});

export default SideNavigation;
