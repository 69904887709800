import { Modal } from 'antd';
import Button from 'Components/button';
import React, { ReactNode } from 'react';
import './index.less';

interface ModalAction {
    text: string;
    action: () => void;
}

export interface ConfirmationModalProps {
    visible: boolean;
    title: string;
    message?: string | ReactNode;
    positive: ModalAction;
    negative: ModalAction;
}

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = ({
    visible,
    title,
    message,
    positive,
    negative,
}) => {
    return (
        <Modal
            className="ConfirmationModal"
            visible={visible}
            onCancel={negative && negative.action}
            width={470}
            closable={false}
            footer={null}
            centered
        >
            <div className="title-h4">{title}</div>
            {message && <div className="p-medium element-light-secondary message">{message}</div>}
            <div className="actions">
                <Button
                    text={negative.text}
                    type="secondary"
                    width="hugged"
                    sizeType="medium"
                    onlyIcon={undefined}
                    onClick={negative.action}
                />

                <Button
                    text={positive.text}
                    type="primary"
                    width="hugged"
                    sizeType="medium"
                    onlyIcon={undefined}
                    onClick={positive.action}
                />
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
