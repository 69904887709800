import { AddressDto } from 'Api/Features/General/Dtos/AddressDto';

export const displayAddress = (address?: AddressDto | null, hideZipCode?: boolean): string => {
    if (address) {
        const addressArray: string[] = [];
        if (address.addressLine1) addressArray.push(address.addressLine1);
        if (address.addressLine2) addressArray.push(address.addressLine2);
        if (address.state) addressArray.push(address.state);
        if (address.countryCode) addressArray.push(address.countryCode);
        if (address.zipCode && !hideZipCode) addressArray.push(address.zipCode);

        return addressArray.join(', ');
    } else {
        return '';
    }
};

export const googleMapLinkAddress = (address?: AddressDto | null): string => {
    if (address) {
        const addressArray: string[] = [];
        if (address.addressLine1) addressArray.push(address.addressLine1);
        if (address.addressLine2) addressArray.push(address.addressLine2);
        if (address.state) addressArray.push(address.state);
        if (address.countryCode) addressArray.push(address.countryCode);
        if (address.zipCode) addressArray.push(address.zipCode);

        return addressArray.join('+');
    } else {
        return '';
    }
};

export const formatLink = (url: string): string => {
    if (!url) return '';
    if (url.startsWith('http')) return url;
    else return 'https://' + url;
};
