import React, { ReactElement } from 'react';
import './index.less';

export const InputSkeletonShape = (label: string, inputHeight?: number): ReactElement => (
    <div className="InputSkeletonShape">
        <div className="ant-col ant-form-item-label">
            <label>
                <div className=" caption-medium element-light-primary">{label}</div>
            </label>
        </div>
        <div className="rect" style={{ height: inputHeight }} />
    </div>
);
