import React, { FunctionComponent, useRef, useState } from 'react';
import { Upload } from 'antd';
import type { UploadFile, UploadFileStatus } from 'antd/es/upload/interface';
import './image-picker.less';
import ImageCarouselModal, { CarouselImage } from 'Components/image-carousel-modal';
import { EyeIcon, PlusIcon, TrashIcon } from 'Components/icons';
import { theme } from 'Style/theme';

interface ImagePickerProps {
    allowMultiple?: boolean;
    images?: UploadFile[];
    setImagesDetails: (images: ImageDetails[] | undefined) => void;
    formId: string;
}
export interface ImageDetails {
    id: string;
    base64: string;
    isDeleted: boolean;
    fileUid: string;
    url: string;
}

const ImagePicker: FunctionComponent<ImagePickerProps> = ({
    allowMultiple,
    images,
    setImagesDetails,
    formId,
}) => {
    const [fileList, setFileList] = useState<UploadFile[] | undefined>(
        images ? images : allowMultiple ? [] : undefined
    );

    const imagesDetails = useRef<ImageDetails[]>(
        images?.map((x) => ({ id: x.uid, isDeleted: false } as ImageDetails)) || []
    );
    const [showAddImage, setShowAddImage] = useState<boolean>(
        allowMultiple || images === undefined
    );

    const [imageCarouselModalState, setImageCarouselModalState] = useState<{
        visible: boolean;
        startingImgId: string;
    }>({ visible: false, startingImgId: '' });

    const [carouselImages, setCarouselImages] = useState<CarouselImage[]>(
        images ? images.map((img) => ({ id: img.uid, url: img.url ?? '' })) : []
    );

    const getBase64 = async (file: any): Promise<string | undefined> => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        return src?.substring(src?.lastIndexOf(',') + 1);
    };

    const getFileSrc = async (file: any): Promise<string> => {
        const src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
        return src as string;
    };

    const handleOnChange = async (event: any): Promise<void> => {
        setFileList(event.fileList);
        if (event.file.status === ('done' as UploadFileStatus)) {
            const srcUrl = await getFileSrc(event.file);
            const base = await getBase64(event.file);
            if (!allowMultiple) {
                imagesDetails.current = [
                    {
                        fileUid: event.file.uid,
                        base64: base,
                        isDeleted: false,
                        url: srcUrl,
                    } as ImageDetails,
                ];
            } else {
                imagesDetails.current = [
                    ...imagesDetails.current,
                    { fileUid: event.file.uid, base64: base, isDeleted: false } as ImageDetails,
                ];
            }
            setImagesDetails(imagesDetails.current);

            setCarouselImages((prev) => [...prev, { id: event.file.uid, url: srcUrl }]);
        }
        if (event.file.status === ('removed' as UploadFileStatus)) {
            const image = images?.find((x) => x.uid === event.file.uid);
            if (image) {
                //image was an existing image from api model
                const imageDetails = imagesDetails.current?.find(
                    (imageDetails: ImageDetails) => imageDetails.id === event.file.uid
                );
                imageDetails!.isDeleted = true;
            } else {
                //image was added in form and must now be ignored
                imagesDetails.current = imagesDetails.current?.filter(
                    (imageDetails: ImageDetails) => imageDetails.fileUid !== event.file.uid
                );
            }
            setImagesDetails(imagesDetails.current.length > 0 ? imagesDetails.current : undefined);

            setCarouselImages((prev) => prev.filter((img) => img.id !== event.file.uid));
        }
        setShowAddImage(allowMultiple || event.fileList?.length === 0);
    };

    const onPreview = async (file: any): Promise<void> => {
        setImageCarouselModalState({ visible: true, startingImgId: file.uid });
    };

    //The antd control requires to post on file select. We do not want this behavior so we must make a dummy request
    const dummyRequest = (request: any) => {
        setTimeout(() => {
            request.onSuccess('ok');
        }, 0);
    };

    return (
        <div className="ImagePicker" id={formId}>
            <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={handleOnChange}
                onPreview={onPreview}
                customRequest={dummyRequest}
                accept={'image/*'}
                showUploadList={{
                    removeIcon: (
                        <div style={{ marginLeft: 10 }}>
                            <TrashIcon fill={theme.white} width={20} height={20} />
                        </div>
                    ),
                    previewIcon: <EyeIcon fill={theme.white} width={20} height={20} />,
                }}
            >
                {(allowMultiple || showAddImage) && (
                    <div className="add-inner-container caption-medium elemeont-light-primary">
                        <PlusIcon width={20} />
                        {'Upload'}
                    </div>
                )}
            </Upload>

            {carouselImages.length > 0 && imageCarouselModalState.visible && (
                <ImageCarouselModal
                    onClose={(): void =>
                        setImageCarouselModalState({ visible: false, startingImgId: '' })
                    }
                    images={carouselImages}
                    startingImgId={imageCarouselModalState.startingImgId}
                />
            )}
        </div>
    );
};

export default ImagePicker;
